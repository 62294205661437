const locales = {
  menus: {
    common: {
      requests: "申請",
      reports: "レポート",
      approvals: "承認",
    },
    rp: {
      expenses: "経費",
      cardStatements: "カード明細",
      cashAdvances: "仮払",
      summary: "集計",
      originalReceipt: "領収書原本",
    },
    ip: {
      invoices: "請求書",
      proposals: "稟議",
      analysis: "集計",
      suppliers: "取引先",
      nationalTaxDocuments: "国税関係書類",
      waitingWorkerDocumentInputs: "自動入力中書類",
    },
    cm: {
      contractManagement: "契約管理",
    },
    systemSettings: "システム設定",
    personalSettings: "個人設定",
  },
  createMenu: {
    new: "新規",
    rp: {
      report: "申請する",
      otherReports: "その他の申請",
      createExpense: "経費を登録する",
      manual: "手入力",
      transport: "交通費入力",
      auto: "自動入力",
      allowances: "日当・手当",
    },
    ip: {
      report: "申請する",
      proposal: "稟議",
      paymentRequest: "支払依頼申請",
      registerInvoice: "請求書を登録する",
      manual: "手入力",
    },
  },
  commons: {
    serveses: {
      receiptPost: "TOKIUM経費精算",
      invoicePost: "TOKIUMインボイス",
      taxDocument: "TOKIUM電子帳簿保存",
      contractManagement: "TOKIUM契約管理",
    },
    actions: {
      index: "アクション",
      send: "送信",
      save: "保存",
      register: "登録",
      search: "検索",
      add: "追加",
      merge: "統合",
      create: "作成",
      update: "更新",
      change: "変更",
      duplicate: "複製",
      registration: "登録",
      edit: "編集",
      apply: "申請する",
      sendReport: "申請を提出する",
      editRequest: "修正申請する",
      settlementRequest: "精算する",
      saveAndApply: "保存して申請する",
      reapply: "再申請する",
      resetFlow: "フローを再設定する",
      withdraw: "取り下げる",
      withdrawApproval: "承認を取り消す",
      reject: "差し戻す",
      approve: "承認する",
      recordTemporaryPaymentRefund: "仮払差額返金済みにする",
      cancelTemporaryPaymentRefund: "仮払差額返金を取り消す",
      approval: "承認",
      output: "出力",
      delete: "削除",
      finish: "完了",
      cancel: "キャンセル",
      cancelAll: "全てキャンセル",
      cancelTemporaryPayment: "仮払を取り消す",
      check: "確認する",
      reset: "解除する",
      resetSearchConditions: "検索条件をリセット",
      searchWithTheConditions: "指定した条件で検索",
      configure: "設定する",
      configured: "設定済",
      close: "閉じる",
      unset: "未設定",
      import: "インポート",
      importWithXlsxFile: "インポート",
      downloadWithXlsxFile: "エクスポート",
      remove: "除外する",
      newEntry: "新規作成",
      markAsRead: "既読にする",
      markAllAsRead: "全て既読にする",
      markAsUnread: "未読にする",
      yes: "はい",
      no: "いいえ",
      select: "選択",
      enable: "利用する",
      downloadCashAdvanceRequest: "仮払申請書のダウンロード",
      loginAsAgent: "代理人としてログイン",
      loginAsAgentPlaceholder: "名前 / メールアドレスで検索",
      loginBackToUser: "代理アカウントからログアウト",
      loginAsTenant: "別のテナントに切替",
      changeTenant: "テナントを切り替えて参照してください。",
      loginAsTenantPlaceholder: "テナント名で検索",
      logout: "ログアウト",
      destroy: "削除しました",
      back: "戻る",
      split: "分割",
      confirmSplit: "分割を実行する",
      next: "次へ",
      setting: "設定",
      enterComment: "コメントを入力",
      loadMore: "さらに読み込む",
    },
    units: {
      person: "人",
      // "person_plural": "人",
      report: "件",
      result: "件",
      // "result_plural": "件",
      hour: "時間",
      minute: "分",
      name: "名", // 例: 申請名
      version: "バージョン",
      otherCounts: "他{{count}}件",
      year: "年",
      // "year_plural": "年",
      month: "月",
      // "month_plural": "月",
      char: "文字",
    },
    ordinals: {
      no: "第{{count}}",
      // "no_interval": "(1){1st};(2){2nd};(3){3rd};",
      // "no_plural": "{{count}}th",
    },
    messages: {
      confirmDelete: "本当に削除しますか？",
      confrimPayment: "本当に支払処理完了としますか？",
      confrimTemporaryPayment: "本当に仮払処理完了としますか？",
      noDataFound: "データがありません",
      submitted: "送信に成功しました",
      saved: "データを保存しました",
      copied: "データを複製しました",
      deleted: "データを削除しました",
      markAllAsRead: "全ての経費を既読にしますか？",
      updated: "更新しました",
      refundTemporaryPayment: "仮払金と支出の差額を返金して下さい",
    },
    errors: {
      communicationError: "通信エラーが発生しました",
      failedToSubmit: "送信に失敗しました",
      failedToSave: "保存に失敗しました",
      failedToUpload: "アップロードに失敗しました",
      failedToUpdate: "データの更新に失敗しました",
      failedToFetch: "データの取得に失敗しました",
    },
    status: {
      created: "未申請",
      sending: "送信中",
      notEntered: "未入力",
      notSelected: "未選択",
      submitted: "申請済み",
      unsubmitted: "未申請",
      creating: "データ化中",
      failed: "データ化失敗",
      applying: "承認待ち",
      reapplying: "再申請",
      recalled: "取下げ",
      rejected: "差し戻し",
      aborted: "フロー変更待ち",
      archived: "フロー変更済み",
      approved: "承認済み",
      potentially: "承認見込み",
      closed: "申請完了",
      pendingTemporaryPayment: "仮払待ち",
      pendingTemporaryPaymentRefund: "仮払差額返金待ち",
      temporaryPaid: "仮払処理済み",
      unsettledTemporaryPayment: "精算未完了",
      unsettled: "未精算",
      settled: "精算済み",
      paid: "支払処理済み",
      checked: "確認済み",
      nothingProject: "プロジェクトなし",
      multipleProjects: "複数のプロジェクト",
      nothingPaymentGroup: "費用負担部署なし",
      multiplePaymentGroups: "複数の費用負担部署",
      notAggregated: "未集計",
      aggregated: "集計済み",
      notExported: "会計データ未出力",
      exported: "会計データ出力済み",
      notPaid: "未払い",
      none: "指定なし",
      unconfirmed: "未確認",
      alert: "アラート",
      approvalRevoked: "承認取り消し",
    },
    inputs: {
      required: "{{name}}（必須項目です）",
    },
    words: {
      date: "日付",
      dateAndTime: "日時",
      total: "合計",
      sizePerPage: "表示件数",
      name: "名前",
      type: "種類",
      info: "info",
      threshold: "しきい値",
      condition: "条件",
      unknown: "不明",
      here: "こちら",
    },
    misc: "画像等",
    image: "画像",
    preposition: {
      from: "から",
      to: "まで",
    },
    conditions: {
      yes: "あり",
      no: "なし",
      all: "すべて",
    },
    validations: {
      valid: "有効",
      invalid: "無効",
      all: "すべて",
    },
    formItemType: {
      text: "テキスト",
      multilineText: "テキスト(複数行)",
      number: "数字",
      date: "日付",
      check: "チェック(Yes or No)",
      multiCheck: "チェック(複数選択)",
      radio: "ラジオボタン",
      select: "セレクトメニュー",
      attachment: "添付ファイル",
    },
    dataFetchTable: {
      itemsAreDisplayed: "件目を表示",
      total: "全",
      items: "件",
      sizePerPage: "1ページの表示件数",
      itemsAreSelected: "件選択中",
      selectedItemsMenu: "選択項目の操作",
    },
    table: {
      pagenation: {
        page: "ページ",
        moveTo: "ページに移動",
      },
    },
    ubiquitous: {
      category: "経費科目",
    },
    label: {
      required: "必須",
    },
  },
  accountingDataScheduledExports: {
    title: "経費精算",
    fileTransferLocations: {
      createModal: {
        title: "転送先 新規作成",
      },
      deleteConfirmModal: {
        title: "転送先 削除",
        message1: "転送先を削除すると元には戻せません。",
        message2: "本当に削除しますか？",
      },
      detailDrawer: {
        connectionCheck: "接続確認",
      },
      commons: {
        existEmptyInput: "未入力の項目があります",
      },
      table: {
        noData: "転送先が見つかりませんでした",
        protocol: "転送種別",
        name: "転送先名",
        description: "説明",
      },
      form: {
        name: "転送先名",
        description: "説明",
        protocol: "転送種別",
        host: "ホスト",
        port: "ポート",
        authMethod: "認証方式",
        authMethodPassword: "パスワード認証",
        account: "アカウント",
        password: "パスワード",
        directory: "転送先ディレクトリ",
        connectionConfirmation: "接続確認",
        connectionSuccess: "成功",
        connectionFail: "失敗",
        connectionNoCheck: "未確認",
        popoverDescription: {
          name: "200文字まで設定できます",
          description: "1000文字まで設定できます",
          host: `ドメイン名またはIPアドレスを設定できます。

              例)
              example.com
              192.168.1.1`,
          port: "20~65535の範囲で設定できます",
          account: `32文字まで設定できます。
              ピリオド(.)とスラッシュ(/)は使用できません`,
          password: "255文字まで設定できます",
          directory: `255文字まで設定できます。
            ルートディレクトリ(/)は指定できません`,
        },
      },
      indexPage: {
        title: "転送先",
        backToTask: "タスク一覧に戻る",
        new: "新規作成",
      },
    },
    jobRequests: {
      table: {
        noData: "実行履歴が見つかりませんでした",
        date: "日付",
        fileTransferLocationFileName: "出力ファイル名",
        status: "ステータス",
        runnerType: "実行種別",
        timeRequired: "所要時間",
        latestJobExecution: "実行結果",
        fileTransferLocationProtocol: "転送方法",
        fileTransferLocationDirectory: "転送先",
        taskName: "タスク名",
        enableAccountingDataCreation: "会計データ作成",
        accountingDataCreationEnabled: "する",
        accountingDataCreationDisabled: "しない",
        enableFileTransfer: "転送",
        fileTransferEnabled: "する",
        fileTransferDisabled: "しない",
      },
      indexPage: {
        title: "実行履歴",
        backToTask: "タスク一覧に戻る",
        reloadJob: "最新の状態を取得",
      },
      status: {
        success: "成功",
        fail: "失敗",
      },
      runnerType: {
        schedular: "定期実行",
        tester: "テスト実行",
        immediateRunner: "即時実行",
        reRunner: "再実行",
      },
      timeRequired: {
        minutes: "{{minutes}}分",
      },
      latestJobExecution: {
        aggregateResults: "集計結果",
        aggregationFailure: "集計: 失敗",
        infoWithExceptionDetail: "詳細",
        errorDetail: "エラー詳細",
        criticalMessage: "原因不明のエラーが発生しました",
        download: "ダウンロード",
        reExecute: "再実行",
        none: "未実行",
        label: "会計データ",
        bankData: {
          label: "全銀データ",
          download: "ダウンロード",
        },
      },
    },
    tasks: {
      deleteConfirmModal: {
        title: "タスク 削除",
        message1: "タスクを削除すると元には戻せません。",
        message2: "本当に削除しますか？",
      },
      createModal: {
        title: "タスク 新規作成",
      },
      forms: {
        base: {
          heading: "基本",
          title: "基本",
          name: {
            label: "タスク名",
            required: "タスク名を入力してください",
          },
          executionInterval: {
            label: "実行間隔",
            required: "実行間隔を選択してください",
            intervals: {
              byMonth: "毎月",
              byWeekday: "毎週",
              byDay: "毎日",
              byOnceSpecifiedDate: "日付指定",
            },
            lastDayOfMonth: "月末日",
          },
          executionTimetable: {
            label: "実行時間枠",
            required: "実行時間枠を選択してください",
            loading: "実行時間枠を読込中...",
            notFound:
              "実行時間枠が登録されていません。サポートまでお知らせください。",
          },
          periodStartedAt: {
            label: "有効期限（開始）",
            required: "有効期限（開始）を入力してください",
          },
          periodEndedAt: {
            label: "有効期限（終了）",
            required: "有効期限（終了）を入力してください",
          },
        },
        aggregation: {
          heading: "集計",
          title: "集計",
          analysisAggregationName: {
            label: "集計名フォーマット",
            required: "集計名フォーマットを入力してください",
            placeholder: "{yyyymmdd}_集計",
          },
        },
        export: {
          heading: "出力",
          title: "会計データ出力",
          enableAccountingDataCreation: {
            label: "会計データを出力する",
            enable: "出力する",
            disable: "出力しない",
          },
          exportFormat: {
            label: "出力形式",
            required: "出力形式を選択してください",
            loading: "出力形式を読込中...",
            notFound:
              "仕訳フォーマット設定または会計出力形式を設定してください。",
          },
          exportFileNameFormat: {
            label: "出力ファイル名フォーマット",
            required: "出力ファイル名フォーマットを入力してください",
            placeholder: "{yyyymmdd}_集計",
          },
          executor: {
            label: "実行者",
            required: "実行者を選択してください",
            loading: "実行者を読込中...",
            notFound: "従業員設定で管理者または集計者を設定してください。",
          },
          enableBankDataCreation: {
            label: "全銀データを出⼒する",
            enable: "出力する",
            disable: "出力しない",
          },
          companyExpenseAccount: {
            title: "全銀データ出⼒",
            label: "⽀払⼝座",
            required: "⽀払⼝座を選択してください",
            loading: "⽀払⼝座を読込中...",
            notFound: "⽀払⼝座を設定してください",
            paymentSpecifiedTitle: "⽀払指定⽇",
            paymentSpecifiedRequired: "支払い方法を選択してください",
            feePaymentDestination: "手数料負担先",
            our: "当方",
            their: "先方",
            inputPaymentSpecifiedRequired: "⽀払指定⽇を入力してください",
          },
        },
        bankExport: {
          title: "全銀データ",
        },
        transfer: {
          heading: "転送",
          title: "ファイル転送",
          enableFileTransfer: {
            label: "ファイルを転送する",
            enable: "転送する",
            disable: "転送しない",
          },
          fileTransferLocation: {
            label: "転送先",
            required: "転送先を選択してください",
            loading: "転送先を読込中...",
            notFound: "転送先を設定してください。",
          },
        },
      },
      table: {
        noData: "タスクが見つかりませんでした",
        name: "タスク名",
        schedule: "スケジュール",
        enabled: "定期実行",
        exportFormatName: "出力形式",
        analysisAggregationConditions: "集計条件",
        latestJobExecution: "前回の実行",
        nextExecutionTimetable: "次回の実行",
        notValidTimetable: "有効な日時がありません",
        enableAccountingDataCreation: "会計データ作成",
        accountingDataCreationEnabled: "する",
        accountingDataCreationDisabled: "しない",
        enableBankDataCreation: "全銀データ作成",
        enableBankDataCreationEnabled: "する",
        enableBankDataCreationDisabled: "しない",
        enableFileTransfer: "転送",
        fileTransferEnabled: "する",
        fileTransferDisabled: "しない",
        noNextExecution:
          "定期実行済みか実行時刻を経過したため次回は実行されません",
        noNextExecutionHelp1: "日付指定は一度だけ定期実行されます。",
        noNextExecutionHelp2:
          "即時実行またはテスト実行については、回数の制限なく複数回実施することが可能です。",
      },
      indexPage: {
        title: "タスク",
        setUpFileTransferLocation: "転送先",
        jobIndex: "実行履歴",
        new: "新規作成",
      },
      enable: {
        true: "有効",
        false: "無効",
      },
      schedule: {
        dayOfWeek: [
          "日曜日",
          "月曜日",
          "火曜日",
          "水曜日",
          "木曜日",
          "金曜日",
          "土曜日",
        ],
        day: "{{day}}日",
        executionDayOfMonth: "毎月{{date}}日",
        executeAtEndOfEachMonth: "毎月末",
        executionDayOfWeek:
          "毎週$t(accountingDataScheduledExports.tasks.schedule.dayOfWeek.{{day}})",
        executionDaily: "毎日",
      },
      analysisAggregationConditions: {
        noData: "集計条件なし",
        details: "集計条件詳細",
        destination: "目的地",
        applicantGroup: "申請者の所属部署",
        paymentGroup: "費用負担部署",
        applicantPost: "申請者の役職",
        approvedApprover: "承認済みの承認者",
        projectId: "プロジェクトID",
        projectName: "プロジェクト名",
        includeChildDepartment: "下位部署を含む",
        includeNotAggregated: "未集計",
        includeAggregated: "集計済み",
        includeNotExported: "会計データ未出力",
        includeExported: "会計データ出力済み",
        includeNotPaid: "未払い",
        includePaid: "支払処理済み",
        includeUnsettled: "未精算",
        includeSettled: "精算済み",
        matchedOriginalReceipt: "完了",
        notMatchedOriginalReceipt: "未完了",
        includeNotTemporaryPaid: "未払い",
        includeTemporaryPaid: "支払処理済み",
        submittedPeriod: "申請日",
        transactedPeriod: "利用日 (経費)",
        lastApprovedPeriod: "最終承認日",
        temporaryPaymentDuePeriod: "仮払希望日",
        startPeriod: "期間",
        registratedNumber: "登録番号",
        includeAsInvoice: "あり",
        includeNotAsInvoice: "なし",
        tags: "申請者のラベル",
        period: {
          current: "当月",
          previous: "前月",
          rangeAndDate: "{{range}} {{from}}日~{{to}}日",
        },
        includeLabel: {
          departmentSearchRange: "部署検索範囲",
          aggregationStatus: "集計状況",
          exportationStatus: "会計データ出力状況",
          paymentStatus: "支払い状況",
          temporaryPaymentStatus: "仮払い状況",
          payOffStatus: "精算状況",
          matchedOriginalReceiptStatus: "原本確認状況",
          asInvoice: "適格請求書の取り扱い",
        },
      },
      latestJobExecution: {
        aggregateResults: "集計結果",
        aggregationFailure: "集計: 失敗",
        download: "ダウンロード",
      },
      descriptions: {
        aggregationDate: `「申請日」や「利用日」等の範囲を、タスクが実行されるタイミングで計算して、該当する経費申請を絞り込みます。

          タスクが2022年4月8日(金)に実行された場合の例：

          (例1) 月指定
          「1」「月」「前」の「1日」から
          「1」「月」「後」の「15日」まで
          → 2022年3月1日~2022年5月15日 \

          (例2) 当月指定
          「0」「月」「前(or 後)」の「1日」から
          「0」「月」「前(or 後)」の「月末日」まで
          → 2022年4月1日~2022年4月8日
          ※4月9日以降のデータが存在していないため

          (例3) 週指定
          「2」「週」「前」の「日曜日」から
          「1」「週」「前」の「土曜日」まで
          → 2022年3月20日(日)~2022年4月2日(土)

          (例4) 日指定
          「1」「日」「前」から
          「1」「日」「前」まで
          → 2022年4月7日(木)`,
        charsRestriction:
          "使用できる文字は「英数字」「平仮名」「カタカナ」「漢字」「長音記号」「ハイフン」「アンダーバー」「{」「}」です。",
        dateFormat: `年、月、日を指定できます。

          例）タスク実行日が2021/9/5(令和3年9月5日)の場合
          ・{}内のみ変換
          {jpymd}_data → 395_data
          ・0埋めでの表示
          {yyyymmdd} → 20210905
          ・ 月末日付へ変更
          {yymmlastdd} → 210930
          ・月日の変更
          {yymmdd[+2]} → 210907 ( 2日後 )
          {yymm[-2]dd} → 210705 ( 2ヶ月前 )
          ・直近の営業日へ変更
          {last_business_day(yyyymmdd)} → 20210903
          {next_business_day(yyyymmdd)} → 20210906`,
        dateFormatNote: `年月日を引用しない設定(ABC,あいう等)は非推奨です。
          ファイルを転送する場合、転送されるファイル名が変化しないため転送先でファイル上書きが発生します。
          例)
          ・申請フォーマット名「ABC」の場合の作成ファイル
          ABC.csv
          ※転送されるファイル名は毎回一致し、上書きされる`,
        executionDay: `集計の対象となる申請は、タスク実行時刻までに存在する申請になります。
          実行日に「毎月」「月末日」を指定し、タスク実行時刻が 12:00 の場合、月末日12:00以降に作成された申請は集計の対象となりません。`,
      },
      readOnlyForm: {
        bankDataCreation: {
          title: "全銀データ",
          enableField: {
            title: "出力",
            enable: "出力する",
            disable: "出力しない",
          },
          exportFileName: "出力ファイル名フォーマット",
          paymentDate: "支払指定日",
          paymentAccount: "支払口座",
          executionDate: {
            prefix: "実行日",
            afterDay: "⽇後",
            beforeDay: "⽇前",
            afterBusinessDay: "営業⽇後",
            beforeBusinessDay: "営業⽇前",
          },
        },
      },
    },
    noLicense: {
      messageForRP:
        "会計データ定期出力機能（TOKIUM経費精算）は、有料オプションとなります。ご利用の際には、サポートまでご連絡ください。",
      message:
        "会計データ定期出力機能（TOKIUM経費精算・TOKIUMインボイス）は、有料オプションとなります。ご利用の際には、サポートまでご連絡ください。",
      summaryForRP: `[TOKIUM経費精算 > 集計] 画面で実施可能な以下の操作を、指定のスケジュールに自動実行できます。

      ・集計の作成
      ・作成した集計から会計データを出力
      ・作成した集計から全銀データを出力

      また、出力したファイルを指定のFTPサーバーに自動転送できます。
      `,
      summary: `[TOKIUM経費精算・TOKIUMインボイス > 集計] 画面で実施可能な以下の操作を、指定のスケジュールに自動実行できます。

      ・集計の作成
      ・作成した集計から会計データを出力
      ・作成した集計から全銀データを出力

      また、出力したファイルを指定のFTPサーバーに自動転送できます。
      `,
    },
  },
  genericFields: {
    dataSets: {
      alertSettings: {
        title:
          "利用する経費で「必須入力」を有効にした場合、アラート機能で警告表示や申請不可とすることができます。",
        urlName: ">> アラート設定に移動",
      },
      titles: {
        list: "汎用マスタ一覧",
        createDataSet: "汎用マスタ 新規作成",
        removeDataSet: "汎用マスタ 削除",
      },
      items: {
        code: "コード",
        name: "名称",
        description: "説明",
        sort: "表示順",
        enabled: "有効・無効",
        usingCategories: "関連する科目",
        linkToItems: "選択肢一覧",
        usingCategoryAutoSettingHelpText:
          "有効にした場合、科目登録時に自動的に関連する科目として設定します。",
        usingCategoryAutoSetting: "新規登録した科目に自動的に関連付ける",
        usingCategoryAutoSettingParams: {
          request: "必須入力",
          multiple: "複数選択",
        },
        usingCategorySettingHelpText:
          "「関連する科目」が選択された際に、このマスタの入力フォームが表示されるようになります。",
      },
      usingCategoriesParam: {
        expenseCategory: "経費科目",
        debitInvoiceCategory: "請求明細科目",
        required: "必須入力",
        multiple: "複数選択",
        checkAll: "全選択/全解除",
      },
      actions: {
        selectUsingExpenseCategories: "関連する経費科目を選択",
        selectUsingDebitInvoiceCategories: "関連する請求明細科目を選択",
      },
      errors: {
        name: "名称は必須項目です。",
        reachedLimit: "汎用マスタを登録できるのは{{limit}}件までです。",
      },
      messages: {
        confirmDeleteDataSet: "汎用マスタを削除しますか？",
      },
      creatableByAllUsers: {
        label: "従業員が選択肢を作成可能",
      },
    },
    items: {
      table: {
        code: "コード",
        name: "名称",
        sort: "表示順",
        userName: "作成者",
        createdAt: "作成日時",
        createdMethod: "作成元",
        linkToMetadata: "付加情報設定へ",
        method: {
          preferencesSetting: "システム設定",
          preferencesImport: "インポート",
          externalApi: "外部API",
          usersExpense: "経費",
          usersInvoice: "インボイス",
        },
      },
      titles: {
        list: "選択肢一覧",
        createItem: "選択肢 新規作成",
        removeItem: "選択肢 削除",
        selectorTitle: "を選択",
      },
      enable: {
        true: "有効",
        false: "無効",
      },
      errors: {
        code: "コードは必須項目です。",
        name: "名称は必須項目です。",
        sort: "表示順は必須項目です。",
        failedToDelete: "選択肢を削除できませんでした",
      },
      labels: {
        code: "コード",
        name: "名称",
        isDeleted: "削除済み",
      },
      imports: {
        title: "汎用マスタインポート",
        importItems: "汎用マスタの情報をインポートします",
        sample: "サンプル",
        guide2:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
        moveExport: "現在の設定内容をエクスポート",
      },
      exports: {
        title: "汎用マスタエクスポート",
        createLatestExportFile: "最新のファイル作成の開始",
        guide:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
        moveImport: "インポート画面へ移動",
      },
      messages: {
        confirmDeleteItem: "選択肢を削除しますか？",
        confirmDeleteAll:
          "チェックした選択肢をすべて削除します。本当によろしいですか？",
      },
      search: {
        placeholders: "複数キーワードの指定が可能",
      },
      button: {
        addButton: "選択肢を追加",
        disabledAddButton: "コードと名称を入力し、新しい選択肢を追加できます",
      },
      unit: "項目",
    },
    licence: {
      noLicenseMessage:
        "汎用マスタ機能のご利用は有料オプションとなります。ご利用の際には、サポートまでご連絡ください。",
      guidanceForSupportPage: "※機能に関する説明は",
    },
  },
  agents: {
    labels: {
      agent: "代理",
    },
  },
  aggregationResults: {
    actions: {
      addAccount: "連携カード・サービスを追加する",
      createSelected: "経費を登録",
      deleteSelected: "履歴を削除",
    },
    errors: {
      categoryMissing: "経費科目を選択して下さい",
      failedToDelete: "利用履歴の削除に失敗しました",
      paymentGroupMissing: "費用負担部署を選択してください",
    },
    messages: {
      delete: "利用履歴を削除しました",
    },
    titles: {
      accountList: "連携カード・サービス一覧",
      createExpenses: "経費を登録する",
      paymentStatement: "利用履歴",
    },
  },
  transactions: {
    titles: {
      page: "経費",
      manual: "手入力",
      fare: "交通費入力",
      picture: "自動入力",
      list: "経費一覧",
      spentList: "支出経費一覧",
      transactionsImport: "経費インポート",
      add: "経費登録",
      edit: "経費編集",
      details: "経費詳細",
      columnSettings: "表示項目設定",
    },
    properties: {
      id: "ID",
      authorName: "経費作成者",
      date: "利用日",
      optionalDate: "利用日 (経費)",
      shop: "支払先",
      visited: "訪問先",
      origin: "出発駅",
      destination: "到着駅",
      originByCategory: "出発地",
      destinationByCategory: "到着地",
      originAndDestinationByCategory: "発着地",
      visitByCategory: "訪問先",
      purposeByCategory: "目的",
      amount: "金額",
      amountPerPerson: "[一人あたり: {{amount}}]",
      rate: "レート",
      currency: "通貨",
      withholding: "源泉徴収税額",
      employeeName: "従業員名",
      taxCategory: "税区分",
      taxCategoryForAmountLabel: "税区分別金額",
      amountPerTaxCategory: "総額",
      taxAmountPerTaxCategory: "消費税額",
      multipleTaxRates: "複数税率",
      category: "経費科目",
      creditCategory: "貸方勘定科目",
      reportTitle: "経費申請書名",
      preReportTitle: "事前申請名",
      personalReportTitle: "レポート名",
      image: "領収書",
      isElectronicReceiptImage: "電子領収書",
      transitPayee: "交通費支払先",
      comment: "メモ",
      status: "ステータス",
      registrationDate: "登録日",
      project: "プロジェクト",
      department: "費用負担部署",
      noRefund: "精算対象外",
      timeStamp: "タイムスタンプ",
      read: "既読",
      unread: "未読",
      userDepartment: "所属部署",
      reportSequenceNum: "経費申請ID",
      preReportSequenceNum: "事前申請ID",
      label: "ラベル",
      companion: "参加者",
      preTransactionContent: "内容",
      eligibleInvoiceConfirmation: "適格請求書確認",
      asEligibleInvoice: "適格請求書として扱う",
      eligibleInvoiceTitle: "適格請求書",
      eligibleInvoice: {
        regardAs: "扱う",
        notRegardAs: "扱わない",
      },
      eligibleInvoiceNotice:
        "登録番号が確認できていても、適格請求書の要件を満たしていない場合があります",
      registratedNumber: "登録番号",
      invoicingOrganization: "適格請求書発行事業者",
      paidAddress: "住所又は所在地",
      examples: {
        preTransactionContent: "例）航空券",
      },
      hasValidatedReceiptMatching: "原本確認",
      rateReferenceType: {
        label: "レート指定",
        setting: {
          label: "設定値を参照",
          description: "管理者が設定しているレートを適用します",
        },
        manual: {
          label: "指定する",
        },
        unspecified: {
          label: "指定なし",
          description: "レート・金額を維持し、通貨のみを変更します",
        },
      },
      resolutionInformation: {
        colorSpaceLabel: "色階調",
        colorSpaceUnit: "階調",
        dpiLabel: "スキャナ保存要件/ 解像度",
        areaDimension: "画素数",
        dpi: "解像度",
        foreside: "表面",
        backside: "裏面",
        noContent: "画像がありません",
        noData: "データなし",
        digital: "電子取引データ",
      },
      electronicReceiptAlert: {
        title: "電子領収書が指定されています",
        description1:
          "電子領収書として受け取ったPDFなどのファイルをそのまま添付してください。",
        description2:
          "紙に印刷して撮影やスキャンしたファイルを添付しないでください。",
      },
    },
    index: {
      addTransactions: "経費を登録する",
      createTransaction: "経費を登録",
      selectTransaction: "経費を選択",
      editSelected: "選択した経費を編集する",
      editSelectedMulti: "一括編集",
      detachSelected: "除外",
      deleteSelected: "削除",
      mergeSelected: "統合",
      displayItemsSettings: "表示項目の設定",
      otherActions: "その他の操作",
      displayItems: "表示項目",
      selectedColumns: "選択中の列",
      moveToTop: "一番上に移動",
      displayItemsSettingsCaution:
        "設定内容はご利用中のブラウザにのみ保存されます。",
      selectFromExistingExpenses: "作成済みの経費から選択",
      createExpense: "経費を新規作成",
      addSelectedToReport: "選択した経費を追加する",
      searchbox: {
        openForm: "検索条件を設定",
        all: "すべて",
        resetCondition: "検索条件をリセット",
        search: "この条件で検索",
        scope: {
          label: "検索範囲",
          all: "会社全体",
        },
        onlyDeleted: "削除済み",
        exportLineId: "出力ID",
        selectAll: "全選択",
        sequenceNum: "申請ID",
        searchTarget: "検索対象",
        searchDisplay: "表示条件",
        userDepartment: "所属部署",
        departmentScope: {
          label: "部署検索範囲",
          includeChildDepartment: "下位部署を含む",
          showAllDepartments: "兼務情報を全て表示する",
          onlyMainDepartment: "主所属のみ",
        },
        toBePaid: {
          label: "精算区分",
          yes: "精算対象",
          no: "精算対象外",
        },
        paidByCorporateCard: {
          label: "コーポレートカード利用",
          description:
            "コーポレートカード（法人カード管理（管理者画面）にて設定）利用有無によって検索します",
          yes: "あり",
          no: "なし",
        },
        electronicReceiptImage: {
          label: "電子領収書区分",
          yes: "電子領収書",
          no: "電子領収書以外",
        },
        includeDraftSaved: {
          label: "下書き保存中の申請を含める",
          description:
            "チェックを入れると、下書き保存中の経費申請書に含まれる経費が検索対象に含まれます。",
          disabled:
            "未申請・データ化中・データ化失敗のいずれかにチェックが入っている場合に利用できます。",
        },
        receiptExpenseMatching: {
          label: "原本確認",
          alreadyMatched: "確認済み",
          notMatched: "未確認",
          electronicOrNotAttachedReceiptImage:
            "不要（領収書添付なし/電子領収書）",
          markedAsMatched: "確認済み（ステータス更新）",
          descriptions: {
            markedAsMatched:
              "原本を紐づけずに経費を確認済みとしたステータスです。これは原本との突合が適切に行われなかった（1つの原本を複数の経費に添付した場合等）、または原本が存在しなかった経費が対象となります。",
          },
        },
        settingLabel: "設定状況",
        isUnassignedApprovalFlow: {
          label: "申請フロー未設定",
          description:
            "同時に申請フロー名を指定した場合には、その申請フローが設定されてない従業員を検索できます",
        },
        blankSearch: "空白検索",
        emptyAmount: "金額空白検索",
        emptyTransactedAt: "利用日空白検索",
        emptyShopname: "支払先空白検索",
        asInvoiceForSearch: "適格請求書の取り扱い",
        registratedNumber: "登録番号",
        asInvoiceCheck: {
          true: "あり",
          false: "なし",
        },
      },
      cannotRequest: {
        chooseExpenses: "申請する経費を選択してください",
        includeUnrequestableExpense:
          "選択項目の中に申請できない経費が含まれています。",
        checkAlertFirst: "アラートに",
        checkAlertlast: "が表示されている経費をご確認ください。",
        alreadyRequestedExpense:
          "既に申請/事前申請に追加済みの経費は申請できません。",
        workerInputtingExpense: "自動入力中の経費は申請できません。",
        associatedWithPreReportExpense:
          "事前申請に追加済みの経費は申請できません。",
        notAuthorizedAgent: "代理人に申請権限が与えられていません。",
      },
      results: "検索結果",
      alert: "アラート",
      inputByWorker: "自動入力された経費です",
      untouchedAmount: "カード連携で取得された金額です",
      unknownCategory: "経費科目不明",
      editMode: "編集モード",
      columnSelection: "表示設定",
      restoreDefaultColumnSelection: "初期設定に戻す",
      resolutionInformation: {
        requirementTitle: "スキャナ保存要件",
        detail: "詳細",
        requirementDetail: `領収書画像をアップロードし直してください。
        ・DPIは縦横それぞれ200DPI以上
        ・画素数は横画素数が1,654以上、縦画素数が2,338以上かつ総画素数が3,870,000以上
        スキャナ保存要件に適合するためには、上記のいずれかの条件を満たす必要があります。
        `,
      },
    },
    inputs: {
      finish: "入力を終了する",
      again: "続けて入力",
      reuse: "入力内容を再利用する",
      cancelImage: "この画像をキャンセル",
      addCostAllocation: "費用負担部署を追加",
      image: "領収書画像",
      backsideImage: "領収書画像（うら面）",
      boardingDate: "利用日",
      multiDate: "複数日選択",
      inputShop: "支払先を入力",
      inputVisited: "訪問先を入力",
      noRouteSelected: "経路を検索してください",
      showOtherRoutes: "他の経路を見る",
      selectRoute: "経路を選択する",
      addVia: "経由地を追加",
      round: "往復",
      means: "交通手段",
      searchAgain: "再検索",
      detailSettings: "詳細設定",
      details: {
        shinkansen: "新幹線",
        limitedExpress: "有料特急",
        liner: "ライナー",
        localBus: "路線バス",
        connectionBus: "連絡バス",
        highwayBus: "高速バス",
        midnightBus: "深夜急行バス",
        plane: "空路",
        ship: "海路",
        surcharge: "特急料金",
        free: "自由席",
        reserved: "指定席",
        green: "グリーン車",
      },
      jrReservation: {
        exYoyaku: "ＥＸ予約",
        exETokkyu: "ＥＸ予約(ｅ特急券)",
        exHayatoku: "ＥＸ予約(ＥＸ早特)",
        exHayatoku21: "ＥＸ予約(ＥＸ早特２１)",
        exGreenHayatoku: "ＥＸ予約(ＥＸグリーン早特)",
        smartEx: "スマートＥＸ",
        smartExHayatoku: "スマートＥＸ(ＥＸ早特)",
        smartExHayatoku21: "スマートＥＸ(ＥＸ早特２１)",
        smartExGreenHayatoku: "スマートＥＸ(ＥＸグリーン早特)",
      },
      facilities: "交通機関",
      expressOptions: "特急料金",
      jrDiscount: "新幹線割引",
      bus: "バス",
      train: "電車",
      plane: "飛行機",
      others: "その他",
      icCard: "IC乗車券",
      fare: "運賃",
      onewayFare: "片道運賃",
      rideTime: "乗車時間",
      distance: "距離",
      inputAmount: "金額を入力",
      report: "経費申請書",
      personalReport: "レポート",
      file: "ファイル名",
      mergeWithAggregation: "カードの明細情報を統合",
    },
    labels: {
      noRefund: "対象外", // 経費一覧の精算対象外列に表示する文言
    },
    imports: {
      uploading: "アップロードしています...",
      selectFormat: "csvの形式を選択",
      allImportResults: "全てのインポート結果を表示",
      defaultShow: "通常では最新5件の結果を表示します",
    },
    droparea: {
      click: "クリックして{{ext}}を選択",
      or: "または",
      dragAndDrop: "{{ext}}をドラッグアンドドロップ",
      filename: "<em><b>{{name}}</b></em>がアップロードされます",
      image: "画像",
    },
    pdf: {
      failMessage:
        "※Internet Explorerでは、ブラウザの問題によりプレビュー機能をご利用できません。経費を一度作成すると、一覧よりプレビューでご確認いただけます。",
    },
    histories: {
      types: {
        add: "追加",
        edit: "編集",
        delete: "除外",
        split: "分割",
      },
      editorTypes: {
        system: "システム",
      },
      fieldTypes: {
        amount: "金額",
        transactedAt: "利用日",
        shop: "支払先/訪問先",
        isCorporate: "精算対象外",
        route: "経路",
      },
      properties: {
        changedAt: "変更日時",
        changeLog: "変更内容",
        editor: "変更者",
        project: {
          displayId: "ID",
          name: "名前",
        },
        amount: {
          value: "",
          originalValue: "(外貨)",
          originalCurrencyId: "通貨コード",
        },
        route: {
          onewayAmount: "片道運賃",
          origin: "出発駅",
          destination: "到着駅",
          lines: "経路",
          via: "経路",
          route: "経路",
          withIc: "ICカード",
          isRoundTrip: "往復",
        },
        receiptFile: {
          foreside: "表面",
          backside: "うら面",
        },
        originalReceipt: {
          match: "突合",
          cancel: "突合解除",
        },
        companion: {
          colleagues: "自社",
          clients: "取引先",
        },
        withholding: {
          amount: "税額",
          name: "受給者の氏名",
          address: "受給者の住所",
        },
        markAsMatched: {
          match: "確認済み",
          cancel: "解除",
          values: {
            false: "未設定",
            true: "確認済み（ステータス更新）",
          },
        },
        split: {
          source: "分割元",
          destination: "分割先",
          attached: "この経費が申請に含まれています",
        },
      },
      messages: {
        count: "{{count}}件の変更履歴があります",
        // "count_plural": "{{count}}件の変更履歴があります",
      },
      titles: {
        originalReceiptDetails: "原本詳細",
        receiptFileDetails: "領収書詳細",
        history: "編集履歴",
      },
    },
    confirmationTitles: {
      confirmEmptyRequiredFields: "入力されていない項目の確認",
      confirmWithholding: "源泉徴収税額の自動計算",
      confirmCancelReceiptMatching: "原本ひも付き解除確認",
    },
    messages: {
      success: "経費登録が完了しました",
      create: "経費を登録しました",
      delete: "経費を削除しました",
      merge: "経費を統合しました",
      deleteImage: "画像を削除しました",
      confirmDelete: "選択された経費を全て削除しますか？",
      detach: "経費を申請から除外しました",
      confirmDetach: "本当に経費を除外しますか？(元の経費は削除されません)",
      confirmAllDetach:
        "選択された経費を全て除外しますか？(元の経費は削除されません)",
      confirmEmptyRequiredfields:
        "{{emptyRequiredfields}}が入力されていませんがよろしいですか？",
      confirmWithholding:
        "源泉徴収対応が必要な経費科目へ更新しようとしています。源泉徴収税額を金額から自動計算して保存しますか？(すでに源泉徴収税額が保存されている場合は上書き保存されます)",
      confirmMerge:
        "選択された{{selected}}件の経費のうち、以下の{{mergeable}}件の経費が統合可能です。これらの経費を統合し、{{merged}}件の経費にまとめますか？",
      update: "経費を更新しました",
      saveRotation: "回転を保存しました",
      isCorporate: "精算対象外の経費です",
      isElectronicReceiptImage: "電子領収書に紐づく経費です",
      isMerged: "統合された経費です",
      fromWorker: "自動入力された経費です",
      fromCard: "関連するカード連携のデータが存在します",
      foreignCurrency: "外貨を利用した経費です",
      confirmCancelReceiptMatching:
        "領収書画像を更新すると、原本とのひも付きまたは原本確認済みのステータスが解除されます。",
      multipleTaxRates:
        "対象経費の経費科目が複数の税率を登録できる設定となっている場合に「●」が表示されます。",
      uploadedBy: "{{name}}({{email}}) がアップロードしました",
      uploadedByYourself: "本人がアップロードしました",
      waitingToUpload: "アップロード待ちです",
      expenseCanBeAttachedToAlreadyCreatedReportsOrPreReports:
        "作成済みの経費申請・事前申請に経費を追加できます",
      expenseCanBeAttachedToAlreadyCreatedReports:
        "作成済みの経費申請に経費を追加できます",
    },
    errors: {
      duplicateReport: "過去に作成された経費申請名は使えません",
      costAllocationDecimal: "負担率に小数の値が含まれています",
      costAllocationNonPositive: "負担率に0以下の値が含まれています",
      costAllocationSumOfFraction: "負担率の合計が100%になりません",
      costAllocationDuplicature:
        "費用負担部署に同じ部署を2つ以上選択しないでください",
      didNotEnterCurrencyRate: "手動設定の場合はレートを入力してください",
      amountDoedNotEqualTotalAmountPerTaxCategories:
        "金額と税区分金額の合計が一致しません",
      noImage: "領収書がありません",
      noOrigin: "出発地を入力して下さい",
      noDestination: "到着地を入力して下さい",
      noTransportFacilities: "交通手段を少なくとも一つ選択してください",
      routeNotFound: "経路が見つかりません",
      routeUnselected: "出発駅・到着駅を選択して、経路を検索してください",
      communicationError: "駅検索中に通信エラーが発生しました",
      failedToLoadCategory: "経費科目の取得に失敗しました",
      failedToCreateData: "経費の作成に失敗しました",
      failedToUpdateData: "経費の更新に失敗しました",
      failedToDelete: "経費を削除できませんでした",
      failedToDetach: "経費を除外できませんでした",
      failedToMerge: "経費を統合できませんでした",
      failedToSaveRotation: "回転を保存できませんでした",
      failedToUploadImage:
        "領収書画像のアップロードに失敗しました。もう一度アップロードしてください。",
      failedToSignTimeStamp:
        "領収書画像へのタイムスタンプ付与に失敗しました。お手数ですがもう一度アップロードしてください。",
      uploadLimitError:
        "領収書画像のサイズが制限を超えています。25MB以下のファイルをアップロードしてください",
      failedToSearchForRoute: "経路の検索に失敗しました",
      invalidDate: "不正な日付です",
    },
    costAllocationModal: {
      title: "費用負担部署詳細",
      costAllocation: "負担割合",
      detail: "詳細",
    },
  },
  expenses: {
    split: {
      dropdownButtonItem: "{{item}}で分割",
      totalAmountsDisplay: {
        totalAmountBeforeSplit: "分割元経費の金額",
        totalAmountAfterSplit: "分割先({{splitCount}}件)の合計金額",
      },
      amountBySeparatorsEditor: {
        title: "分割する項目と金額を入力してください",
        totalAmount: "合計金額",
        addButton: "さらに分割",
        categorySelectTitle: "経費科目を選択してください",
        projectSelectTitle: "プロジェクトを選択してください",
        amountInputTitle: "金額を入力してください",
      },
      diffAmount: {
        overOriginalAmount: "{{diffAmount}} 超過",
        lessThanOriginalAmount: "{{diffAmount}} 不足",
      },
      warning: {
        makesManualInputedExpense: "分割した経費は、手入力した経費となります。",
        resetTaxCategory:
          "税区分が複数ある場合、分割した金額が1つ目の税区分に割り当てられます。",
      },
      confirmModalTitle: "経費を分割しますか？",
      selectCategoryTitle: "経費科目を指定してください",
    },
    timestamp: {
      inprogress: "タイムスタンプ付与中",
      failed: "タイムスタンプ付与失敗",
    },
    workerInputCorrectionRequest: {
      button: "自動入力修正依頼",
    },
    timestampVerify: {
      createdAt: "作成日",
      completedAt: "完了日",
      message: "メッセージ",
      result: "結果",
      searchedTarget:
        "検索条件を満たす全経費を対象にタイムスタンプ検証を実行します",
      selectedTarget:
        "選択した経費({{count}}件)を対象にタイムスタンプ検証を実行します",
      sample: "検証結果のサンプルのダウンロードはこちら",
      error: "経費のタイムスタンプ検証に失敗しました",
      title: "タイムスタンプ検証",
      warn: "タイムスタンプ検証は完了までお時間を要する場合がございます。ご了承ください。",
      showAll: "全ての検証結果を表示(通常では最新5件の結果を表示します)",
      start: "検証を開始",
      started: "経費のタイムスタンプ検証を開始しました",
      completed: "経費のタイムスタンプ検証が完了しました",
      inprogress: "経費のタイムスタンプ検証中です",
      unit: "検証済み領収書",
    },
    expenses: {
      components: {
        operatorEntryConfirmModal: {
          header: {
            title: "金額の確認",
          },
          body: {
            message1:
              "自動入力された金額・消費税額と現在の税区分別金額が異なります。",
            message2: "このまま保存しますか？",
            subtotal: "{{amount}} (税{{taxAmount}})",
          },
          footer: {
            accept: "はい",
            reject: "いいえ",
          },
        },
        operatorEntryInfo: {
          title: "自動入力結果",
          total: "合計",
        },
      },
    },
  },
  attachedFiles: {
    icons: {
      description: {
        notExist: "添付ファイルがありません",
        notFound: "ファイルが見つかりません",
        error: "ファイルを表示できません",
      },
    },
  },
  analyses: {
    titles: {
      aggregationHistory: "集計履歴",
      applicationList: "申請一覧",
      reportTitle: "経費申請名",
      preTravelReportTitle: "旅費申請書名",
      personalReportTitle: "レポート名",
      sequence: "申請ID",
      userName: "申請者名",
      applicantGroup: "申請者の所属部署",
      paymentGroup: "費用負担部署",
      project: "プロジェクト",
      applicantPost: "申請者の役職",
      approvedApprover: "承認済みの承認者",
      projectId: "プロジェクトID",
      projectName: "プロジェクト名",
      tag: "申請者のラベル",
      amount: "総額",
      preAmount: "事前申請額",
      status: "ステータス",
      aggregationStatus: "集計状況",
      exportationStatus: "会計データ出力状況",
      exportationStatusAlt: "会計データ出力",
      paymentStatus: "支払い状況",
      paymentStatusAlt: "支払いステータス",
      temporaryPaymentStatus: "仮払い状況",
      payOffStatus: "精算状況",
      departmentCondition: "部署検索範囲",
      includeChildDepartment: "下位部署を含む",
      createdAt: "作成日時",
      aggregationName: "集計名",
    },
    searchbox: {
      openForm: "検索条件を設定",
      search: "この条件で検索",
      aggregate: "この条件で集計",
    },
    toolbar: {
      aggregationButton: "選択した{{length}}件の申請を集計する",
    },
    aggregationModal: {
      title: "集計名を入力してください",
      placeHolder: "集計名を入力",
      submit: "集計を作成",
      errorNoData: "集計名を入力してください",
    },
    properties: {
      nothingApplicantGroup: "申請者部署なし",
      nothingPaymentGroup: "費用負担部署なし",
      noProjects: "プロジェクトなし",
      multipleApplicantGroup: "複数の所属部署",
      multiplePaymentGroups: "複数の費用負担部署",
      multipleProjects: "複数のプロジェクト",
      paymentStatusPaid: "支払処理済み",
      paymentStatusNotPaid: "未支払",
      exportStatusExported: "会計データ出力済み",
      exportStatusNotExported: "未出力",
    },
    messages: {
      deleteReportSuccess: "申請を削除しました",
      deletePersonalReportSuccess: "レポートを削除しました",
      changeReportTitleSuccess: "申請名を変更しました",
      changePersonalReportTitleSuccess: "レポート名を変更しました",
      confirmDelete: "「{{name}}」を本当に削除しますか？",
      confirmAnalysesExport: "会計データ出力完了としますか？",
      irreversibleOperation: "この操作は元に戻せません。",
      failedToDeleteReport: "申請の削除に失敗しました",
      failedToDeletePersonalReport: "レポートの削除に失敗しました",
      cannotPayReports: "支払処理完了にできない申請が含まれています",
      cannotPayTemporarilyReports: "仮払処理完了にできない申請が含まれています",
    },
  },
  reports: {
    types: {
      report: "経費申請",
      preReport: "事前申請",
      preTravelReport: "旅費申請",
      application: "汎用申請",
    },
    titles: {
      expenses: "申請可能経費",
      personalExpenses: "未レポート経費一覧",
      checkContents: "申請内容を確認 ({{count}}件)",
      // "checkContents_plural": "申請内容を確認 ({{count}}件)",
      reportList: "申請一覧",
      personalReportList: "レポート一覧",
      timeline: "タイムライン",
      kinds: "申請の種類",
      list: "経費精算申請一覧",
      reapply: "前回と同じ承認フローで申請しますか？",
      reportRequest: "経費精算",
      attachableExpenses: "追加可能経費",
    },
    properties: {
      application: "申請",
      applicationName: "申請名",
      reportTitle: "経費申請名",
      personalReportTitle: "レポート名",
      applicantName: "申請者名",
      amount: "総額",
      withholding: "源泉徴収税総額",
      preAmount: "事前申請額",
      temporaryPaymentDueAt: "仮払希望日",
      reimbursableExpense: "立替精算額",
      status: "申請状況",
      statusForApprover: "承認状況",
      lastApprovedDate: "最終承認日",
      comment: "コメント",
      approvalFlow: "承認フロー",
      requestDate: "申請日",
      scheduleAt: "支払希望日",
      sequence: "申請ID",
      approvalFlowName: "申請フロー名",
      requestType: "申請種別",
      reportTypes: {
        report: "経費申請",
        noTemporaryPayment: "精算(仮払以外)",
        temporaryPayment: "精算(仮払)",
      },
      currentApprovalFlow: "前回の承認フロー",
      matchedOriginalReceipt: "原本確認状況",
      reportRequestTypes: {
        chooseFromExpenses: "経費を選択して申請する",
        chooseFromPreReports: "未精算の事前申請を選択して精算する",
      },
      preReportAndReport: "経費申請・事前申請",
    },
    index: {
      addReport: "申請する",
      addPersonalReport: "レポートを追加",
      scope: {
        label: "検索範囲",
        all: "会社全体",
      },
      matchedOriginalReceipt: {
        label: "原本確認状況",
        yes: "完了",
        no: "未完了",
        description:
          "申請内経費の添付画像に対する原本の確認が全て完了したものを絞り込めます。また、交通費等の添付画像がない場合も確認完了として判断されます。",
      },
    },
    requests: {
      selectAll: "すべて選択する",
      selectByProject: "プロジェクトから選択する",
      selectThisMonth: "今月分を選択する",
      selectLastMonth: "先月分を選択する",
      selectAdvancedPayment: "自分(精算対象)",
      selectCorporatePayment: "法人",
      selectPayer: "請求先から選択",
      clearSelection: "選択をクリア",
      submitAll: "全て経費申請する",
      submitSelected: "選択した経費を申請する",
      saveAsDraft: "下書き保存する",
      createReportOfAll: "全てをレポートにする",
      createReportOfSelected: "選択した経費をレポートにする",
      enterReportTitle: "申請書名を入力",
      enterPersonalReportTitle: "レポート名を入力",
      enterComment: "コメントを入力",
      showExpenses: "含まれる経費を確認",
      detach: "申請から除外する",
      submit: "申請する",
      applyNewApprovalFlow: "新しい申請フローで申請",
      useCurrentApprovalFlow: "前回と同じフローで申請",
      temporaryPaymentProcessed: "仮払処理済みにする",
      createBlankReport: "申請せずに、空の申請書を保存します。",
      createReportWithSelectedExpenses:
        "申請せずに、選択した経費を添付した申請書を保存します。",
      youCanAddExpenseLaterAndRequest: "後から経費を追加し、申請できます。",
      saveDraftReport: "申請書を下書き保存する",
    },
    timeline: {
      markerMessage: "以下の承認が必要です",
    },
    messages: {
      submitSuccess: "申請が完了しました",
      deleteReportSuccess: "申請を削除しました",
      deletePersonalReportSuccess: "レポートを削除しました",
      changeReportTitleSuccess: "申請名を変更しました",
      changePersonalReportTitleSuccess: "レポート名を変更しました",
      confirmDelete: "「{{name}}」を本当に削除しますか？",
      statusFormat: "{{datetime}}に申請",
      statusFormatPersonalPlan: "{{datetime}}に作成",
      enterComment: "コメントを入力してください...",
      selectApprovers: "承認者を選択してください",
      exportSize: "出力可能なデータ件数は最大 {{size}} 件までです。",
      noSelectableProjects: "選択可能なプロジェクトがありません",
      addExpensesSuccess: "経費を追加しました",
      noSelectableReports: "選択できる申請書がありません",
      noData: "申請書が見つかりませんでした。",
    },
    errors: {
      failedToDeleteReport: "申請の削除に失敗しました",
      failedToDeletePersonalReport: "レポートの削除に失敗しました",
      workerInProgress: "データ化中の経費があるため申請できません",
      failedToCreateData: "データ化不可の経費があるため申請できません",
    },
    searchConditions: {
      openForm: "検索条件を指定",
      ReportId: "申請ID",
      ReportName: "申請名（キーワード）",
      approvalFlowName: "承認フロー名",
      optionalDate: "利用日（経費）",
      requestDate: "申請日",
      lastApprovedDate: "最終承認日",
      approvedUser: "承認済みの承認者",
      IncludedInApprovalFlow: "承認フローに含まれる承認者",
      applicantName: "申請者名",
      applicantDepartment: "申請者の所属部署",
      category: "経費科目",
      approvalStatus: "承認状況",
      approvalStatusForApprover: "あなたの承認状況",
      projectId: "プロジェクトID",
      projectName: "プロジェクト名",
      includesAborted: "「フロー変更待ち」も含まれます",
      searchWithReportTitle: "申請名で検索",
    },
    modals: {
      actions: {
        createCsv: "最新の出力ファイル作成を開始",
      },
    },
  },
  preReports: {
    properties: {
      title: "事前申請名",
      content: "事前申請内容",
      amount: "総額",
      withholding: "源泉徴収税総額",
      preAmount: "事前申請額",
      applicantName: "申請者名",
      status: "ステータス",
      sequence: "申請ID",
      project: "プロジェクト",
      department: "所属部署",
    },
    items: {
      addItems: "項目を追加する",
      anItem: "事前申請項目",
      errors: {
        amount: {
          blank: "金額を入力してください",
        },
        category: {
          blank: "経費科目を選択してください",
        },
        content: {
          blank: "内容を入力してください",
        },
        date: {
          blank: "日付を入力してください",
          invalid: "正しい日付を入力してください",
        },
      },
      menu: "事前申請項目",
    },
    titles: {
      list: "事前申請一覧",
    },
    reportablePreApplications: "精算可能事前申請",
  },
  preTravelReports: {
    titles: {
      list: "旅費申請一覧",
    },
  },
  temporaryPayments: {
    titles: {
      list: "仮払一覧",
    },
    properties: {
      title: "事前申請名",
      preAmount: "仮払額",
      paidAt: "仮払支払日",
      amount: "利用額",
      withholding: "源泉徴収税額",
      refundAmount: "返金額",
      applicantName: "申請者名",
      status: "ステータス",
      sequence: "申請ID",
      paymentDate: "仮払日時",
      refundDate: "仮払返金日時",
    },
  },
  exports: {
    titles: {
      fileExport: "ファイル出力",
      accountingData: "会計データ出力",
      applicationForm: "精算申請書出力",
      preReport: "事前申請書出力",
      temporaryPayment: "仮払申請書出力",
      zengin: "全銀データ出力",
      zenginAndAccountingData: "全銀データ・会計データ出力",
      finishToExport: "会計データ出力完了",
      finishToPay: "支払処理完了",
      completedTemporaryPayment: "仮払処理完了",
      csvExport: "CSV出力",
      aggregationByUserExport: "従業員毎集計出力",
      aggregationByCategoryExport: "勘定科目毎集計出力",
      expenseAccountSelector: "支払口座を選択",
    },
    actions: {
      exportColumns: "この条件で会計データ作成を開始",
      showModal: "この条件でファイル出力する",
      searchWithAccount: "金融機関名・支店名・口座番号で検索",
      allExportResults: "最新の100件を表示",
      defaultShow: "通常では最新5件の結果を表示します",
    },
    inputs: {
      recipient: "送信先",
      selectFormat: "出力形式を選択",
      sendEmail: "メールで送信",
      or: "または",
      download: "ダウンロード",
      requestAccountingCsv: "最新の会計データ作成を開始",
      downloadXls: "精算申請書（xls形式）をダウンロード",
      requestXlsZip: "精算申請書（xls形式）のダウンロードをリクエスト",
      requestAggregationByCategoryExportFile: "勘定科目毎集計（csv形式）を作成",
      requestAggregationByUserExportFile: "従業員毎集計（csv形式）を作成",
      downloadPreXls: "事前申請書（xls形式）をダウンロード",
      downloadTemporaryPaymentXls: "仮払申請書（xls形式）をダウンロード",
      requestedAt: "作成開始日時",
      completedAt: "作成完了日時",
      exportType: "出力形式",
      processing: "作成中",
      format: "ファイル形式",
      downloadXlsx: "最新のエクスポートファイル作成を開始",
      error: "エラー",
      result: "出力結果",
      transactionDate: "取引日付の一括指定",
    },
    messages: {
      sendEmailSuccess: "メール送信が完了しました",
      startGenerateCsv: "CSVの作成を開始しました",
      emailFormat: "送信先を選択してください",
      exported: "出力済み",
      auto: "（自動）",
      aggregatedManually: "手動",
      aggregatedByScheduledExportsTask: "自動(会計データ定期出力タスク)",
      enterTransactionDate: "取引日付を入力してください",
      changeAnalysisTitleSucceed: "集計名を変更しました",
    },
    errors: {
      emailMissing: "正しい送信先を選択してください",
      workerInProgress: "データ化中の経費があるため出力できません",
      payeeAccountBlank:
        "支払先の払込口座が指定されていることを確認してください",
      payerCompanyAccountBlank:
        "事業所の払出口座が設定されていることを確認してください",
    },
  },
  companions: {
    companion: "参加者",
    ourCompany: "自社",
    partner: "取引先",
    addCompanion: "社員を追加",
    addOurs: "自社の参加者を追加",
    ours: "自社の参加者",
    addPartners: "取引先の参加者を追加",
    partners: "取引先の参加者",
    name: "氏名",
    number: "人数",
    companyName: "会社名",
    group: "部署名",
    companyPost: "役職名",
    ourStaff: "自社の社員：名前、部署で検索",
    partnerStaff: "取引先の社員：名前、会社名などで検索",
    companionName: "参加者名",
    detail: "詳細",
    messages: {
      added: "参加者を追加しました",
      edited: "参加者を編集しました",
      deleted: "参加者を削除しました",
      notFound: "参加者が見つかりません",
      confirmToDelete: "参加者を削除してよろしいですか？",
    },
    title: {
      participantsList: "参加者一覧",
      participantImport: "参加者インポート",
      addPaticipant: "参加者の追加",
      editPaticipant: "参加者の編集",
    },
    actions: {
      importCSV: "インポート",
      addParticipant: "参加者追加",
      formatExplanation: "フォーマットは名前,会社名,部署,役職です",
      forSample: "csvサンプルはこちら",
    },
  },
  imports: {
    startAt: "開始日時",
    endAt: "終了日時",
    message: "メッセージ",
    importResult: "インポート結果",
  },
  projects: {
    project: "プロジェクト",
    none: "プロジェクト名なし",
    information: "プロジェクト情報",
    deleted: "削除済み",
    imports: {
      importProjects: "プロジェクトをインポートします",
      limitOfNumberToImport: "一度に5万件までインポートできます",
      sample: "サンプル",
    },
    titles: {
      addMember: "プロジェクト役職に従業員を追加",
      createProject: "プロジェクトの作成",
      details: "プロジェクト詳細",
      exportModal: "プロジェクトエクスポート",
      importModal: "プロジェクトインポート",
      postSettings: "プロジェクト役職設定",
      projectList: "プロジェクト一覧",
      settings: "プロジェクト設定",
    },
    properties: {
      name: "プロジェクト名",
      id: "プロジェクトID",
    },
    actions: {
      searchPlaceholder: "複数キーワードの指定が可能",
      assignMembers: "役職を設定する",
      selectProject: "プロジェクトを選択",
      addProject: "プロジェクトを追加",
      searchByName: "ユーザ名で検索",
      useProjectSetting: "プロジェクト機能を利用する",
      employeeBuildProjects: "従業員がプロジェクトを作成可能",
      addMember: "従業員を追加",
      searchWithDeleted: "削除されたプロジェクトも検索する",
    },
    buttons: {
      add: "追加",
      moveToExportModal: "現在の設定内容をエクスポート",
      moveToImportModal: "インポート画面へ移動",
    },
    messages: {
      confirmDelete: "プロジェクトを削除します。よろしいですか？",
    },
  },
  approvals: {
    titles: {
      reports: "承認一覧",
      applicationApprovals: "汎用申請承認",
      approvalFlowInfo: "承認フロー詳細",
    },
    actions: {
      withdrawApproval: "承認を取り消す",
      approve: "承認する",
    },
    flows: {
      approver: "承認者",
      department: "承認部署",
      post: "役職",
      departmentPost: "部署役職",
      userDepartment: "所属部署役職",
      payerDepartment: "費用負担部署役職",
      projectPost: "プロジェクト役職",
      approvalStep: "{{order}}承認",
      notEditable: "編集不可",
      notSkippable: "スキップ不可",
      selfApprovable: "自己承認する",
      selfApprovalSkippable: "対象ステップをスキップ",
      all: "全員",
      someOne: "誰か一人",
      addStep: "承認フローを追加",
      addApprover: "承認者を追加",
      noResults: "承認候補が見つかりませんでした",
      empty: "申請時に選択",
      addCondition: "条件を追加",
      addAndCondition: "AND条件を追加",
      addOrCondition: "OR条件を追加",
      flowName: "フロー名",
      conditions: "条件",
      priority: "優先順位",
      description: "項目説明",
      specifiesDepartmentPosition: "個上の部署の役職を指定する",
      rules: {
        include: "含む",
      },
      options: {
        userDepartmentPosts: {
          upperLevelPostSelecting:
            "申請者の所属部署の、上位部署の役職を指定する",
          nearestPostsApproverSelecting: "申請者の直近部署の役職を指定する",
        },
        payerDepartmentPosts: {
          upperLevelPostSelecting: "費用負担部署の、上位部署の役職を指定する",
          nearestPostsApproverSelecting:
            "費用負担部署の直近部署の役職を指定する",
        },
      },
      descriptions: {
        conditions: `
          申請フローを適用する経費の条件を設定します。\n
          金額、経費科目、プロジェクト、部署などを条件として指定することができます。\n
          ただし、事前申請のフローの条件には経費科目のみを指定することができます。\n
          また、汎用申請には条件を指定できません。
        `,
        priority: `
          優先度の数字が小さい申請フローから順に、条件を満たす経費を申請に分割していきます。\n
          同じ優先度のものは設定できません。
        `,
        notEditable: `
          申請提出時に申請者が承認ステップを変更・削除できなくなります。\n
          申請者の判断で承認者を変更して良い場合はチェックを外してください。
        `,
        notSkippable: `
          該当ステップの承認者がいない場合に、申請を提出できなくなります。\n
          （例）特定部署に部長が不在の場合
        `,
        preReportCondition: "事前申請の紐づく経費精算のみに適用されます",
        selfApprovable:
          "申請者自身が承認者となる承認ステップについて、自ら承認を行う場合はチェックを入れてください。",
        selfApprovalSkippable: `
        申請者自身が承認者に含まれている承認ステップについて、申請者以外の承認が不要となる場合にはチェックを入れてください。\n
        （チェックをいれた場合の例）部長が申請した場合に「部長」のステップを飛ばす。\n
        （チェックをいれない場合の例）課長・部長がステップに指定されており、課長が申請した場合に「部長」が承認を行う
        `,
        upperLevelPostSelecting:
          "指定した階層以上の上位部署に役職者がいる場合、その中で一番近い部署に所属する役職者のみに承認依頼が届くようになります。",
        nearestPostsApproverSelecting:
          "親部署やその親部署など、上位部署それぞれに役職者がいる場合に、申請者から一番近い部署に所属する役職者のみに承認依頼が届くようになります。",
        noLimitedFlow: "すべての申請の種類で使用可能な承認フローです",
        limitedFlow: "現在の条件では、以下の申請の種類で使用可能です",
        noAvailableFlow: "使用可能な申請はありません",
      },
      labels: {
        preReport: "事前申請",
        totalAmount: "合計金額",
        post: "役職",
      },
      // マニュアル変更が必要なため今は他箇所に合わせて承認フローではなく申請フロー表記とする(CS確認済み)
      search: {
        userNameOrEmail: "氏名 または メールアドレス",
        departmentName: "部署",
        approvalFlowName: "申請フロー名",
        requestType: "申請の種類",
        post: "役職",
        selectApprovalFlow: "申請フローを選択",
      },
    },
    search: {
      openForm: "検索条件を指定",
    },
    messages: {
      stepConstraint:
        "部署を承認者とする場合、その部署に所属する1人のみの承認が必要となります。",
      stepEmpty: "承認者が1人もいない承認ステップはスキップされます。",
      rejected: "差し戻しました",
      approved: "承認しました",
      withdrewApproval: "承認を取り消しました",
      memberUnassignedApprovalFlows: "申請フロー未割当の従業員が存在します。",
      approverSelectFlowConstraint:
        "次の承認者を承認時に選択していく申請フローです。",
    },
  },
  notifications: {
    types: {
      expense: "経費に関する通知",
      request: "申請に関する通知",
      account: "法人カード連携に関する通知",
      originalReceipt: "原本に関する通知",
    },
    actions: {
      editExpense: "経費を編集",
      matchingExpense: "突合経費を選択",
      businessUse: "経費として処理",
      privateUse: "私用として処理",
      informAdministrators: "管理者へ通知する",
    },
    actionTitle: {
      ProcessOriginalReceipt: "原本を処理する",
      SubmitReceipt: "この領収書を提出しましたか？",
      privateUse: "私用として処理",
    },
    properties: {
      message: "通知内容",
      cardType: "カード種別",
      cardName: "カード名",
    },
    messages: {
      approvalPending: "承認の完了をお待ち下さい",
      unknownCategory: "経費科目を設定して下さい",
      requireCompanion: "参加者の設定が必要です",
      setCompanions: "参加者を設定する",
      mistakenlySubmit: `
        対象の原本について、経費としての処理が不要であることを管理者へ連絡します。/n
        通常、これは自らの提出した原本であるが、誤って投函した原本である場合に実施します。/n
        対象の原本は、この処理によって経費の原本として扱われなくなります。
      `,
      linkedAggregation: "新たに明細が連携されました",
    },
    errors: {
      failedToLoad: "読み込みに失敗しました。画面をリロードして下さい。",
    },
  },
  userPreferences: {
    titles: {
      mailNotification: "メール通知",
      editCompanions: "参加者管理",
      agentSettings: "代理人設定",
    },
    mailNotification: {
      mailNotificationSettings: "メール通知設定",
      applicationNotice: {
        title: "申請に関する通知",
        onApproved: "承認された時",
        onRejected: "差し戻された時",
        onCanceled: "承認が取り消された時",
        onCommented: "コメントがあった時",
      },
      noticeToApprover: {
        title: "承認に関する通知",
        onNewApplication: "新たに承認が必要な申請がある時",
        onWithdrawn: "承認した申請が取り下げられた時",
        onRejected: "他の承認者が差し戻した時",
        onCommented: "承認した申請にコメントがあった時",
      },
      agentNotifcation: {
        title: "代理操作に関する通知",
        onRequestAndApprove: "代理人が申請・承認をした時",
        onApproved: "代理人として申請後、承認された時",
        onRejected: "代理人として申請後、差し戻された時",
        onCanceled: "代理人として申請後、承認が取り消された時",
        onCommented: "代理人として申請後、コメントがあった時",
      },
      otherNotification: {
        title: "領収書に関する通知",
        onCompleted: "領収書の自動入力完了・失敗時",
        onCreatedCorporateAggregation: "法人カード利用明細の連携時",
        notSubmitted: "締め日後に未提出経費がある時",
        onReceivedExpectedPaymentDate: "支払予定日の通知が届いた時",
        onReceivedExpectedTemporaryPaymentDate: "仮払予定日の通知が届いた時",
        onReceivedOriginalReceiptIdentificationRequest:
          "原本の確認通知が届いた時",
        help: {
          corporateAggregationDescription:
            "管理者にて登録されている法人カードが対象です",
        },
      },
    },
    editCompanions: {
      ourCompanions: "自社の参加者",
      clientCompanions: "取引先の参加者",
      ourCompanionEditModal: {
        title: "自社の参加者の情報を入力してください",
        nameLabel: "氏名",
      },
      clientCompanionEditModal: {
        title: "取引先の参加者の情報を入力してください",
        nameLabel: "氏名",
        companyLabel: "会社名",
      },
    },
    agentSettings: {
      addAgentModal: {
        button: "代理人を追加",
        title: "代理人を設定する",
        selectAgent: "代理人を選択",
        addAuthority: "権限を設定",
        unknownName: "名称不明",
      },
      agentTable: {
        agentName: "従業員名",
        expenseRequest: "経費申請",
        canMakeExpensesAndSubmit: "経費作成・申請",
        canApprove: "承認",
      },
      errorMessage: {
        user: {
          required: "ユーザを選択してください",
        },
        authority: {
          required: "権限を指定してください",
        },
      },
    },
  },
  preferences: {
    titles: {
      account: "アカウント設定",
      commuterPass: "定期区間設定",
      category: "経費科目設定",
      closingDate: "締め日設定",
      businessCategory: "自動入力時の業種・経費科目対応設定",
      sso: "シングルサインオン設定",
      expenseAccount: "経費精算払込口座",
      companyExpenseAccount: "支払口座",
      teiki: "定期情報設定",
      commentFormat: "経費入力メモ欄テンプレート",
      detailFormat: "事前申請詳細欄テンプレート",
      accountTitle: "勘定科目",
      alertRuleList: "アラートルール一覧",
      expenseAlertRuleList: "経費アラートルール一覧",
      expenseAlert: "経費アラート",
      autoCategorizeRule: "自動経費科目設定",
      editTaxCategory: "税区分入力",
      noRefund: "精算対象外",
      emptyPreTransaction: "事前申請項目",
      corporateCardsAccountList: "法人カード保持者一覧",
      password: "パスワードの変更",
      suppliers: "取引先一覧",
      securitySettings: "IPアドレス制限",
      currencies: "外貨設定",
    },
    users: {
      inputs: {
        name: "アカウント名",
        email: "メールアドレス",
        employeeId: "社員番号",
        locale: "言語設定",
        newPassword1: "新しいパスワード",
        newPassword2: "新しいパスワード（確認）",
        oldPassword: "現在のパスワード",
      },
      locale: {
        ja: "日本語",
        en: "English",
      },
    },
    reports: {
      titles: {
        reportNameFormat: "申請書名フォーマット",
        preReportNameFormat: "事前申請書名フォーマット",
        reportRemarksFormat: "申請書備考欄フォーマット",
        exportedRequestFileDisplayGroupName: "精算申請書 宛名",
      },
      descriptions: {
        commentFormat: "経費入力時に、予め入力されるコメントを設定できます。",
        detailFormat: "事前申請入力時に、予め入力される詳細欄を設定できます。",
        editTaxCategory:
          "経費入力時に経費科目に関わらず、税区分を入力できます。",
        noRefund:
          "経費入力時に、精算対象外の表示・非表示を切り替えることができます。",
        reportNameFormat:
          "申請書作成時に予め入力される申請書名を設定できます。",
        preReportNameFormat:
          "事前申請書作成時に予め入力される事前申請書名を設定できます。",
        specialSymbols: "名前には以下の特殊フォーマットが使用できます。",
        reportRemarksFormat1:
          "申請書のxlsファイルを出力する際に、備考欄に表示される項目を設定できます。",
        reportRemarksFormat2: "名前には以下の特殊フォーマットが使用できます。",
        exportedRequestFileDisplayGroupName:
          "経費申請の精算申請書を出力する際に、宛名として表示する企業名を設定できます。",
      },
      examples: {
        example: "設定例：",
        reportNameFormat: {
          placeholder: "{year}年{month}月分_経費",
          input: "{year}年{month}月分_経費",
          outputParser: "年",
          output: "月分_経費",
        },
        preReportNameFormat: {
          placeholder:
            "{submissionyear}年{submissionmonth}月分_事前申請_{username}",
          input: "{username}_事前申請",
          output: "山田太郎_事前申請",
        },
        reportRemarksFormat: {
          placeholder: "{comment}  【プロジェクト名】{projectname}",
          input: "{comment}  【プロジェクト名】{projectname}",
          output: "出張時の経費です。 【プロジェクト名】 Aプロジェクト",
        },
        exportedRequestFileDisplayGroupName: {
          placeholder: "株式会社TOKIUM 御中",
        },
      },
    },
    requests: {
      approval: {
        approvalSetting: "承認設定 (経費申請、事前申請、汎用申請)",
        bulkApprove: "一括承認を使用できるようにする",
        bulkApproveDesc:
          "承認者は複数の経費申請、事前申請、汎用申請をまとめて承認できるようになります",
        bulkApproveChanged: "一括承認設定を更新しました",
      },
    },
    teiki: {
      route: "経路",
      startAt: "利用開始日",
      expireAt: "利用終了日",
      notFound: "定期が見つかりません。",
      titles: {
        add: "定期区間追加",
        edit: "定期区間更新",
      },
      search: "この条件で検索",
      terms: {
        oneMonth: "1ヶ月分",
        threeMonths: "3ヶ月分",
        sixMonths: "6ヶ月分",
      },
      fee: {
        oneMonth: "1ヶ月料金",
        threeMonths: "3ヶ月料金",
        sixMonths: "6ヶ月料金",
      },
      information:
        "定期区間設定がロックされています。編集する場合は、管理者にロック解除を依頼してください。",
    },
    expenseAccount: {
      bank: "金融機関名",
      bankBranch: "支店名",
      type: "口座種類",
      ordinary: "普通",
      current: "当座",
      saving: "貯蓄",
      number: "口座番号",
      holderKana: "名義人",
      customerCode: "受託者コード",
      account: "口座",
      transferFee: "振込手数料",
      eqOrHeigher: "円以上",
      lower: "円未満",
      sameBankBranch: "同一店",
      sameBank: "他店",
      otherBank: "他行",
      to: "振込先",
      amount: "振込金額",
      yen: "円",
      transferFeeHelp:
        "振込手数料は請求書集計の全銀フォーマット出力時に適用されます",
      openTransferFeeHelp: "全銀データへの反映内容について",
      transferFeeHelpText:
        "全銀データ出力時に設定する手数料負担先(※1)と、請求書に設定されている手数料負担先(※2)を参照し、振込金額が自動計算されます。",
      holderKanaHelp:
        "ひらがな・カタカナ・英数字・記号【()-/.,】が入力できます。\n入力内容は、保存時に半角大文字に自動変換されます。\n濁音・半濁音は、半角変換後に2文字としてカウントされます。",
      titles: {
        add: "口座追加",
        edit: "支払口座更新",
      },
      information:
        "経費精算払込口座設定がロックされています。追加・編集が必要な場合は、管理者に連絡してください。",
    },
    sso: {
      provider: "IDプロバイダ",
      email: "メールアドレス",
      actions: {
        add: "アカウント登録",
        destroy: "紐付けを解除",
      },
    },
    ssoAvailable: {
      title: "シングルサインオン",
      description:
        "従業員のシングルサインオン設定の利用可否を設定します。<br><br><b>ご注意:</b> ONからOFFに変更した場合、各従業員のシングルサインオン設定が削除され、<u>シングルサインオンでログインしている従業員はすべての端末からログアウトされます。</u>",
      confirmationModal: {
        buttonText: "無効にする",
        content:
          "各ユーザのシングルサインオン設定が削除され、現在シングルサインオンでログインしているユーザは、すべての端末から強制的にログアウトされます。",
      },
    },
    closingDates: {
      tabTitles: {
        expense: "TOKIUM経費精算",
        invoice: "TOKIUMインボイス",
      },
      titles: {
        setting: "経費精算締め日設定",
        invoiceSetting: "支払依頼締め日設定",
        add: "締め日追加",
        edit: "締め日編集",
      },
      text: "締め日",
      add: "締め日を追加する",
      transactedAtFrom: "利用日(from)",
      transactedAtTo: "利用日(to)",
      accountingDateFrom: "計上日(from)",
      accountingDateTo: "計上日(to)",
      requestableDateFrom: "申請可能期間(from)",
      requestableDateTo: "申請可能期間(to)",
      notificationDate: "通知日",
      descriptions: {
        closingDateSetting:
          "各経費の利用日に対して申請可能な期間を設定できます。",
        invoiceClosingDateSetting:
          "各支払依頼の計上日に対して申請可能な期間を設定できます。",
        toEnableSetting:
          "ただし経費アラート設定の申請可能期間チェックが有効である場合のみ機能します。",
        toEnableSettingInInvoice:
          "ただし請求書アラート設定の申請可能期間チェックが有効である場合のみ機能します。",
      },
      term: "日(毎月)",
      after: "日後",
      noticeAt: "通知日",
      rowDate: "毎月{{date}}日",
      rowNoticeAt: "締め日から{{date}}日後",
      endOfMonth: "月末",
    },
    businessCategories: {
      header: "業種・経費科目対応設定",
      modalHeader: "業種と経費科目の対応を設定",
      businessCategoryName: "業種名",
      categoryName: "経費科目名",
      correspondingCategory: "対応する経費科目",
      priority: "優先順位",
    },
    externalCategoryMaps: {
      messages: {
        changeSuperCategoryNameSuccess: "勘定科目を更新しました。",
      },
    },
    externalTaxCategoryMap: {
      messages: {
        changeSuperTaxCategoryNameSuccess: "税区分を更新しました。",
      },
    },
    rootCategories: {
      settings: "経費科目",
      add: "経費科目を追加",
      showDisabledCategories: {
        setToTrue: "利用しない経費科目も表示",
        doneSettingToTrue: "利用しない経費科目を表示しました",
        doneSettingToFalse: "利用しない経費科目を非表示にしました",
      },
      categoryName: "入力時に表示する経費科目設定名称",
      superCategory: "勘定科目",
      subCategory: "補助科目",
      requiresReceipt: "領収書添付",
      requiresCompanion: "参加者入力",
      requiresOriginAndDestination: "発着地入力",
      requiresVisit: "訪問先入力",
      requiresPurpose: "目的入力",
      requiresPreReport: "事前申請",
      requiresWithholding: "源泉徴収対応",
      requiresMemoInput: "メモ欄入力",
      eligibleInvoiceConfirmation: "適格請求書確認",
      notRequiresReceivingInvoice: "交付義務免除として扱う",
      requiresRegistratedNumber: "登録番号欄入力",
      requiresPaidAddress: "住所又は所在地入力",
      genericFieldDataSets: {
        name: "汎用マスタ",
        required: "必須入力",
        optional: "任意入力",
        single: "単一選択",
        multiple: "複数選択",
      },
      sort: "並び順",
      taxCategory: "税区分",
      usePreReportItem: "事前申請項目表示",
      memoTemplate: "メモ欄テンプレート",
      required: "必須",
      necessary: "必要",
      noNeed: "不要",
      enable: "利用する",
      enabling: "する",
      disabling: "しない",
      regardAs: "扱う",
      notRegardAs: "扱わない",
      edit: "経費科目編集",
      create: "経費科目登録",
      category: "経費科目",
      parentCategory: "親の経費科目",
      editingParent: "親科目として設定する",
      nothing: "なし",
      defaultTransitCategory: "交通費入力時の経費科目",
      displayPreference: {
        title: "ユーザごとの経費科目表示設定",
        text: "個人設定から経費科目を非表示としている従業員にも、経費科目「{{category}}」を表示させますか？",
        overwrite: "表示させる",
        maintain: "非表示のままにする",
      },
      errors: {
        messages: {
          canNotSelectDeletedTaxCategory: "削除された税区分が選択されています",
        },
      },
      placeholders: {
        notEntered: "未入力",
      },
      alert: "アラート",
      help: {
        canNotEditEnableWithoutEnabledChild:
          "「利用する」となっている子科目がないため、編集できません。",
        canNotEditUsePreReportItem:
          "事前申請項目の入力時に利用するかどうかを設定します。なお、この親科目には「利用する」かつ「事前申請項目で表示する」に設定した子科目が存在しないため編集できません。",
        canNotEditUsePreReportItemWhenDisable:
          "事前申請項目の入力時に利用するかどうかを設定します。なお、この経費科目は「利用しない」設定のため編集できません。",
        checkChangeMemoFieldInput:
          "経費作成時にメモ欄への入力（追記等）が必要かを設定します。",
        isEditingParentCanBeChangedOnlyOnce:
          "一度登録すると、子科目から親科目、親科目から子科目への変更はできません。",
        requiresWithholdingCanBoChangedOnlyOnce:
          "一度登録すると、「必須」と「不要」を変更できません。",
        usePreReportItem:
          "事前申請項目の入力時に利用するかどうかを設定します。",
      },
    },
    taxCategorySelections: {
      title: "税区分選択",
      name: "税区分名",
      taxRate: "税率",
      validPeriod: "有効期間",
      sort: "並び順",
      setting: "設定",
      errors: {
        messages: {
          noSelection: "税区分を1つ以上選択してください",
          invalidSort: "並び順に1以上の値を入力してください",
        },
      },
    },
    personalCategories: {
      displaySetting: "経費科目表示設定",
      accountTitle: "経費科目",
      valid: "有効",
      noData: "データがありません",
      messages: {
        enabled: "{{name}}を有効にしました",
        disabled: "{{name}}を無効にしました",
      },
    },
    format: {
      comment: "メモ欄テンプレート",
      detail: "詳細欄テンプレート",
      placeholder: {
        reportname: "申請書名",
        reportid: "申請書ID",
        prereportid: "事前申請書ID",
        username: "申請者名",
        userid: "社員ID",
        userdepartment: "所属部署",
        date: "申請日付",
        email: "申請者メールアドレス",
        category: "勘定科目",
        parentdisplaycategory: "親科目",
        displaycategory: "経費科目",
        subcategory: "補助科目",
        taxcategoryname: "税区分名",
        transactiondate: "利用日",
        shopname: "支払先",
        projectname: "プロジェクト名",
        projectid: "プロジェクトID",
        comment: "コメント",
        foreigncurrencycode: "外貨コード",
        exchangerate: "外貨レート",
        foreigncurrencyamount: "外貨金額",
        companionnum: "参加人数",
        companions: "参加者一覧",
        companiondetails: "参加者詳細",
        clientinc: "取引先社名",
        clientnum: "取引先人数",
        clients: "取引先参加者一覧",
        clientdetails: "取引先参加者詳細",
        employeenum: "自社参加者人数",
        employees: "自社参加者一覧",
        employeedetails: "自社参加者詳細",
        originbycategory: "出発地",
        destinationbycategory: "到着地",
        visitbycategory: "訪問先",
        purposebycategory: "目的",
        department: "費用負担部署",
        submissionyear: "提出年",
        submissionmonth: "提出月",
        submissionday: "提出日",
        year: "経費の利用年（最も過去の日付が反映）",
        month: "経費の利用月（最も過去の日付が反映）",
        day: "経費の利用日（最も過去の日付が反映）",
        reimbursable: "法人請求かどうか",
        bank: "例:三井住友",
        bankBranch: "例:銀座",
        number: "例:1234567",
        holderKana: "例: ヤマダタロウ",
        customerCode: "例:0111234567",
        nameOrEmail: "従業員名、またはメールアドレス",
        origin: "出発駅",
        destination: "到着駅",
      },
    },
    paymentDate: {
      settings: {
        title: "支払予定日の設定",
        useSetting: {
          title: "支払予定日の登録",
          description: "支払処理を行う際に予定日の登録を行うことができます。",
        },
      },
      labels: {
        dateToBePaid: "支払予定日",
        notificationMessage: "通知メッセージ",
      },
    },
    temporaryPaymentDate: {
      settings: {
        useSetting: {
          title: "仮払予定日の登録",
          preTitle: {
            useDkRequests: "経費精算",
            usePaymentRequest: "インボイス",
          },
        },
      },
      labels: {
        dateToBePaid: "仮払予定日",
        notificationMessage: "通知メッセージ",
      },
    },
    accountTitle: {
      add: "勘定科目を追加",
      edit: "勘定科目を編集",
      destroy: "選択した勘定科目を削除",
      list: "勘定科目一覧",
      properties: {
        name: "勘定科目名",
        yomi: "よみがな",
        taxCategory: "税区分",
        code: "勘定科目コード",
        defaultCreditCategory: "既定の貸方勘定科目(経費用)",
      },
      messages: {
        deleteMultiple: "{{count}}件の勘定科目を削除しました",
      },
      errors: {
        taxCategoryNotSelected: "税区分が選択されていません",
      },
    },
    taxCategories: {
      taxCategoriesSetting: "税区分設定",
      column: {
        taxCategoryName: "税区分名",
        taxRate: "税率",
        startDate: "開始日",
        endDate: "終了日",
        taxRatePercentage: "税率(%)",
        eligible: "適格",
        eligibleName: "適格の税区分",
      },
      button: {
        add: "税区分を追加",
        restore: "税区分を復元",
      },
      operation: {
        add: "税区分登録",
        edit: "税区分編集",
      },
      messages: {
        deletedCategoryIsnotExist: "現在、削除されている税区分は存在しません。",
        taxCategoriesHaveBeenDeleted:
          "表示されている税区分は現在削除されています。",
        dataUnchanged:
          "※上記の税区分に関連した過去の経費などのデータは変更されません。",
        getFailed: "税区分の取得に失敗しました。",
        notChangeEligibleTaxCategory:
          "※ 経費や請求明細で使用されている場合「適格の税区分」は変更できません",
        notChangeTaxRate:
          "※ 経費や請求明細で「非適格の税区分」が使用されている場合「税率」は変更できません",
      },
      form: {
        label: {
          eligibleOrUneligible: "適格/非適格",
          eligibleTaxCategory: "適格の税区分",
          eligible: "適格",
          uneligible: "非適格",
        },
      },
    },
    alertRules: {
      levels: {
        ignore: "オフ",
        warning: "警告表示",
        error: "申請不可",
      },
      name: "ルール名",
      description: "チェック内容",
      level: "チェックレベル",
      setting: "設定",
      link: {
        linkToGenericFieldsText: "汎用マスタ設定へ移動",
      },
      requestablePeriod: {
        period: "申請可能期間",
        nextCutoffDay: "次の締め日",
        prevCutoffDay: "前の締め日",
        messages: {
          update: "申請可能期間を更新しました",
        },
      },
      autoInputEditRule: {
        autoInput: "自動入力",
        externalLink: "外部連携",
        transactedAt: "利用日",
        shopName: "支払先/訪問先",
        amount: "金額",
        route: "経路",
        externalLinkDescription: "ICカード、クレジットカード等",
        isUnenterdCheckedDescription: "未入力だった項目の変更は対象としない",
      },
      ruleNames: {
        stampedWithinDueDateRule: "タイムスタンプ有効性チェック",
      },
    },
    editTaxCategory: {
      header: "税区分入力",
    },
    editNoRefund: {
      header: "精算対象外",
    },
    showDistance: {
      title: "距離表示",
      attention:
        "交通費入力時に、距離を表示するかどうかを切り替えることができます。",
      description: "距離表示",
    },
    emptyPreTransaction: {
      title: "事前申請作成、編集時に事前申請項目なしで申請できるようにします。",
      header: "事前申請項目なしで申請",
    },
    icCardUseOption: {
      title: "IC乗車券オプションの初期状態",
      description:
        "交通費入力時のIC乗車券オプションをあらかじめ選択した状態にする",
    },
    icCardDeductionOption: {
      title: "定期区間控除",
      description: "IC乗車券の金額から定期料金を自動控除する",
    },
    requestTypes: {
      title: "申請フォーム一覧",
      name: "申請名",
    },
    password: {
      password: "新しいパスワード",
      passwordConfirmation: "新しいパスワード(確認)",
      currentPassword: "現在のパスワード",
      description:
        "・パスワードは8 文字以上 256 文字以下で設定してください\n・英字(小文字)、英字（大文字）、数字、記号のうち3種類以上を含める必要があります",
    },
    messages: {
      updatedAccount: "アカウント設定を更新しました",
      AddedCutoffDay: "締め日設定を追加しました。",
      updatedPassword: "パスワードを変更しました",
    },
    errors: {
      failedToUpdateAccount: "アカウント設定の更新に失敗しました",
    },
    autoCategorizeRule: {
      target: "対象",
      regex: "条件",
      category: "経費科目名",
      state: "状態",
      delete: "削除",
      titles: {
        add: "科目設定追加",
      },
    },
    listShow: {
      titles: {
        listShowOption: "一覧表示",
      },
      descriptions: {
        approvalFlowName: "申請フロー名を申請一覧・承認一覧に表示する",
        projectName: "プロジェクト名を申請一覧・承認一覧に表示する",
        withholding: "源泉徴収税額を申請一覧・承認一覧に表示する",
      },
    },
    suppliers: {
      titles: {
        blockConfirmation: "凍結確認",
        unblockConfirmation: "凍結解除確認",
      },
      imports: {
        title: "取引先インポート",
        importSuppliers: "取引先の情報をインポートします。",
        sample: "サンプル",
        guide:
          "サンプルデータのP列に補足事項の記載があります。インポート時にはP列を削除してください。",
      },
      messages: {
        blockConfirmation: "凍結しますか？",
        unblockConfirmation: "凍結解除しますか？",
      },
      searchConditions: {
        blocked: "凍結済みの取引先を表示する",
      },
      actions: {
        new: "新規取引先追加",
        update: "取引先更新",
        block: "凍結",
        unblock: "凍結解除",
      },
      properties: {
        code: "取引先コード",
        name: "取引先名",
        willPayAt: "支払期日",
        specify: "指定する",
        notSpecify: "指定しない",
        willPayOfMonth: "支払期日(月)",
        willPayOfDay: "支払期日(日)",
        willPayForwardSetting: "休日設定(カレンダーに準拠)",
        willPayForward: "休日設定",
        forward: "前倒し",
        back: "後倒し",
        willPayFee: "振込手数料負担先",
        wePayFee: "当方",
        theOtherPayFee: "先方",
        editableOnRequest: "編集設定",
        editable: "編集可",
        notEditable: "編集不可",
      },
      descriptions: {
        code: "インポートによる更新を行う場合は取引先コードが必須となります",
        editableOnRequest:
          "申請時の編集を許可するかどうかを設定します。振込手数料負担先と支払期日に適用されます。",
      },
      errors: {
        name: {
          required: "入力してください",
        },
        willPayOfMonth: {
          required: "選択してください",
        },
        willPayOfDay: {
          required: "選択してください",
        },
        willPayForward: {
          required: "選択してください",
        },
        willPayFee: {
          required: "選択してください",
        },
        bank: {
          required: "候補から選択してください",
        },
        bankBranch: {
          required: "候補から選択してください",
        },
        number: {
          required: "7桁の数字で指定してください",
        },
        holderKana: {
          required: "入力してください",
        },
      },
    },
    currencies: {
      refCurrency: "基準通貨",
      common: {
        period: "適用期間",
        currency: "通貨",
        edit: "編集",
        delete: "削除",
        cancel: "キャンセル",
      },
      tables: {
        title: "為替レート一覧",
        rate: "レートの設定",
        configChanged: "設定変更",
      },
      buttons: {
        changeCurrencyButton: "変更",
        addCurrencyButton: "通貨を追加",
        importCsvButton: "インポート",
        addRateButton: "レート設定を追加",
        updateButton: "更新",
        addButton: "追加",
        historyButton: "過去のレートを見る",
      },
      modal: {
        changeCurrencyTitle: "基準通貨を変更",
        importTitle: "為替レートインポート",
        confirmTitle: "設定を削除",
        rateFormTitle: "為替レート設定",
        formatInfo:
          "フォーマットは外貨コード,基準通貨コード,為替レート,端数の取り扱い,適用開始日,適用終了日です",
        autoUpdate:
          "自動取得を指定する場合は、為替レート列を空欄もしくは「自動取得」にてインポートください",
        sampleLink: "csvサンプルはこちら",
        warnNotUndo: "この操作は取り消せません",
        deleteConfirm: "設定を削除してもよろしいですか？",
        changeBaseInfo:
          "基準通貨を変更すると、為替レートの設定も削除されます。",
        changeBaseConfirm: "よろしいですか？",
        autoRefresh: "自動取得",
        rate: "為替レート",
        fraction: "端数の取り扱い",
        roundUp: "切り上げ",
        roundOff: "四捨五入",
        roundDown: "切り捨て",
        pastRateNotFound: "表示可能なレートが存在しません",
        startDate: "適用開始日",
        expiryDate: "有効期限",
      },
      rootCategories: {
        showPastExchangeRates: "期限切れの為替レートも表示",
      },
    },
    analysesOutputStatus: {
      title: "会計データ出力の状態",
      preTitle: {
        useDkRequests: "経費精算",
        usePaymentRequest: "インボイス",
      },
      attention: "会計データ出力の出力完了/未完了について",
      description:
        "会計データのファイル出力時に、自動的に会計データ出力完了状態とする",
    },
    journalEntriesExportSetting: {
      title: "会計データ出力設定",
      labels: {
        availableExportFormatToEveryone:
          "仕訳/カスタマイズフォーマットを集計者以外も使用可能にする",
      },
    },
    requiredCompanionRule: {
      description:
        "経費作成者のみが参加者に指定されている場合、アラートを表示する",
    },
    currency: {
      actions: {
        addRateConfig: "レート設定を追加",
        addCurrency: "通貨を追加",
        importFromCSV: "csv形式でインポート",
      },
      importForm: {
        title: "外貨レート",
      },
      confirmationModal: {
        title: "設定を削除",
      },
      table: {
        title: "為替レート一覧",
        header: {
          currency: "通貨",
          exchangeRate: "設定レート",
          applicablePeriod: "適用期間",
          actions: "設定変更",
        },
      },
      description: {
        autoUpdate:
          "自動取得を指定する場合は、変換レート列を空欄もしくは「自動取得」にてインポートください",
        format:
          "フォーマットは通貨コード,基準通貨コード,変換レート,端数の取り扱い,適用開始日,適用終了日です",
        fromSample: "csvサンプルはこちら",
        cannotCancel: "この操作は取り消せません",
        askDelete: "設定を削除してもよろしいですか？",
      },
    },
    securitySettings: {
      ipRestrictionsOptionCard: {
        alert:
          "IPアクセス制限の機能は、有料オプションとなります。ご利用の際には、サポートまでご連絡ください。",
        warning:
          "アクセス制限を有効にする前に許可IPアドレスを登録してください。",
        description: "指定されたIPアドレスからのみアクセスを許可します",
        note1:
          "許可IPアドレス一覧で指定されたIPアドレスからのみアクセスできます。",
        note2: "IPアドレスは最大1,000件まで登録できます。",
        changeEventsLink: "変更履歴",
      },
      ipRestictionChangeConfirmationModal: {
        title: "IPアクセス制限変更",
        enable: "有効にする",
        disable: "無効にする",
        toEnableAlert:
          "IPアクセス制限を有効にすると、許可IPアドレス以外からのアクセスが遮断されます。変更しますか？",
        toDisableAlert:
          "IPアクセス制限を無効にすると、すべてのIPアドレスからアクセスできます。変更しますか？",
      },
      allowIpCard: {
        title: "許可IPアドレス一覧",
        search: {
          title: "検索条件を指定",
          reset: "検索条件をリセット",
          submit: "この条件で検索",
        },
        addModal: "IPアドレス追加",
        updateModal: "IPアドレス更新",
        ipAddress: "IPアドレス",
        comment: "コメント",
        cidr: "CIDR",
        add: "IPアドレスを追加する",
        import: "インポート",
        export: "エクスポート",
        edit: "編集",
        date: "登録日時",
        delete: "削除",
        deleteAll: "IPアドレスを削除する",
        importModal: {
          title: "許可IPアドレスインポート",
          description1: "許可IPアドレスをインポート（追加・更新）します。",
          description2: "テンプレートはこちら",
          description3: "IPアドレス、コメントの列を必ず指定してください。",
          description4: "追加する場合、ID列を空にします。",
          description5:
            "更新する場合、エクスポートしたファイルをご利用ください。編集時にはID列は変更しないでください。",
          moveToExportModal: "現在の設定内容をエクスポート",
          alert1: "IPアクセス制限が有効です。",
          alert2:
            "インポートされた許可IPアドレスは、すぐにIPアクセス制限の対象となります。",
        },
      },
      changeEventsCard: {
        title: "変更履歴",
        search: {
          title: "検索条件を指定",
          startDate: "変更日（開始）",
          endDate: "変更日（終了）",
          employeeEmail: "従業員またはメールアドレス",
          ipAddress: "IPアドレス",
          comment: "コメント",
          reset: "検索条件をリセット",
          submit: "この条件で検索",
        },
        added: "追加",
        updated: "更新",
        deleted: "削除",
        enabled: "有効化",
        enabledIpRestriction: "すべて許可 → 一部許可",
        disabled: "無効化",
        disabledIpRestriction: "一部許可 → すべて許可",
        imported: "インポート",
        changeType: "変更タイプ",
        changeDate: "変更日時",
        editor: "変更者",
        details: "変更内容",
      },
      allowIpTableExportModal: {
        title: "許可IPアドレスエクスポート",
        button: "CSV形式でエクスポート",
        moveToImportModal: "インポート画面へ移動",
      },
    },
    security: {
      subMenu: {
        ipRestriction: "IPアクセス制限",
        singleSignOn: "シングルサインオン",
        subdomain: "サブドメイン",
        samlSetting: "SAML設定",
        samlAccount: "SAML連携アカウント",
      },
      subdomain: {
        title: "サブドメイン",
        create: "作成する",
        description:
          "サブドメインを登録することで、ログイン方法を制限したり、SAML認証機能を利用できるようになります。",
        warning:
          "サブドメインを保存するとログインURLが変更され、管理者を含むすべての従業員が再ログインする必要があります。事前に新しいログインURLを従業員に連絡してください。",
        checkAvailable: "使用可能か確認する",
        overview: "概要",
        loginUrl: "ログインURL",
        edit: "編集する",
        stopUseSubdomain: "サブドメインの利用を停止する",
        updateModal: {
          title: "サブドメイン編集",
          warning:
            "サブドメインを更新すると、管理者を含むすべての従業員が再ログインする必要があります。事前に新しいログインURLを従業員に連絡してください。",
        },
        updateConfirmModal: {
          title: "警告",
          content:
            "サブドメインを更新すると、従業員が接続できなくなります。変更しますか？",
        },
        stopUsingModal: {
          title: "サブドメイン利用停止",
          content:
            "サブドメインを利用停止にした場合、管理者を含むすべての従業員が再ログインする必要があります。利用停止する場合、入力欄にサブドメインを入力し、利用停止ボタンを押してください。",
          stopUsing: "利用停止",
        },
        stopUsingConfirmModal: {
          title: "警告",
          content:
            "サブドメインを利用停止にした場合、管理者を含むすべての従業員が再ログインする必要があります。本当に削除しますか？",
        },
        options: {
          title: "SAML認証オプション",
          skipIPAccessRestriction: "SAML認証時にIPアクセス制限をスキップする",
          enableModal: {
            title: "IPアクセス制限をスキップする",
            buttonText: "有効化",
            content:
              "IPアクセス制限をスキップする機能を有効にします。変更しますか?",
          },
          disableModal: {
            title: "IPアクセス制限をスキップする",
            buttonText: "無効化",
            content:
              "IPアクセス制限をスキップする機能を無効にします。変更しますか",
          },
          warnings: {
            message: "IdP情報を登録してください。",
            noSamlLicence: "SAML認証ライセンスがありません。",
            noIpRestrictionLicense: "IPアクセス制限ライセンスがありません。",
            ipRestrictionDisabled: "IPアクセス制限機能が無効です",
          },
        },
      },
      allowedAuthenticationMethods: {
        title: "許可ログイン方法",
        emailPassword: "Eメールとパスワード",
        google: "Google",
        office365: "Microsoft 365",
        apple: "Sign in with Apple (iOSのみ)",
        edit: "編集する",
        updateModal: {
          title: "許可ログイン方法",
        },
      },
      samlSetting: {
        title: "SAML設定",
        create: "作成する",
        noLicense:
          "SAMLの機能は、有料オプションとなります。ご利用の際には、サポートまでご連絡ください",
        noSubdomain: "サブドメインを設定してください。",
        samlSettingNameHelpText:
          "設定名はログイン画面のボタンとして表示されます。短く適切な設定名としてください。",
        table: {
          name: "設定名",
          description: "説明",
          status: "ステータス",
          active: "有効",
          inactive: "無効",
        },
        newModal: {
          title: "SAML設定作成",
          name: "設定名",
          description: "説明",
        },
        updateModal: {
          title: "SAML設定概要",
          name: "設定名",
          description: "説明",
        },
      },
      samlSettingDetail: {
        title: "SAML設定",
        setupDescription: {
          head: "設定手順",
          line1: "ご利用のIdPにサービスプロバイダ情報を登録します。",
          line2:
            "IdPからメタデータをダウンロードまたはメタデータのURLをコピーします。",
          line3: "メタデータをアップロードまたはメタデータのURLを登録します。",
          line4: "有効化します。",
          line5:
            "サブドメイン画面の許可ログイン方法でこのSAML設定を選択します。",
          end1: "ご利用のIdPがAzure ADの場合、以下クレームのソース属性を変更してください。",
          end2: "クレーム名: http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
          end3: "ソース属性: user.userprincipalname",
        },
        summary: {
          title: "概要",
          name: "設定名",
          description: "説明",
          edit: "編集する",
        },
        serviceProvider: {
          title: "サービスプロバイダ情報",
          entityId: "Entity ID",
          ascUrl: "ACS URL",
          logoutUrl: "ログアウトURL",
        },
        identityProvider: {
          title: "SAML設定",
          metadataUpload: "メタデータのアップロード",
          metadataUrl: "メタデータのURL",
          edit: "編集する",
          cannotChange:
            "メタデータを変更するには、SAML設定を無効にしてください。",
        },
        connectionCheck: {
          title: "SAML設定の確認",
          action: "設定を確認する",
        },
        activation: {
          title: "SAML認証有効化",
          action: "SAML認証を有効にする",
          enableModal: {
            title: "SAML設定",
            buttonText: "SAML設定を有効にする",
            content: "本当にSAML設定を有効にしますか？",
          },
          disableModal: {
            title: "SAML設定",
            buttonText: "SAML設定を無効にする",
            content: "本当にSAML設定を無効にしますか？",
          },
          warnings: {
            mustSetMetadata: "メタデータを登録してください",
          },
        },
        stopUsingModal: {
          title: "このSAML設定削除する",
          content:
            "以下のテキストフィールドにこの設定名を入力して、削除ボタンをクリックしてください。",
          stopUsing: "削除",
        },
        stopUsingConfirmModal: {
          title: "警告",
          content:
            "SAML設定を利用停止にした場合、従業員がアクセスできなくなる可能性があります。本当に削除しますか？",
        },
        updateIdPModal: {
          title: "SAML設定",
          metadataUpload: "メタデータのアップロード",
          or: "または",
          metadataURL: "メタデータのURL",
        },
        deleteIdpConfirmModal: {
          title: "警告",
          content: "本当に削除しますか？",
        },
      },
      samlAccount: {
        title: "SAML連携アカウント",
        info: {
          line1:
            "TOKIUMのアカウントとIdP側のアカウントが異なる場合にマッピングできます。",
          line2:
            "TOKIUMのアカウントとIdP側のアカウントが同じ場合、設定する必要はありません。",
        },
        setSearchCondition: "検索条件を設定",
        import: "インポート",
        export: "エクスポート",
        employee: "従業員",
        linkedAccount: "連携アカウント",
        filter: {
          all: "すべての従業員への操作",
          linkAll: "すべての従業員を一括で連携する",
          unlinkAll: "すべての従業員を一括で連携解除する",
          batch: "選択した従業員への操作",
          batchLink: "選択した従業員を一括で連携する",
          batchUnlink: "選択した従業員を一括で連携解除する",
        },
        searchModal: {
          title: "SAML連携アカウント",
          setSearchCondition: "検索条件を設定",
          employeeCode: "社員コード",
          nameOrEmail: "氏名またはメールアドレス",
          department: "部署",
          blocked: "凍結済み",
          notSet: "未設定",
          alreadyLinked: "連携済み",
          all: "すべて",
          resetSearchCondition: "検索条件をリセット",
          search: "この条件で検索",
        },
        linkAllModal: {
          title: "SAML連携アカウント / 一括連携",
          description:
            "選択したSAML設定にTOKIUMのアカウントと同じアカウントが設定されます。",
          linkAll: "一括連携",
        },
        releaseAllModal: {
          title: "SAML連携アカウント / 一括解除",
          description: "選択したSAML設定の連携が解除されます。",
          releaseAll: "一括解除",
        },
        singleModal: {
          title: "SAML連携アカウント",
          link: "連携",
          useReceiptPostEmail: "TOKIUMアカウントを適用する",
        },
        destroyLinkModal: {
          title: "SAML連携アカウント",
          unlink: "解除",
        },
        importModal: {
          title: "SAML連携アカウントインポート",
          description1:
            "SAML連携アカウントをインポート（追加・更新・削除）します。",
          description2: "テンプレートはこちら",
          description3: "メールアドレスの列を必ず指定してください。",
          description4: "SAML設定名の列は必ず１列以上を指定してください。",
          description5: "氏名列は空でも構いません。",
          description6:
            "削除の場合は連携情報（SAMLメール又はユーザ名）を空白にしてください。",
          description7:
            "更新する場合、ダウンロードしたファイルをご利用ください。",
          gotoExport: "エクスポートはこちら",
        },
        exportModal: {
          title: "SAML連携アカウントエクスポート",
          button: "CSV形式でエクスポート",
          description: "SAML連携アカウントでエクスポートできます。",
          gotoImport: "インポートはこちら",
        },
      },
    },
    nationalTaxDocumentSettings: {
      typeAliases: {
        title: "書類種別名称",
        actions: {
          addAlias: "書類種別を追加する",
        },
        properties: {
          enabled: {
            yes: "する",
            no: "しない",
          },
        },
        tableLabels: {
          name: "書類種別名",
          typeName: "書類カテゴリ",
          order: "並び順",
          enabled: "利用する",
        },
        tableLabelHelpers: {
          name: {
            message:
              "「国税関係書類」の一覧に表示される名称です。\n自由な名称で設定できます。\n例）「契約書」を「発行契約書」に変更",
          },
          typeName: {
            message:
              "書類種別名に該当する分類を選択してください。\nどのカテゴリにもあてはまらない場合は「その他」を選択してください。\n例）書類種別名が「発行請求書」の場合、書類カテゴリは「請求書」を選択",
          },
        },
        formLabels: {
          includeDisabled: "利用しない書類も表示",
          name: "書類種別名",
          type: "書類カテゴリ",
          order: "並び順",
          enabled: "利用する",
        },
        modal: {
          createAndEdit: {
            titleForCreate: "国税関係書類種別追加",
            titleForEdit: "国税関係書類種別編集",
          },
        },
      },
      autoCreation: {
        title: "自動入力設定",
        labels: {
          preserveSupplierPaymentMethod: "取引先設定の内容でデータ化",
        },
        descriptions: {
          preserveSupplierPaymentMethod:
            "自動入力時、書類に記載された内容ではなく、取引先設定にある内容でデータ化されます",
        },
        workerInputPreserve: {
          title: "詳細設定",
          supplierSettings: "取引先設定",
          workerInput: "自動入力内容を優先",
          items: {
            supplierName: "取引先名",
            paymentInformation: "支払情報",
            bankAccountHolder: "名義人",
            payFee: "手数料負担先",
          },
          descriptions: {
            paymentInformation:
              "支払先を特定する情報（金融機関名、支店名、口座種類、口座番号）です",
          },
          message: {
            updated: "取引先優先設定を更新しました",
          },
          error: {
            failedToUpdated: "取引先優先設定が更新できませんでした",
          },
        },
      },
    },
    login: {
      subdomain: "サブドメイン",
      submit: "送信",
      back: "ログイン画面に戻る",
      invalidSubdomain: "無効なサブドメインです",
    },
    searchSetting: {
      titles: {
        canDeletedExpenseSearch: "経費検索",
        canDeletedInvoiceSearch: "請求書検索",
        canDeletedNationalTaxDocumentSearch: "国税関係書類検索",
      },
      descriptions: {
        canDeletedExpenseSearch: "削除された経費を検索可能にする",
        canDeletedInvoiceSearch: "削除された請求書を検索可能にする",
        canDeletedNationalTaxDocumentSearch:
          "削除された国税関係書類を検索可能にする",
        searchTargetDeletedExpenses:
          "右記の日時以降に作成した経費を削除済み検索の対象とする",
        searchTargetDeletedInvoices:
          "右記の日時以降に作成した請求書を削除済み検索の対象とする",
        searchTargetDeletedNationalTaxDocuments:
          "右記の日時以降に作成した国税関係書類を削除済み検索の対象とする",
      },
    },
    timestampVerification: {
      title: "タイムスタンプ検証設定",
      enable: "タイムスタンプ検証機能",
      error: "タイムスタンプ検証機能の利用変更に失敗しました",
    },
    resolutionInformation: {
      title: "解像度検証設定",
      description:
        "アップロードした画像が、電子帳簿保存法のスキャナ保存要件を満たしているかチェックされます。",
      enable: "経費",
    },
  },
  employeeLabels: {
    titles: {
      labels: "従業員ラベル一覧",
      createLabel: "従業員ラベルを追加",
      editLabel: "従業員ラベルを編集",
    },
    properties: {
      name: "ラベル名",
      color: "色",
    },
    headers: {
      number: "人数",
      label: "ラベル",
    },
  },
  members: {
    titles: {
      update: "従業員更新",
      new: "従業員追加",
      clearApprovalFlow: "申請フローのクリア",
      selectApprovalFlow: "承認フローを選択",
      selectLabel: "ラベル選択",
      list: "従業員一覧",
      import: "従業員インポート",
      export: "従業員エクスポート",
      settingsLock: "従業員情報の編集ロック設定",
    },
    properties: {
      mail: "メールアドレス",
      employeeId: "社員番号（任意）",
      name: "氏名",
      addDepartment: "所属部署を追加する",
      primaryDepartment: "主所属部署",
      department: "所属部署",
      noDepartment: "所属なし",
      post: "役職",
      noPost: "役職なし",
      approver: "承認者",
      removeDepartment: "除外する",
      aggregator: "集計者",
      admin: "管理者",
      blocked: "凍結済み",
      reference: "参照する",
      unlock: "ロック解除",
      addEmployee: "従業員を追加する",
      importFromXlsx: "インポート",
      exportAsXlsx: "エクスポート",
      account: "口座",
    },
    authorities: {
      help: {
        admin: "システム設定メニューの閲覧・編集ができます",
        accountant: "集計者専用画面の閲覧・編集ができます",
      },
    },
    buttons: {
      update: "更新",
      add: "追加",
      showAllDepartments: "兼務情報を全て表示する",
      clearApprovalFlow: "申請フローを取り除く",
    },
    departmentPosts: {
      approver: "承",
    },
    statuses: {
      expenseAccount: "口座",
      commuterPassRoute: "定期区間",
      agent: "代理人",
      notConfigure: "未設定",
      configured: "設定済",
      locked: "ロック中",
      blocked: "{{- date}} 凍結済",
    },
    headers: {
      employee: "従業員",
      departmentPost: "部署・役職",
      department: "部署",
      permission: "権限",
      status: "ステータス",
      statusHelp:
        "各状態やアイコンをクリックすると設定や編集可・不可を操作できます。",
    },
    searchbox: {
      openForm: "検索条件を設定",
      search: "この条件で検索",
      resetCondition: "検索条件をリセット",
      onlyMainDepartment: "主所属のみ",
      authority: "権限",
      searchCondition: "検索条件",
      displayCondition: "表示条件",
      showFrozen: "凍結済みアカウント",
      nameOrEmail: "氏名 または メールアドレス",
      department: "部署",
      post: "役職",
      employeeId: "社員番号",
      label: "ラベル",
    },
    settingMenu: {
      editExpenseAccount: "口座を設定する",
      lockExpenseAccount: "口座を編集不可にする",
      unlockExpenseAccount: "口座を編集可にする",
      editCommuterPassRoute: "定期区間を設定する",
      lockCommuterPassRoute: "定期区間を編集不可にする",
      unlockCommuterPassRoute: "定期区間を編集可にする",
      editCategory: "経費科目を設定する",
      editAgent: "代理人を設定する",
      unlock: "ロックを解除する",
      block: "凍結する",
      unblock: "凍結を解除する",
    },
    messages: {
      memberUpdate: "更新しました。",
      memberAdd: "招待メールを送信しました。",
      confirmUserBlockTitle: "本当に凍結しますか？",
      confirmUserBlockMessage: "従業員を凍結します．よろしいですか？",
      confirmUserBlock: "凍結",
      confirmUserUnblockTitle: "本当に凍結を解除しますか？",
      confirmUserUnblockMessage: "従業員の凍結を解除します．よろしいですか？",
      confirmUserUnblock: "凍結解除",
      confirmUserUnlockTitle: "本当にロックを解除しますか？",
      confirmUserUnlockMessage: "従業員のロックを解除します．よろしいですか？",
      confirmUserUnlock: "ロック解除",
      confirmClearApprovalFlow:
        "申請フローが割り当てられていない状態にします、よろしいですか？",
    },
    descriptions: {
      unlock:
        "誤ったパスワードを5回入力し、アカウントがロックされた従業員をこちらから解除できます",
      exportGuide: {
        description1:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
        exportTargets: {
          labels: {
            group: "部署",
            post: "役職",
            departmentPostRelationship: "部署役職紐付け",
            tag: "従業員ラベル",
            member: "従業員",
            personalCategoryPreference: "従業員毎の経費科目表示設定",
            teiki: "定期情報",
            flow: "申請フロー(作成)",
            approverSelectFlow: "承認者選択フロー(作成)",
            flowMapping: "申請フロー(登録)",
          },
          description1: "エクスポート対象を選択してください",
          description2: "未選択の場合は全シートをエクスポートします",
        },
      },
      importGuide: {
        conma: "、",
        department: "部署",
        post: "役職",
        approvalFlow: "申請フロー",
        teikiRoute: "定期区間",
        expenseAccount: "支払口座",
        multiInfoImport:
          "従業員の情報と{{value}}の設定を同時にインポートします",
        memberInfoImport: "従業員情報をインポートします",
        sample: "サンプル",
        templateGuide: "テンプレートはこちら",
        overwrite:
          "既に登録されているユーザーの情報は、<u>インポート時に上書きされます</u>",
        pass: "バスを含む定期券については、定期控除が適切に行われない場合があります",
        frozen: "凍結を行う場合のみ凍結列に「する」をご記入ください",
        mail: "変更後のメールアドレスは各従業員毎の最初の行に記載ください",
        bankCode:
          "銀行名と銀行コードの両方が記載されている場合は、銀行コードを優先します(支店名に関しても同様)",
        export:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください",
        importTargets: {
          labels: {
            group: "部署",
            post: "役職",
            departmentPostRelationship: "部署役職紐付け",
            tag: "従業員ラベル",
            member: "従業員",
            personalCategoryPreference: "従業員毎の経費科目表示設定",
            teiki: "定期情報",
            flow: "申請フロー(作成)",
            approverSelectFlow: "承認者選択フロー(作成)",
            flowMapping: "申請フロー(登録)",
          },
          description1: "インポート対象を選択してください",
          description2:
            "選択したインポート対象以外のシートはインポートされません",
          description3: "未選択の場合は全シートをインポートします",
        },
      },
    },
    link: {
      closeExportAndOpenImport: "インポート画面へ移動",
      closeImportAndOpenExport: "現在の設定内容をエクスポート",
    },
    actions: {
      addLabel: "ラベルを追加",
      enableAccountSettingsLock: "口座設定のロック設定を有効化",
      enableTeikiSettingsLock: "定期区間のロック設定を有効化",
    },
  },
  groups: {
    noValue: "設定なし",
    titles: {
      groupList: "部署一覧",
      employeeList: "従業員一覧",
      displayColumnSettings: "一覧表示設定",
      displayCodeColumn: "部署コードを一覧に表示する",
      editParent: "親部署の編集",
      selectGroup: "部署を選択する",
      selectPosts: "役職を選択する",
      costAllocatedDepartment: "費用負担部署",
      export: "部署エクスポート",
    },
    actions: {
      addGroup: "部署を追加する",
      editGroup: "部署を編集する",
      deleteGroup: "部署を削除する",
      searchPlaceholder: "検索する部署名",
      inputExpenseGroup: "費用負担部署を入力できるようにする",
      inputBurdenRatio: "費用負担部署の負担割合を入力できるようにする",
      exportDepartmentsPostsApproversTable: "エクスポート",
    },
    properties: {
      name: "部署名",
      parent: "親部署",
      payer: "費用負担部署",
      approver: "承認部署",
      employee: "従業員",
      post: "役職",
      sort: "並び順",
      code: "部署コード",
    },
    messages: {
      sort: "並び順の指定がない場合はコード順に、コードの指定がない場合は名前順に従って表示されます。",
      posts: "従業員に指定できる役職を部署ごとに設定することができます。",
    },
    exports: {
      guide1: "各部署に在籍している役職者を確認できます。",
      guide2: "承認権限のある役職者が在籍している場合、名前が表示されます。",
      guide3: "承認権限のない役職者は（権限なし）と表示されます。",
      guide4:
        "グレーの箇所は、該当部署・該当役職に従業員が在籍していないことを示しています。",
    },
  },
  payments: {
    plans: {
      /* eslint-disable camelcase */
      corporate_yearly: "コーポレートプラン（年間一括払い）",
      personal_yearly: "パーソナルプラン（年間一括払い）",
      corporate_monthly: "コーポレートプラン（月々払い）",
      personal_monthly: "パーソナルプラン（月々払い）",
      /* eslint-enable camelcase */
    },
    titles: {
      contractInformation: "契約情報",
      monthlyUsage: "月ごとの自動入力件数",
      paperlessPlanSubscriptions: "ペーパーレス契約期間一覧",
      receiptBoxes: "ポスト一覧",
    },
    messages: {
      cannotChangePlanNow:
        "パーソナルプラン・コーポレートプランの変更は現在受け付けておりません。",
    },
    properties: {
      monthlyUsageScope: "表示対象年",
    },
    tables: {
      yearAndMonth: "年/月",
      numberOfUsed: "自動入力件数",
    },
    actions: {
      receiptBoxes: {
        search: "ポストを検索",
      },
    },
  },
  posts: {
    titles: {
      create: "役職を作成",
      edit: "役職を編集",
      companyPostSetting: "役職設定",
      projectPostSetting: "プロジェクトの役職設定",
      createCompanyPost: "役職を作成",
      createProjectPost: "プロジェクトの役職を作成",
      editCompanyPost: "役職を編集",
      editProjectPost: "プロジェクトの役職を編集",
      postNotSet: "プロジェクトの役職が登録されていません",
    },
    actions: {
      addPost: "役職を追加",
      deletePost: "役職を削除",
      searchPlaceholder: "検索する役職",
    },
    properties: {
      name: "役職名",
      order: "並び順",
    },
  },
  accountForms: {
    titles: {
      newAccount: "連携カード・サービス登録",
      linkedAccount: "連携カード・サービス / 認証情報編集",
      additionalInputAccount: "連携カード・サービス / 追加認証情報入力",
      nfcAccount: "連携カード・サービス / ICカード設定編集",
    },
    actions: {
      registerAsCorporateCard: "コーポレートカード（法人決済型）として登録",
      registerAsCorporateExpense: "精算対象外として登録",
      toggleMergeable: "経費登録した明細を自動入力経費に統合する",
      toggleAutoRegister: "明細の経費登録を自動で行う",
      toggleIsExcludingProductPurchase: "物販の明細を登録しない",
      defaultCategory: "経費登録時の経費科目",
      defaultGroup: "経費登録時の費用負担部署",
      defaultProject: "経費登録時のプロジェクト",
      defaultComment: "経費登録時のメモ欄テンプレート",
    },
    help: {
      registerAsCorporateCard:
        "個人口座ではなく法人口座から引落しが発生するカードの場合にONとします。経費作成時に精算対象外となります。",
      registerAsCorporateExpense:
        "個人口座ではなく法人口座から引落しが発生する場合にONとします。経費作成時に精算対象外となります。",
    },
    corporateCard: "コーポレートカード",
  },
  timeStamps: {
    timeStamp: "タイムスタンプ",
    versionHistory: "バージョン履歴",
    properties: {
      stampedAt: "署名日時",
    },
    foreside: "表面",
    backside: "裏面",
  },
  requestTypes: {
    modalHeader: {
      settingPreReport: "事前申請オプション設定",
      settingReport: "経費精算オプション設定",
      editForm: "申請フォーム編集",
      createForm: "申請フォーム作成",
    },
    modalBody: {
      useTemporaryPayment: "仮払利用",
      useDraftSaveReport: "下書き保存機能",
      formName: "申請名",
      notEntered: "未入力",
      preview: "プレビュー",
      paidOptionAlert:
        "仮払機能の機能は、有料オプションとなります。ご利用の際には、右下のチャットからサポートまでご連絡ください。",
    },
    modalFooter: {
      close: "閉じる",
      save: "保存",
      add: "追加",
    },
    formItems: {
      labelName: "項目名",
      inputType: "入力タイプ",
      canNotDelete: "削除できません",
      canNotEdit: "編集できません",
      addNewFormItem: "入力項目を追加する",
      editFormItems: "申請項目編集",
      addFormItems: "申請項目追加",
      required: "入力必須",
      checkDescription: "チェック説明",
      choice: "選択肢",
      initValue: "初期値",
      inputChoicesLineBreakSeparated: "選択肢を改行区切りで入力してください",
      reportTitle: "申請名",
      editForm: "フォーム",
      editOption: "オプション",
    },
  },
  approvalFlow: {
    title: {
      approvalFlowList: "申請フロー一覧",
      approvalFlowProblemExport: "申請フローエラー出力",
    },
    actions: {
      createApprovalFlow: "申請フローを作成する",
      createSelectNextApproverApprovalFlow: "承認者選択フローを作成する",
      checkProblemsApprovalFlow: "設定に問題がないかチェックする",
    },
    properties: {
      name: "名前",
      condition: "条件",
      priority: "優先度",
      isApproverSelectFlow: "承認者選択",
      approvalFlow: "申請フロー",
      edit: "編集",
      copy: "複製",
      deletion: "削除",
      approverSelectFlow: "承認者選択フロー",
      flowName: "フロー名",
    },
    help: {
      title: "申請の種類と条件の対応表",
      conditions: {
        condition: "条件",
        amount: "金額",
        totalAmount: "合計金額",
        category: "経費科目",
        department: "所属部署",
        parentDepartment: "親部署",
        payerDepartment: "費用負担部署",
        project: "プロジェクト",
        preReport: "事前申請",
      },
      flowType: {
        report: "経費精算",
        preReport: "事前申請",
        paymentRequest: "支払依頼申請",
        proposalRequest: "稟議申請",
        applicationReport: "汎用申請",
      },
    },
  },
  metadata: {
    tables: {
      user: "氏名",
      group: "部署名",
      companyPost: "役職名",
      tag: "ラベル名",
      project: "プロジェクト名",
      category: "経費科目名",
      taxCategory: "税区分",
      superCategory: "勘定科目",
      paymentRequestsDebitInvoiceCategory: "借方請求明細科目名",
      paymentRequestsCreditInvoiceCategory: "貸方請求明細科目名",
      paymentRequestsSupplier: "取引先名",
      companyExpenseAccount: "支払口座",
    },
    keyModal: {
      add: "項目追加",
      update: "項目編集",
      key: "項目{{index}}",
      newKey: "新規項目{{index}}",
    },
    buttons: {
      createLatestExportFile: "最新のエクスポートファイル作成を開始",
      importFromXlsx: "インポート",
      downloadAsXlsx: "エクスポート",
      moveToExportModal: "現在の設定内容をエクスポート",
      moveToImportModal: "インポート画面へ移動",
      guideAboutChangeConfig:
        "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてださい。",
    },
    messages: {
      added: "項目を追加しました",
      updated: "項目名を更新しました",
      deleted: "項目を削除しました",
    },
    imports: {
      title: "付加情報インポート",
      importMetadata: "付加情報設定をインポートします。",
      sample: "サンプル",
      guide:
        "サンプルデータのD列・E列に補足事項の記載があります。インポート時には補足事項の列は削除してください。",
      guide2:
        "項目名は一致している必要があります ※インポートによる項目追加はできません。画面より追加ください。",
      guide3:
        "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてださい。",
      guideWithHierarchy:
        "部署名・経費科目名に「/」が含まれる場合はエクスポート時に「¥/」と変換されますが、そのままインポートしてください。",
    },
    exports: {
      title: "付加情報エクスポート",
    },
    alertSettings: {
      genericFields: {
        title: "汎用マスタ設定でコード・名称や選択肢を設定できます。",
        urlName: ">> 汎用マスタ設定に移動",
      },
    },
  },
  allowance: {
    settings: {
      title: "日当・手当表設定",
      newEntry: "新規作成",
    },
    properties: {
      name: "表名称（入力フォーム名）",
      icon: "メニューアイコン",
      factorTypes: "手当表の作成",
      categories: "選択できる経費科目",
      defaultCategory: "デフォルトの経費科目",
      addTableDimension: "表の次元を追加する",
      tableSize: "表のサイズ",
      table: "手当表",
      additionalItem: "追加入力項目",
      addInputItem: "入力項目を追加する",
      inputType: "入力タイプ",
      calFormula: "計算式",
      insertVariable: "変数を挿入",
    },
    descriptions: {
      name: "サイドメニューに表示される名前になります",
      icon: "サイドメニューに表示されるアイコンになります",
      factorTypes:
        "手当の計算に使うマトリックスの項目を設定します。 「役職」と「移動距離」で手当が決まる場合、その2点をを入力します",
      categories: "手当の入力時に選択可能とする経費科目を設定します。",
      defaultCategory:
        "手当の入力時にデフォルトで選択する経費科目を設定します。",
      tableSize: "作成する表が含むことのできるセルの最大数は200個です",
      addCalcItem: "手当の総額計算に使用する項目を追加できます",
      formula: "一回の申請に含まれる、手当の総額を算出する式を設定します",
    },
    placeholders: {
      categories: "全ての経費科目を選択可能にする",
      nameOfName: "{{name}}名",
      itemName: "項目名",
      actualCost: "実費入力",
    },
    messageBox: {
      title: {
        info: "フォーマットについて",
        variable: "変数",
        variableName: "[変数名]",
        operator: "演算子",
        function: "関数",
        allowance: "手当",
      },
      description: {
        variable: "項目に対応する、ユーザの入力値が代入されます",
        operator: "足し算、引き算、掛け算を表します",
        funcMin: "a, bの内、小さい方の値を返します",
        funcMax: "a, bの内、大きい方の値を返します",
      },
    },
    notification: {
      unsaved: "保存されていないデータがあります。移動してよろしいですか？",
      unusedVariable: "使用されていない変数があります:",
      sure: "{{name}}を削除します。よろしいですか？",
    },
    pulldown: {
      numeric: "数字入力",
      duration: "期間入力",
      select: "セレクトボックス",
    },
    selectionBox: {
      optionSet: "オプション設定",
      optionName: "オプション名",
      value: "数値",
      add: "オプションを追加する",
    },
  },
  categories: {
    actions: {
      selectCategory: "経費科目を選択する",
      searchPlaceholder: "検索する経費科目名",
    },
    imports: {
      title: "経費科目インポート",
      importCategories: "経費科目の情報をインポートします。",
      sample: "サンプル",
      guide:
        "サンプルデータの最終列に補足事項の記載があります。インポート時には最終列を削除してください。",
      guide2:
        "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
      moveExport: "現在の設定内容をエクスポート",
    },
    exports: {
      title: "経費科目エクスポート",
      createLatestExportFile: "最新のファイル作成の開始",
      guide:
        "設定済みの内容を修正する際には、最新の設定内容をエクスポート、エクスポートファイルを修正の上インポートしてください。",
      moveImport: "インポート画面へ移動",
    },
  },
  tags: {
    actions: {
      selectTag: "従業員ラベルを選択",
      searchPlaceholder: "検索する従業員ラベル",
    },
  },
  companyPosts: {
    actions: {
      selectCompanyPost: "役職を選択",
      searchPlaceholder: "検索する役職名",
    },
  },
  personalCategories: {
    titles: {
      individualSettings: "個別経費科目表示設定",
    },
    messages: {
      saved: "{{name}}を{{state}}にしました",
    },
    misc: {
      enabled: "有効",
      disabled: "無効",
    },
  },
  expenseDataEntries: {
    waitingTime: "現在の領収書データ化時間目安",
    hoursGreaterThan: "{{hours}}時間以上",
    hoursLessThan: "{{hours}}時間未満",
    minutesGreaterThan: "{{minutes}}分以上",
    minutesLessThan: "{{minutes}}分未満",
    timeGreaterThan: "{{hours}}時間{{minutes}}分以上",
    timeLessThan: "{{hours}}時間{{minutes}}分未満",
  },
  journalEntries: {
    expense: "経費",
    reortItem: "明細",
    invoice: "請求書",
    messages: {
      deleted: "削除しました",
      duplicated: "仕訳フォーマット「{{name}}」を複製しました",
      maxLengthRange: "最大文字数は1~9999の値で入力してください",
      published: "仕訳フォーマット「{{name}}」を公開しました",
      registeredExportFormat: "仕訳フォーマットを登録しました",
      restored: "仕訳フォーマットを復元しました",
      saved: "保存しました",
      setToDefault: "仕訳フォーマット「{{name}}」を既定の形式にしました",
      setToDraft: "仕訳フォーマット「{{name}}」を非公開にしました",
      setToUndefault:
        "仕訳フォーマット「{{name}}」を既定の形式から解除しました",
      updatedDebitCreditStyle: "貸借形式を更新しました",
      updatedExportFormat: "仕訳フォーマットを更新しました",
      updatedExportTarget: "出力する対象を更新しました",
      updatedVoucherPreference: "伝票・仕訳設定を更新しました",
    },
    descriptions: {
      addColumnWithPlusButton:
        "「借方」や「貸方」の「＋」ボタンから出力項目を追加できます。",
      aggregationLeftHand:
        "「4. 伝票・仕訳を設定する」で「まとめる対象」を設定した場合、その条件を満たすかどうかで、出力内容を変更できます。",
      cellType:
        "カスタマイズでは、条件分岐や複数の項目の指定など、より複雑な出力内容を設定できます。",
      customCellValue:
        "文字入力と項目選択を組み合わせることができます。例）申請提出日_従業員名",
      customCellValueOnClick:
        "また、選択した項目をクリックすることで、項目ごとに以下を指定できます。",
      customCellValueSettingDate: "・（出力内容が日付の場合）日付形式",
      customCellValueSettingLength: "・文字数制限",
      customCellValueSettingCharExtraction:
        "・（出力内容が日付以外の場合）文字列抽出",
      customCellValueSettingCharLeading: "・桁調整",
      dateFormat:
        "年、月、日、時、分、秒を指定できます。例）2021/9/5 12:30:00(令和3年9月5日)の場合、jpy/m/d → 3/9/5、yyyymmdd_hhMMss → 20210905_123000、yymmlastdd → 210930 (月末日付)、",
      defaultFormat:
        "会計データ出力時のデフォルト設定を指定できます。尚、「非公開」状態の仕訳フォーマットは選択できません。",
      deleteAdditionalInformation:
        "サンプルデータの最右列に補足事項の記載があります。インポート時には補足事項の列を削除してください。",
      deleteDefaultExportFormat:
        "この仕訳フォーマットは「既定の形式」に指定されています。削除すると「会計データ出力形式」で設定された既定の形式が適用されます。",
      exportFormatName:
        "仕訳ファイル作成時、出力するフォーマットを選択します。「仕訳フォーマット名」は、その選択肢として表示される名称です。選択時に判別しやすい名称を設定ください。",
      exportFormatNotPublished:
        "この仕訳フォーマットは公開されていません。経費集計や承認一覧から出力形式として選択できるようにするには、「公開する」をクリックしてください。",
      exportGuide:
        "設定済みの出力項目を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
      exportPriority: "上にある条件ほど優先度が高くなります",
      importantImportGuide: "重要事項となりますので、以下必ずご一読ください。",
      importColumns: "出力項目の情報をインポートします。",
      importGuide:
        "設定済みの出力項目を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
      includeZeroAmountEntries:
        "金額が0円となる明細行を出力するかどうかを設定できます。",
      leadingChar:
        '出力項目に最大文字数を設定した場合、桁を調整します。例）最大文字数5の出力項目の値を「0埋め」する場合、値"123"は"00123"と出力されます。',
      naviTextUnclickableReason:
        "別の設定画面に移動する前に、設定を保存するか、変更を破棄してください。",
      onlyConstantCellUpdatable:
        "出力内容については、固定値のみインポートでの登録・変更が可能です。項目選択やカスタムは登録・変更できません。",
      preferencePreviewTable:
        "「伝票について」の設定内容に応じて仕訳イメージが表示されます。行の出力方法やまとめ方は反映されません。",
      prioritizeOverExportPreference:
        "当設定の「既定の形式」は、会計データ出力形式の「既定の形式」よりも優先されます。",
      publicationStatus:
        "集計や承認一覧などの出力形式として選択できるのは、「公開」となっている仕訳フォーマットのみです。",
      rowSep: "会計ソフトの特別な指定がない限り、「LF」で取り込みが可能です。",
      sample: "サンプル",
      saveEachContent: "この画面では、出力項目ごとに変更を保存します。",
      showSettingsSample:
        "「設定例を表示する」にカーソルを合わせると、設定イメージを確認できます。",
      template: "テンプレート",
      ungivenColumnsWillBeDeleted:
        "インポートファイルで指定されていない出力項目は削除されます。",
      useMetadataToSetCode:
        "勘定科目コード、税区分コードを出力したい場合には、付加情報設定をご利用ください。",
      voucherDivisionWay:
        "{{resource}}集計や申請に含まれる{{item}}を伝票に分けて出力できます。画面下方のプレビューの伝票番号、明細行番号を参考にしてください。",
      writeHeaders:
        "仕訳ファイルの1行目に項目名や受入記号等を出力する場合、ヘッダーを「出力する」としてください。",
    },
    titles: {
      blankColumnName: "[項目名なし]",
      exportContentCellModal:
        "{{- columnName}}({{rowExpression}})の出力内容を設定する",
      exportForm: "出力内容設定エクスポート",
      formatList: "仕訳フォーマット一覧",
      importForm: "出力内容設定インポート",
      instruction: "マニュアル",
      instructionFirstHalf: "詳しい設定方法については",
      instructionSecondHalf: "を参照してください。",
      restored: "仕訳フォーマットを復元する",
      setUpColumn: "{{classificationExpression}}の出力項目を設定する",
      setUpDebitCreditStyle: "貸借形式を設定する",
      setUpExportContent: "出力内容を設定する",
      setUpExportTarget: "出力する対象を設定する",
      setUpFileFormat: "ファイル形式を設定する",
      setUpProcedures: "仕訳フォーマット設定のステップ",
      setUpVoucherDivision: "伝票・仕訳を設定する",
      updateDebitCreditStyle: "貸借形式を変更する",
      variablePopover: "{{label}}の編集",
      selectPaymentMethodType: "支払方法を選択",
    },
    buttons: {
      addAndCondition: "AND条件を追加",
      addConditionalBranch: "条件分岐を追加",
      addOrCondition: "OR条件を追加",
      backToList: "一覧へ戻る",
      backward: "戻る",
      close: "閉じる",
      create: "新規作成する",
      detailed: "拡張子等の詳細設定",
      discardChanges: "変更を破棄",
      downloadPreviewData: "サンプルデータ", // ボタンなので短い表記にする
      finished: "完了",
      forward: "進む",
      moveToExportModal: "現在の設定内容をエクスポート",
      moveToImportModal: "インポート画面へ移動",
      publish: "公開する",
      requestToDownloadExportContents: "最新のエクスポートファイル作成を開始",
      restore: "復元する",
      restored: "復元",
      save: "保存",
      setToDraft: "非公開にする",
      toExportPreferenceSettings: "会計データ出力形式",
      toMetadataSettings: "付加情報設定",
    },
    status: {
      published: "公開",
      private: "非公開",
    },
    labels: {
      aboutExportContent: "出力値について",
      aboutVoucher: "伝票について",
      aggregatingTarget: "まとめる対象",
      aggregationWay: "まとめ方",
      formatName: "仕訳フォーマット名",
      accountingSoftwareName: "会計ソフト",
      colSeps: {
        label: "区切り文字",
        comma: "カンマ",
        tab: "タブ",
        noColSep: "なし",
      },
      dateFormat: "日付の形式",
      debitCreditClassificationExpressions: {
        // eslint-disable-next-line camelcase
        common_former: "共通(前半)",
        // eslint-disable-next-line camelcase
        common_latter: "共通(後半)",
        credit: "貸方",
        debit: "借方",
      },
      displaySampleDataInExportContents: {
        setToFalse: "サンプル値を登録しない",
        setToTrue: "サンプル値を登録する",
      },
      displaySettingsSampleHovered: {
        display: "設定例を表示する",
        displayed: "設定例を表示中",
      },
      exportWay: "出力方法",
      fileExtension: "拡張子",
      leadingChar: "桁調整",
      operators: {
        equal: "=",
        // eslint-disable-next-line camelcase
        all_equal: "完全一致",
        // eslint-disable-next-line camelcase
        greater_than: ">",
        // eslint-disable-next-line camelcase
        greater_than_or_equal: "≥",
        // eslint-disable-next-line camelcase
        less_than: "<",
        // eslint-disable-next-line camelcase
        less_than_or_equal: "≤",
        // eslint-disable-next-line camelcase
        not_equal: "≠",
        in: "含む",
        // eslint-disable-next-line camelcase
        contains_any: "いずれかを含む",
        // eslint-disable-next-line camelcase
        not_in: "含まない",
        // eslint-disable-next-line camelcase
        contains_all: "すべて含む",
        // eslint-disable-next-line camelcase
        not_contain_any: "いずれも含まない",
        // eslint-disable-next-line camelcase
        match_any: "=",
        // eslint-disable-next-line camelcase
        not_match_any: "≠",
      },
      rowSep: "改行コード",
      header: "ヘッダー",
      zeroAmountEntries: "0円の明細行",
      cellType: "種類",
      cellTypes: {
        constant: "固定値",
        custom: "カスタマイズ",
        variable: "項目選択",
      },
      sampleValue: "サンプル値",
      createVoucher: "伝票作成",
      voucherDivisionWay: "分け方",
      voucherUnit: "伝票単位",
      exportContent: "出力内容",
      contentName: "項目名",
      exportedHeaderName: "ヘッダー名",
      charCountLimit: "文字数制限",
      charConversion: "カナ英数変換",
      withCellValueConditions: "条件分岐",
      exportTargetConditionSetting: {
        simple: "簡単な設定",
        advanced: "詳細な設定",
      },
      charExtraction: "文字列抽出",
      charExtractionForm: {
        cut: {
          from: "から",
          output: "文字を出力する",
        },
        extract: {
          from: "から",
          output: "の間に含まれる文字列を出力する",
          includeWord: "含む",
          helpCard: {
            title: "抽出する文字列について",
            exampleDescription: {
              example: "例)",
              exampleWord1: "株式会社TOKIUM",
              exampleWord2: "インボイス係",
              exampleWord3:
                "（※TOKIUMの後ろに改行あり）から、指定した文字で抽出を行った場合",
            },
            header: {
              start: "開始",
              end: "終了",
              result: "抽出結果",
            },
            example1: {
              start: "株式会社(含まない)",
              end: "係(含まない)",
              result: "TOKIUM インボイス",
            },
            example2: {
              start: "株式会社(含む)",
              end: "係(含まない)",
              result: "株式会社TOKIUM インボイス",
            },
            example3: {
              start: "先頭",
              end: "係(含まない)",
              result: "株式会社TOKIUM インボイス",
            },
            example4: {
              start: "株式会社(含まない)",
              end: "末尾",
              result: "TOKIUM インボイス係",
            },
            example5: {
              start: "株式会社(含まない)",
              end: "行末",
              result: "TOKIUM",
            },
          },
        },
      },
    },
    contents: {
      aggregate:
        "ごとに{{resourceName}}を合算して、{{rowExpression}}をまとめる",
      aggregateToSingleRow: "1行にまとめる",
      aggregateToMultipleRows: "その他",
      aggregationPolicies: {
        withoutVoucher: {
          noAggregation:
            "{{resourceName}}をまとめずに、1{{resourceName}}1行として出力する",
          partialAggregation:
            "一部の{{resourceName}}をまとめ、その他の{{resourceName}}は1{{resourceName}}1行として出力する",
          allAggregation: "すべての{{resourceName}}をまとめる",
        },
        withVoucher: {
          noAggregation:
            "伝票内の{{resourceName}}をまとめずに、1{{resourceName}}1行として出力する",
          partialAggregation:
            "伝票内の一部の{{resourceName}}をまとめ、その他の{{resourceName}}は1{{resourceName}}1行として出力する",
          allAggregation: "伝票内のすべての{{resourceName}}をまとめる",
        },
      },
      export: "出力する",
      exportContentInOtherCases: "その他の場合、以下を出力",
      exportContentWithCondition: "を満たす場合、以下を出力",
      doNotExport: "出力しない",
      createVoucher: "伝票を作る",
      doNotCreateVoucher: "伝票を作らない",
      multipleVoucher: "複数の伝票に分ける",
      singleVoucher: "伝票を分けないで1つにまとめる",
      debitCreditStyles: {
        balanceEachRow: "行ごとに貸借金額が一致する",
        creditUpperRightAndDebitBottomLeft:
          "借方と貸方が異なる行として表現され、伝票内で貸借金額が一致する",
        useDepositRow: "源泉徴収税額を別の行に出力する（預り金）",
      },
      voucherDivision: "ごとに{{resourceName}}をまとめて、伝票を分ける",
      leadingChars: {
        nothing: "なし",
        space: "半角スペース埋め",
        zero: "0埋め",
      },
      charCutPosition: {
        beginning: "先頭",
        ending: "末尾",
      },
      charExtractStartPosition: {
        wordSpecification: "ワード指定",
        beginning: "先頭",
      },
      charExtractEndPosition: {
        wordSpecification: "ワード指定",
        endOfSentence: "末尾",
        endOfLine: "行末",
      },
      charExtraction: {
        nothing: "なし",
        cut: "文字数で切り取り",
        extract: "指定した文字で抽出",
      },
      preview: "プレビュー",
      updateDebitCreditStyleConfirmation:
        "貸借形式を変更すると、「4. 伝票・仕訳を設定する」「5. 出力内容を設定する」で設定済みの内容が削除されます。この操作は元に戻せません。",
      impose: "付ける",
      doNotImpose: "付けない",
      doNot: "しない",
      fullWidth: "全角",
      halfWidth: "半角",
      fullWidthTohalfWidth: "全角→半角",
      editContent: "項目を編集",
      deleteContent: "項目を削除",
      addContentToRightSide: "右側に項目を追加",
      addContentToLeftSide: "左側に項目を追加",
      withConditions: "あり",
      withoutConditions: "なし",
    },
    placeholder: {
      blank: "空欄",
      dateFormatExample: "例: yyyy/mm/dd",
      invoiceFormatName: "例：請求書仕訳_弥生会計",
      expenseFormatName: "例：経費仕訳_弥生会計",
      multipleSelectable: "複数選択可",
      unselected: "未選択",
      sampleValueDisabled: "自動",
    },
    tables: {
      headers: {
        date: "日付",
        defaultFormat: "既定の形式",
        voucherNumber: "伝票番号",
        rowNumber: "明細行番号",
        debit: "借方",
        credit: "貸方",
        superCategory: "勘定科目",
        amount: "金額",
        taxCategory: "税区分",
        formatName: "仕訳フォーマット名",
        accountingSoftwareName: "会計ソフト",
        publicationStatus: "公開状態",
      },
    },
    navTabs: {
      expenseExportFormat: "TOKIUM経費精算",
      invoiceExportFormat: "TOKIUMインボイス",
    },
    createModal: {
      title: "仕訳フォーマットを作成",
      labels: {
        fromTemplate: "テンプレートから作成する",
        fromScratch: "最初から作成する",
        templateName: "テンプレート名",
      },
      buttons: {
        submit: "新規作成する",
      },
    },
  },
  searchResults: {
    messages: {
      results: "検索結果",
      notFound: "検索結果が見つかりません",
      selectableItemNotFound: "選択できる{{itemName}}がありません",
      truncated: "最初の{{count}}件を表示しています",
      limited: "最大{{count}}件まで表示可能です",
      displayingRecentlyUsed:
        "最近利用したプロジェクトを優先的に表示しています",
      selection: "選択中",
    },
  },
  superCategories: {
    actions: {
      searchPlaceholder: "検索する勘定科目名",
      selectSuperCategory: "勘定科目を選択",
    },
    import: {
      title: "勘定科目インポート",
    },
    export: {
      selectFileType: "ファイル形式を選択してください",
    },
    help: {
      title: "勘定科目インポートについて",
    },
  },
  corporateCards: {
    titles: {
      aggregationResults: "未承認明細一覧",
    },
    properties: {
      user: "カード利用者",
      numberLast4: "番号下4桁",
      brand: "ブランド",
      issuer: "発行会社",
      settlement: {
        corporate: "会社決済",
        personal: "個人決済",
      },
    },
    aggregationResults: {
      properties: {
        transactedAt: "利用日",
        alert: "アラート",
        name: "利用先",
        amount: "金額",
        reportTitle: "申請名",
        createdAt: "登録日",
      },
      alerts: {
        deleted: "明細が削除されています",
        unmerged: "経費が未登録です",
        toBePaid: "精算対象経費として登録されています",
      },
      statuses: {
        unapproved: "未承認",
        deleted: "明細削除",
        unmerged: "経費削除",
        toBePaid: "精算対象",
      },
    },
  },
  originalReceiptIdentificationRequests: {
    titles: {
      selectRecipients: "通知先の選択",
      searchResult: "検索結果",
      selectResult: "選択結果",
    },
    actions: {
      notifySelectedOriginalReceipts: "選択した原本を通知する",
      notifyOriginalReceipt: "この原本を通知する",
      advancedSearch: "高度な検索",
      allSelectRecipients: "表示している内容を全て選択する",
      clearAllRecipients: "全て外す",
      sendNotification: "対象のユーザに通知を送信する",
    },
    properties: {
      employeeName: "従業員名",
      department: "部署",
    },
  },
  paperless: {
    receiptMatchings: {
      titles: {
        match: "突合画面",
        searchExpenses: "経費を検索",
      },
      errors: {
        unknownCurrency: "通貨不明",
        failedToLoadExpenses: "突合候補経費が取得できません",
        expenseNotSelected: "突合する経費が選択されていません",
        requireSearch:
          "経費に該当する画像がない場合、利用日・金額のいずれかの条件を変更したうえで検索をしてください。\n※実際の画像に記載されている内容と入力データで、データ側の金額/日付の片方の内容が誤っているケースがあります。",
      },
      warnings: {
        JR: "切符番号、購入した駅名も併せて確認してください",
      },
      properties: {
        amount: "金額",
        shopName: "支払先",
        transactedAt: "利用日",
        originalReceipt: "原本画像",
        expenseReceipt: "経費画像",
        status: "ステータス",
      },
      actions: {
        match: "突合する",
        skip: "該当画像なし",
        next: "この画像をスキップ",
        resetSearchConditions: "検索条件をリセット",
        searchWithTheConditions: "指定した条件で検索",
      },
      messages: {
        select: "選択してください",
        match: "突合を行いました",
        confirmation: "「{{action}}」でよろしいですか？",
      },
      progressionStatus: {
        labels: {
          finished: "月間処理数",
          remaining: "未処理数",
        },
      },
    },
    receiptCollectionCycles: {
      titles: {
        list: "原本回収サイクル一覧",
        search: "原本回収サイクルを検索",
      },
      properties: {
        collectedAt: "登録日",
        collectedCount: "回収データ数",
        matching: "突合",
        needMatchingCount: "突合未完了数",
        receivedAt: "受領日",
        rootGroupName: "事業所名",
        status: "ステータス",
        subscriptionPeriod: "契約期間",
        totalCollectedCount: "累計回収データ数",
        waitingForWorkerCount: "データ化待ち数",
      },
      actions: {
        new: "新規検証開始",
        resetSearchConditions: "検索条件をリセット",
        searchWithTheConditions: "指定した条件で検索",
      },
    },
    receiptBox: {
      properties: {
        address: "ポスト設置住所",
      },
    },
    receiptPackages: {
      titles: {
        list: "原本パック一覧",
      },
      properties: {
        managementId: "管理ID",
        storageCode: "パック管理番号",
        boxName: "ポスト名",
        status: "ステータス",
        receiptNumber: "データ数",
        delete: "削除",
      },
      actions: {
        new: "原本パックを追加する",
        edit: "原本パックを編集する",
        report: "レポート出力",
      },
      messages: {
        confirmExport: "作業を終了し、レポート作成しますか？",
      },
    },
    receiptMatchingResults: {
      titles: {
        list: "突合結果確認",
      },
    },
    originalReceipts: {
      titles: {
        list: "領収書原本一覧",
        details: "原本詳細",
        upload: "原本アップロード",
        selectMatchedExpense: "突合する経費の選択",
        exportModal: "原本一覧のレポート作成",
      },
      matchingActions: {
        selectMatchedExpense: "対象の経費を突合",
      },
      properties: {
        amount: "金額",
        collectedAt: "登録日",
        image: "原本画像",
        shopName: "支払先",
        transactedAt: "利用日",
        companyName: "事業所名",
        storageCode: "パック管理番号",
        selfStatus: "原本ステータス",
        expenseStatus: "経費ステータス",
        reportTitle: "経費精算名",
        reportStatus: "申請ステータス",
        postName: "ポスト名",
        postAddress: "ポスト設置住所",
        matchingStatus: "突合ステータス",
        expenseAuthorName: "経費作成者名",
        notificationStatus: "通知ステータス",
        originalFilename: "ファイル名",
      },
      actions: {
        export: "検索結果でレポートを出力",
        create: "最新のレポート作成を開始",
      },
      matchingStatus: {
        waitMatching: "突合待ち",
        waitCreateExpense: "該当経費画像なし",
        unsubmitted: "未申請",
        applying: "申請中（最終承認除く）",
        waitLastApplying: "最終承認待ち",
        closeApplying: "最終承認済み",
        canceled: "キャンセル（突合解除）",
        unnecessary: "私用の原本",
        notified: "通知済",
        unnotified: "未通知",
      },
    },
    receiptBoxes: {
      properties: {
        name: "ポスト名",
      },
    },
    sendBackings: {
      actions: {
        sendBack: "返却する",
      },
      messages: {
        confirmSendBack: "本当に返却しますか？",
        completeSendBack: "返却しました",
      },
    },
    expense: {
      markAsMatched: "原本確認済みにステータスを更新する",
      reviseMarkedAsMatched: "原本確認済みのステータスを取り消し/変更する",
      confirmOriginalReceipt: "原本を確認する",
      cancelMatchingModal: {
        title: "突合済みの原本画像",
        cancel: "解除する",
        footer: {
          button: "原本とのひも付きを解除する",
        },
      },
      forms: {
        markingAsMatchedForm: {
          labels: {
            comment: "コメント",
          },
          placeholders: {
            comment: "変更理由",
          },
        },
      },
      modals: {
        markingAsMatchedModal: {
          title: "原本確認済みにステータスを更新",
          footer: {
            button: "原本確認済みにする",
          },
        },
        revisingMarkedAsMatchedModal: {
          title: "原本確認済みのステータスを取り消し/変更する",
          footer: {
            cancelButton: "解除する",
            updateButton: "コメントを更新する",
          },
        },
      },
      messages: {
        cancelConfirm: "本当に解除しますか？",
      },
    },
    rootGroupPreferences: {
      actions: {
        archive: "凍結",
        unarchive: "凍結解除",
      },
      imports: {
        receiptBoxes: {
          title: "ポストをインポート",
          caption: "ポストをインポートします。",
          sample: "サンプル",
          guide1:
            "サンプルデータのD列に補足事項の記載があります。インポート時には補足事項の列は削除してください。",
          guide2:
            "インポートでは項目の追加のみ可能です。編集を行う場合、画面より操作をお願いいたします。",
        },
      },
      titles: {
        archiveReceiptBoxes: "ポストを凍結",
        createPaperlessPlanSubscriptions: "契約期間を追加",
        createReceiptBoxes: "ポストを追加",
        deletePaperlessPlanSubscriptions: "契約期間を削除",
        deleteReceiptBoxes: "ポストを削除",
        paperlessPlanSubscriptions: "契約期間一覧",
        receiptBoxes: "ポスト一覧",
        search: "事業所情報 検索",
        searchReceiptBoxes: "ポストを検索",
        unarchiveReceiptBoxes: "ポストを凍結解除",
        updatePaperlessPlanSubscriptions: "契約期間を更新",
        updateReceiptBoxes: "ポストを更新",
      },
      labels: {
        includeArchivedBoxes: "凍結済みを含める",
      },
      messages: {
        archiveConfirm: "本当に凍結しますか？",
        deleteConfirm: "本当に削除しますか？",
        unarchiveConfirm: "本当に凍結解除しますか？",
      },
      models: {
        rootGroup: {
          properties: {
            name: "事業所名",
          },
        },
        receiptBox: {
          properties: {
            address: "ポスト設置住所",
            archived: "凍結済",
            name: "ポスト名",
            zipCode: "郵便番号",
          },
          propertyDescriptions: {
            zipCode: "3桁-4桁の形式で入力 例: 123-4567",
          },
        },
        paperlessPlanSubscription: {
          properties: {
            term: "契約期間",
          },
        },
      },
    },
  },
  paymentRequests: {
    common: {
      alert: "アラート",
      send: "送信",
      edit: "編集",
      delete: "削除",
      cancel: "キャンセル",
      save: "保存",
      create: "作成",
      input: "入力",
      append: "追加",
      expand: "展開",
      ok: "OK",
      back: "戻る",
      close: "閉じる",
      copy: "コピー",
      duplicate: "複製",
      select: "選択",
      enable: "利用する",
      notify: "通知する",
      doNotNotify: "通知しない",
      nothing: "なし",
      setting: "設定",
      colleague: "自社",
      client: "他社",
      reportCountUnit: "件",
      reportIndex: "検索結果 {{current}}/{{max}}件",
      beforeChange: "変更前",
      afterChange: "変更後",
      confirm: "確認",
      uploading: "アップロード中",
      copyToClipboard: "クリップボードへコピー",
      deselect: "解除",
      finish: "完了",
      register: "登録",
      searched: "検索結果",
      selected: "選択",
      detailSettings: "詳細設定",
      ng: "NG",
      loading: "読み込み中",
      workerInputting: "自動入力中",
      searchWithTheConditions: "この条件で検索",
      aggregateWithTheConditions: "この条件で集計",
      reflect: "反映",
      thisMonth: "今月",
      nextMonth: "来月",
      lastMonth: "先月",
      selectDepartment: "部署選択",
      unlink: "解除",
      fileName: "ファイル名",
      detail: "詳細",
      blankSearch: "空白検索",
      currencyConversion: "{{currency}}換算",
      halfWidthNumbersWithDigit: "半角数字{{digit}}桁",
      overView: "概要",
      openAnotherWindow: "別ウィンドウで開く",
      doDelete: "削除する",
      notSet: "未設定",
    },
    message: {
      confirmDelete: "本当に削除しますか？",
      confirmDeleteWithName: '本当に集計結果 "{{name}}" を削除しますか？',
      confirmBulkDelete:
        "複数選択している場合、一括削除されます。\n本当に削除しますか？",
      underControlledOrg: "原本管理中の請求書です。",
      underControlledOrgDocument: "原本管理中の書類です。",
      cannotRestoreOriginal:
        "削除を行うと原本の確認、取り出し等が出来なくなりますのでご注意ください。",
      enterAggregationName: "集計名を入力してください",
      noDataFound: "データがありません",
      hasNotSavedDiff: "保存されていない変更点があります",
      notifyToNewUsersInCharge:
        "取引先変更により、新しく担当者となった従業員へ通知を行いますか？",
      attachedInvoiceFiles: {
        add: "最大10枚まで請求書画像を追加できます",
        change: "表示中の請求書画像を入れ替えることができます",
      },
      attachedTaxDocumentFiles: {
        add: "最大10枚まで画像を追加できます",
        change: "表示中の画像を入れ替えることができます",
      },
      autoReusedPast:
        "同取引先における直近の請求書をもとに、システムが自動生成した明細です",
      copyToClipboardSuccess: "クリップボードへコピーしました",
      copyToClipboardFailed: "クリップボードへのコピーに失敗しました",
      urlIsNotValid: "URLが正しくありません。",
      guideToNationalTaxDocuments:
        "納品書/見積書/契約書/注文書などのその他国税関係書類はこちら",
      autoInput: "自動入力された請求書です",
      input: "入力してください",
      select: "選択してください",
      unlinkProposalsForReportItems:
        "削除と同時に発注稟議の消化状況から本請求書は除外されます。",
      confirmUnlink: "本当に解除しますか？",
      confirmDiscardChanges: "編集内容が保存されませんがよろしいですか?",
      confirmOpenWindow: "既存ウィンドウを閉じて、新しく開き直しますか？",
      saved: "保存しました。",
      savedSub: "保存した請求書は、「請求書一覧」にて確認できます。",
    },
    errors: {
      common: {
        notSelected: "選択されていません",
        notInput: "入力されていません",
        invalidAmount: "金額を入力してください",
      },
      unitPrice: {
        inputValue: "単価を入力してください",
      },
      quantity: {
        inputValue: "数量を入力してください",
      },
      costAllocations: {
        totalNotFull: "合計値が100%ではありません",
        totalAmountNotFull: "合計金額が明細の税込金額と一致していません",
        totalAmountNotFullForExcludeTax:
          "合計金額が明細の税抜金額と一致していません",
        hasZeroPercentage: "按分率に0%が存在します",
        hasZeroAmount: "按分金額に0が存在します",
        sumOfPercentagesMustNotBeZero:
          "按分率の合計を0%で登録することはできません",
        sumOfAmountsMustNotBeZero:
          "按分金額の合計を0で登録することはできません",
        duplicateDepartment: "部署が重複しています",
        emptyOrInvalidDepartment: "存在しない部署が指定されています",
      },
      genericFieldItems: {
        required: "必須項目です",
        multipleNotAllowed: "複数選択はできません",
      },
      attachedInvoiceFiles: {
        overLimit: "ファイル数の上限を超えたため、追加できませんでした",
        invalidMimetype: "対応していない拡張子のため、変更できませんでした",
      },
      proposals: {
        notSameSupplier: "稟議と請求書で設定されている取引先が異なります",
      },
      exports: {
        feePayerNotSelected: "手数料負担先を選択してください",
      },
      preference: {
        updateFailed: "設定の更新時にエラーが発生しました",
      },
      foreignCurrency: {
        getFailed: "外貨情報の取得時にエラーが発生しました",
      },
      registratedNumber: {
        inputAlert: "13桁で入力してください",
      },
    },
    notifications: {
      invoiceCreated: "請求書を登録しました",
      invoiceDeleted: "請求書を削除しました",
      invoiceDeletingNotSelected: "削除する請求書が選択されていません",
      invoiceUpdated: "請求書を更新しました",
      noteUpdated: "メモを更新しました",
      proposalSaved: "稟議を保存しました",
      invoiceNotSelected: "請求書が選択されていません",
      updatingInvoiceNotSelected: "更新する請求書が選択されていません",
      invoiceApproved: "請求書を承認しました",
      invoiceUnapproved: "請求書を未承認に戻しました",
    },
    titles: {
      menuTitle: "請求書一覧",
      histories: "集計履歴",
      registerNewInvoice: "請求書登録",
      createNewInvoice: "請求書（新規作成）",
      reportItemsEdit: "明細入力",
      paymentEdit: "支払設定",
      paymentAmount: "支払金額",
      reportItemsFormColumnSetting: "表示列設定",
      notifyToEmployee: "従業員への通知",
      previewOfficialize: "取引先の登録情報との差分",
      previewWorkerInvoiceInput: "請求書の記載情報との差分",
      timeline: "タイムライン",
      reportAlert: "請求書アラート",
      reportRequest: "支払依頼申請",
      reportTemplates: "請求書テンプレート一覧",
      reportTemplateCreate: "請求書テンプレート登録",
      reportTemplateUpdate: "請求書テンプレート編集",
      reportTemplateShow: "請求書テンプレート詳細",
      proposalRequest: "稟議申請",
      consolidatedReportRequest: "一括支払依頼申請",
      proposalCreate: "発注稟議作成",
      proposalInput: "詳細情報",
      proposalTimeline: "タイムライン",
      nationalTaxDocuments: "国税関係書類一覧",
      nationalTaxDocumentCreate: "国税関係書類登録",
      nationalTaxDocumentDetail: "国税関係書類詳細",
      nationalTaxDocumentBulkUpdate: "国税関係書類一括編集 {{count}} 件",
      nationalTaxDocumentTypeSelectorModal: "国税関係書類の書類カテゴリを選択",
      nationalTaxDocumentTypeAliasSelectorModal: "国税関係書類の書類種別を選択",
      waitingWorkerDocumentInputs: "自動入力中書類一覧",
      waitingWorkerDocumentInputsDetail: "自動入力中書類詳細",
      consumptionTax: "消費税",
      supplierShow: "取引先詳細",
      etc: "その他",
    },
    actions: {
      registerInvoice: "請求書を登録する",
      next: "次へ",
      previous: "前へ",
      supplierShow: "取引先詳細",
      save: "保存する",
      closeImageArea: "画像エリアを閉じる",
      openImageArea: "画像エリアを開く",
      closeAnotherWindow: "別ウィンドウを戻す",
    },
    authorities: {
      help: {
        admin: "システム設定メニューと、取引先画面の閲覧・編集ができます",
        accountant:
          "すべての書類と、取引先画面、集計者専用画面の閲覧・編集ができます",
        supplierManage: "取引先画面(取引先設定タブのみ)の閲覧・編集ができます",
      },
    },
    select: {
      debitInvoiceCategory: "借方請求明細科目選択",
      project: "プロジェクト選択",
    },
    previewWorkerInvoiceInput: {
      description: "現在の入力内容を請求書の記載情報で更新する",
      beforeChange: "現在の入力内容",
      afterChange: "請求書の記載情報",
    },
    previewOfficialize: {
      description: "取引先の登録情報を現在の入力内容で更新する",
      beforeChange: "取引先の登録情報",
      afterChange: "現在の入力内容",
    },
    periodicReport: {
      title: "定期支払一覧",
      description: "日程を指定して、請求書データを自動で作成します",
      notice:
        "請求書の自動作成は、設定変更の翌日以降に開始されます。\n設定変更当日に発生した請求書は、別途手動での作成をお願いいたします。",
      relativeMonthOption: "登録日の{{relativeMonth}}{{day}}日",
      // eslint-disable-next-line camelcase
      relativeMonthOption_end: "登録日の{{relativeMonth}}末日",
      relativeMonthLabels: ["登録日の当月", "登録日の翌月"],
      relativeMonth: ["当月", "翌月", "{{differenceMonth}}ヶ月後"],
      dayOfWeek: [
        "日曜日",
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日",
      ],
      days: "{{day}}日",
      endOfMonth: "末日",
      cyclicEventOption: {
        weekly: "毎週$t(paymentRequests.periodicReport.dayOfWeek.{{day}})",
        monthly: "毎月{{day}}日",
        // eslint-disable-next-line camelcase
        monthly_end: "毎月末日",
        labels: {
          weekly: "毎週",
          monthly: "毎月",
        },
      },
      term: "有効期間",
      relatedTaxDocuments: "関連書類",
      relatedTaxDocumentsSelection: "関連書類選択",
      taxDocuments: "国税関係書類",
      hasTaxDocuments: "関連書類の有無",
      bulkUnlink: "一括解除",
      error: {
        supplierIsEmpty: "取引先が設定されていません",
        supplierIsBlocked:
          "凍結されているため定期支払が作成されないようになっています",
      },
      message: {
        confirmUnlink: "本当に一括解除しますか？",
      },
    },
    properties: {
      undefined: "",
      null: "",
      searchCondition: "検索条件",
      filterBySpecified: "指定した条件で検索",
      amount: "金額",
      foreignCurrencyTotalAmounts: "外貨金額",
      currency: "通貨",
      rate: "レート",
      sequenceNumber: "申請ID",
      consolidatedReportSequenceNumber: "一括支払依頼申請ID",
      requestName: "申請名",
      requestDate: "申請日",
      approvalStatus: "承認状況",
      requestStatus: "申請状況",
      requesterName: "申請者名",
      belongingDepartment: "所属部署",
      approvalFlowName: "申請フロー名",
      paidAmount: "支払総額",
      withholding: "源泉徴収税額",
      aggregationId: "集計ID",
      scheduleAt: "支払希望日",
      groupName: "所属部署",
      supplier: "取引先",
      supplierInCharge: "取引先担当",
      supplierName: "取引先名",
      supplierCode: "取引先コード",
      supplierManager: "取引先管理",
      personInCharge: "担当者",
      departmentInCharge: "担当部署",
      dueAt: "支払期日",
      appropriatedAt: "計上日",
      registeredAt: "登録日",
      registeredAtWithTime: "登録日時",
      receivedAt: "受領日",
      lastApprovedAt: "最終承認日",
      enclosedReport: "関連請求書",
      paymentMethod: "支払方法",
      methodType: {
        unselected: "未選択",
        transfer: "振込",
        debit: "口座引落",
        cash: "現金",
        // eslint-disable-next-line camelcase
        payment_slip: "納付書",
        card: "カード",
        bill: "手形",
        check: "小切手",
        // eslint-disable-next-line camelcase
        electronically_recorded_monetary_claims: "でんさい",
        // eslint-disable-next-line camelcase
        overseas_remittance: "海外送金",
        other: "その他",
        undefined: "",
      },
      sourceType: {
        external: "受領",
        internal: "発行",
      },
      bank: "金融機関名",
      bankBranch: "支店名",
      bankAccountType: "口座種類",
      ordinal: "普通",
      current: "当座",
      saving: "貯蓄",
      other: "その他",
      bankAccountNumber: "口座番号",
      bankAccountHolder: "名義人",
      status: "ステータス",
      created: "未承認",
      paid: "支払処理済み",
      unpaid: "未払い",
      paymentStatus: "支払い状況",
      unexported: "未出力",
      exported: "出力済み",
      documentTypeAlias: "書類種別",
      multiPayerDepartment: "複数の費用負担部署",
      accountingDataExportStatus: "会計データ出力状況",
      aggregationMethod: "集計方法",
      amountInputType: "金額入力",
      includeTax: "税込",
      excludeTax: "税抜",
      amountIncludeTax: "請求金額",
      memo: "メモ",
      invoiceMemo: "請求書メモ",
      reportItemMemo: "明細メモ",
      consumptionTax: "消費税",
      targetTotalExcludeTax: "{{percentage}}%対象",
      sendFrom: "送付元",
      registratedNumber: "登録番号",
      asInvoice: "適格請求書として扱う",
      asInvoiceShort: "適格請求書",
      asInvoiceLong: "適格請求書（インボイス）として扱う",
      asInvoiceForSearch: "適格請求書の取り扱い",
      asInvoiceCheck: {
        true: "あり",
        false: "なし",
      },
      reportNumber: "請求書番号",
      reportItem: {
        name: "明細名",
        amountIncludeTax: "税込金額",
        shortAmountIncludeTax: "税込",
        amountExcludeTax: "税抜金額",
        amountConsumptionTax: "消費税額",
        amountWithholding: "源泉徴収税額",
        shortAmountWithholding: "源泉",
        foreignCurrency: "外貨",
        debitInvoiceCategory: "借方請求明細科目",
        creditInvoiceCategory: "貸方請求明細科目",
        taxCategory: "税区分",
        proposal: "発注稟議",
        purchaseOrderNumber: "発注書番号",
        costAllocation: "費用負担部署",
        departmentInCharge: "取引先担当部署",
        project: "プロジェクト",
        projectName: "プロジェクト名",
        projectDisplayId: "プロジェクトID",
        participant: "参加者",
        genericFieldItems: "汎用マスタ",
        memo: "メモ",
        reportItemMemo: "明細メモ",
        foreignCurrencyAmountIncludeTax: "外貨税込金額",
        foreignCurrencyAmountExcludeTax: "外貨税抜金額",
        quantity: "数量",
        unitPrice: "単価",
      },
      reportLabel: "請求書ラベル",
      createdAt: "作成日",
      completedAt: "完了日",
      aggregationName: "集計名",
      numberOfInvoices: "請求書数",
      totalAmount: "総額",
      totalAmountIncludeTax: "税込金額",
      totalAmountWithholding: "源泉徴収税額",
      paymentAmount: "支払金額",
      unaggregated: "未集計",
      aggregated: "集計済み",
      aggregationStatus: "集計状況",
      payFee: "手数料負担先",
      none: "未指定",
      our: "当方",
      their: "先方",
      feeTarget: "手数料負担先",
      searchScope: "検索範囲",
      inCharge: "自身が担当",
      noCharge: "担当なし",
      nationalTaxDocumentsType: "書類種別",
      nationalTaxDocumentTypeAlias: "書類種別",
      transactedAt: "取引日",
      all: "すべて",
      onlyDeleted: "削除済み",
      onlyMoved: "請求書へ移動済み",
      new: "新規",
      stampedAt: "タイムスタンプ",
      validStampedAt: "有効性",
      stamped: "あり",
      notStamped: "なし",
      valid: "有効",
      invalid: "無効",
      result: "結果",
      message: "メッセージ",
      download: "ダウンロード",
      userDepartment: "所属部署",
      genericFields: {
        dataSet: {
          item: {
            code: "コード",
            name: "名称",
          },
        },
      },
      sendFromAddressTypes: {
        tokium: "TOKIUM",
        system: "システム",
      },
    },
    reports: {
      exports: {
        actions: {
          exportColumns: "この条件で会計データ作成を開始",
        },
      },
      update: {
        images: {
          message: {
            failed:
              "請求書ファイルの保存時にエラーが発生しました。請求書を開き直して、ファイルの再更新を行ってください。エラーが続く場合はサポートにお問い合わせください。",
          },
        },
      },
      searchScope: {
        help: {
          all: "閲覧可能な請求書を全て表示します",
          inCharge:
            "請求書の取引先にご自身や所属部署が指定されている請求書のみを表示します",
          noCharge: "未登録の取引先が指定されている請求書のみを表示します",
          deleted: "削除した請求書も含めて表示します",
        },
      },
      searchForm: {
        exclusionSearch: "除外検索",
      },
      enclosedReport: {
        help: "同封（メール・封筒）されていた請求書・その他資料を確認できます\n\n※メール送付の場合、暗号化ファイルや対象外拡張子のファイルはデータ化対象外です",
        inputting: "自動入力中",
        waiting: "件の請求書が自動入力中です",
      },
      diffWorkerInvoiceInput:
        "請求書の記載情報と下記の入力内容に差分があります",
      hasAlert: "請求書アラートがあります",
      proposals: {
        sameSupplierAndApprovedOnly:
          "この請求書と同じ取引先に紐づいており、かつ承認済みの発注稟議のみ選択可能です。",
        proposalDetail: "稟議詳細",
      },
      reportRelatedDocumentsIndicator: {
        messages: {
          empty: "関連書類はありません。",
          available: "があります。",
          nItems: "{{count}} 件の関連書類",
        },
        actions: {
          edit: "編集する",
        },
      },
      reportItemsIndicator: {
        messages: {
          noItems: "明細がありません。",
          itemsAvailable: "件の明細があります。",
        },
        actions: {
          addItem: "明細を追加する",
          editItem: "明細を編集する",
        },
      },
      reportRelatedDocument: {
        documentTypes: {
          report: "請求書", // payment_request_reportには書類種別がないので、ここでi18nを定義
          empty: "書類種別未設定",
        },
        documentNames: {
          report: "請求書", // payment_request_reportには書類名がないので、ここでi18nを定義
          empty: "書類タイトル未入力",
        },
        labels: {
          enclosed: "同封",
          indexLink: "({{value}}件)",
          indexLinkPopover: {
            noAttached: "関連書類",
            attached: "関連書類が、{{value}}件紐づいています。",
          },
          hasAttached: "紐付けられた関連書類が{{value}}件あります。",
          seeAll: "すべて見る",
          backToRelatedList: "関連書類に戻る",
          searchRelatedDocuments: "関連書類を検索",
        },
        titles: {
          base: "関連書類",
          attached: "関連書類（紐付けのみ）",
          show: "関連書類（詳細）",
        },
        actions: {
          attach: "左の請求書に関連付ける場合はクリック",
          detach: "関連付けを解除する場合はクリック",
          openDetail: "詳細画面を開く",
          loadMore: "さらに読み込む",
          backToRelationIndex: "関連書類に戻る",
        },
        messages: {
          attached: "関連付けを追加しました。",
          detached: "関連付けを解除しました。",
          closed: "閲覧権限がありません",
          noAttachedRelatedDocument:
            "請求書と紐付けられた関連書類はありません。\n紐付けたい場合、検索して紐付けしてください。",
          attachSuccess: "関連書類を紐付けました。",
          detachSuccess: "関連書類の紐付けを解除しました。",
          failedReloadAttachedRelatedCount:
            "紐づけられた関連書類の件数取得に失敗しました。画面をリロードしてください。",
        },
        helps: {
          isNotSameSupplier: "請求書と同じ取引先に紐づいていません。",
        },
        search: {
          titles: {
            searchRelatedDocuments: "関連書類を絞り込む",
            selectDocumentTypeAliases: "書類種別を選択",
          },
          labels: {
            related: "関連書類",
            notRelated: "関連していない書類",
          },
          placeholders: {
            documentName: "検索する書類タイトルを入力",
          },
          actions: {
            expand: "詳しい検索条件を指定",
          },
          helps: {
            hasOtherSupplierInRelated:
              "他の取引先に関連付けされている書類があるため、デフォルトで全ての取引先から検索しています。",
          },
        },
        viewer: {
          titles: {
            selector: "左側に表示する関連書類を選択",
            viewer: "関連書類の画像を表示しています",
          },
        },
        fileViewer: {
          previewArea: "画像プレビューエリア",
          description: "※関連書類を選択すると画像が表示されます",
        },
        modals: {
          requestTypeTargetSelector: {
            title: "関連書類の書類種別を選択",
            labels: {
              report: "請求書（インボイス）",
            },
            actions: {
              clearSelection: "選択をクリア",
              searchPlaceholder: "検索する書類種別名",
            },
          },
        },
      },
      timestampVerify: {
        title: "タイムスタンプ検証",
        searchedTarget:
          "検索条件を満たす全請求書を対象にタイムスタンプ検証を実行します",
        selectedTarget:
          "選択した請求書({{count}}件)を対象にタイムスタンプ検証を実行します",
        sample: "検証結果のサンプルのダウンロードはこちら",
        warn: "タイムスタンプ検証は完了までお時間を要する場合がございます。ご了承ください。",
        showAll: "全ての検証結果を表示(通常では最新5件の結果を表示します)",
        start: "検証を開始",
        started: "請求書のタイムスタンプ検証を開始しました",
        completed: "請求書のタイムスタンプ検証が完了しました",
        inprogress: "請求書のタイムスタンプ検証中です",
        error: "請求書のタイムスタンプ検証に失敗しました",
        unit: "検証済み請求書画像",
      },
      resolutionInformations: {
        link: "スキャナ保存要件/ 解像度：{{satisfied}}",
        caution: "(電子取引であれば問題ありません)",
        title: "解像度情報",
        error: "画像の情報が取得できませんでした",
        pageNumber: "PDFの{{pageNumber}}ページ目：{{satisfied}}",
        resolution: {
          dpi: "解像度：DPI{{dpi}}",
          dpiDifferent: "解像度：DPI{{width}}x{{height}}",
        },
        pixels: "画素数：{{width}}x{{height}}（{{total}}）",
        colorspace: "色階調：{{gradation}}階調",
        digital: "電子取引データ",
        open: "詳細を確認",
        close: "閉じる",
      },
      attachedInvoiceFiles: {
        unsupportedTimestamp:
          "画像（jpg,png,PDF）以外のファイルには、タイムスタンプが付与されません",
      },
      errors: {
        registratedNumber: {
          updateFailed: "登録番号の更新時にエラーが発生しました",
        },
      },
      asInvoice: {
        notice:
          "登録番号が確認できていても、適格請求書の要件を満たしていない場合があります",
      },
      alerts: {
        consumptionTaxDifferenceCheckOnInvoice: {
          message:
            "請求書に記載の消費税額と現在入力されている消費税額に差分があります",
        },
      },
      labels: {
        youInCharge: "ご自身が担当の請求書です",
      },
      pagination: {
        count: "{{order}} / {{total}}",
      },
      pastIndex: {
        words: {
          searchCondition: "検索条件",
          invoice: "請求書",
          reportItemsRelateTo: "に紐づく明細",
          noReportFound:
            "検索結果に該当する請求書が見つかりません。検索条件を変更して再度検索してください",
          totalAmountIncludeTax: "合計金額",
        },
        tabs: {
          searchByInvoice: "請求書から探す",
          favorites: "お気に入り",
        },
        favorites: {
          favoriteLabel: "お気に入りラベル",
          favoriteLabelInfo:
            "よく使う過去明細は★アイコンを押してお気に入りに登録できます。\nお気に入りラベルをつけてさらに探しやすくすることもできます。",
          addLabel: "ラベルを入力できます",
          labelCreateNew: "{{label}} を追加する",
        },
      },
      attachedInvoiceFileBulkDownloadRequests: {
        words: {
          bulkDownload: "請求書証憑の一括ダウンロード",
          gotoFileNameSetting: "証憑ファイル名の設定はこちら",
          nReportsSelected: "件の請求書が選択されています",
          showAllDownloadHistories:
            "全てのダウンロード履歴を表示(通常では最新5件の結果を表示します)",
        },
        messages: {
          description:
            "選択された請求書の証憑を一括ダウンロードします。ダウンロードしたファイルはZIP形式で保存されます。\nZIPに含まれる各証憑のファイル名はシステム設定から設定可能です。",
          needAccountantPrivilegeToConfigure:
            "ファイル名の設定を行うには「集計者」権限が必要です。",
          youNeedSelectReportsToDownload:
            "請求書が選択されていません。証憑を一括ダウンロードしたい請求書にチェックを入れてください。",
          selectedReportExceedsMax:
            "一度に選択できる請求書は{{max}}件までです。{{selected}}件選択されています。",
        },
        actions: {
          startBulkDownload: "選択した請求書に紐づく証憑のZIPファイルを作成",
        },
        fileNameFormat: {
          variables: {
            amount: "金額",
            appropriated: "計上日",
            supplier_name: "取引先名", // eslint-disable-line camelcase
            sequence_number: "申請ID", // eslint-disable-line camelcase
            file_index: "画像の連番", // eslint-disable-line camelcase
          },
          editCard: {
            title: "証憑ファイル名のフォーマット設定",
            messages: {
              description1:
                "一括ダウンロードする証憑のファイル名のフォーマットを設定できます。",
              description21: "半角英数字と一部記号",
              description22: "に加え、以下の特殊フォーマットが使用できます。",
              description3:
                "特殊フォーマットの「申請ID」と「画像の連番」を必ず含める必要があります。\nまた、スペースは使用できません。",
            },
            words: {
              example: "設定例:",
            },
            labels: {
              formatString: "ファイル名フォーマット",
            },
          },
        },
      },
    },
    reportItems: {
      title: {
        lines: "明細",
        linesCount: "（{{value}}件）",
        new: "明細の作成",
        edit: "明細の編集",
      },
      messages: {
        createSuccess: "保存しました。",
        createSuccessSub: "保存した明細は、「最終行」にて確認できます。",
        updateSuccess: "保存しました。",
        copySuccess: "明細を複製しました。",
        copySuccessSub: "複製された明細は、最終行にてご確認できます。",
        selectingCount: "{{count}}件選択中",
        destroySuccess: "明細を削除しました。",
        errors: {
          failedToUploadFile: "ファイルのアップロードに失敗しました",
          failedUpdateForTaxIncluded:
            "税区分の更新に失敗しました。画面を再読み込みしてください。",
        },
        alertHeader:
          "エラーや警告、自動仕訳された明細が存在する場合、この列にアイコン表示されます。",
        warnings: {
          hasAlert: "チェックレベル「警告表示」の請求書アラートが存在します。",
        },
      },
      labels: {
        addDepartment: "部署追加",
        costAllocationDepartmentCriteria: "費用負担部署の基準",
        deleteAll: "全て削除",
        costAllocationValue: {
          amount: "{{name}}={{value}}",
          percentage: "{{name}}={{value}}%",
        },
        valueTypes: {
          inputAsPercentage: "割合",
          inputAsAmount: "金額",
        },
        amountIncludeTax: "金額（税込）",
        amountExcludeTax: "金額（税抜）",
        cost: "費用",
        reminingAmount: "残 {{value}}",
        tableSetting: "表の設定",
        amountComsumptionTax: "{{comsumptionTax}}",
        targetAmount: "（対象金額：{{excludeTax}}）",
        lineDuplicate: "行を複製",
        lineDestroy: "行を削除",
        lineActionHelp: "行の複製・削除",
        participant: {
          colleague: "自社{{value}}人",
          client: "取引先{{value}}人",
        },
      },
    },
    reportTemplates: {
      name: "テンプレート名",
      autoCreation: "自動仕訳",
      autoInput: "自動入力",
      supplierSettings: "取引先設定",
      workerInput: "自動入力内容を優先",
      default: "デフォルト",
      useReportItemAutoCreation: "自動仕訳設定",
      carryoverAmountConfig: "繰越額設定",
      useCarryoverAmount: "繰越額を利用する",
      relativeMonthOption: "登録日の{{relativeMonth}}の{{day}}日",
      // eslint-disable-next-line camelcase
      relativeMonthOption_end: "登録日の{{relativeMonth}}の末日",
      actions: {
        addReportTemplate: "請求書テンプレートを追加",
      },
      help: {
        default:
          "取引先の請求書テンプレートが設定されていない、または取引先が指定されていない場合、デフォルトの請求書テンプレートが適用されます。",
        useCarryoverAmount:
          "利用する：繰越額も請求書明細に反映されます\n利用しない：繰越額は請求書明細に反映されません",
      },
      form: {
        labels: {
          description: "請求書の登録時に適用されるテンプレートを設定できます。",
        },
        paymentSettingForm: {
          workerInputPriority: {
            supplierNamePriority: "取引先名",
            paymentInformationPriority: "支払情報",
            bankAccountHolderPriority: "名義人",
            payFeePriority: "手数料負担先",
          },
          descriptions: {
            paymentInformation:
              "支払先を特定する情報（支払方法、金融機関名、支店名、口座種類、口座番号）です",
          },
          relativeMonth: {
            "-1": "前月",
            0: "当月",
            1: "翌月",
            2: "翌々月",
            3: "{{differenceMonth}}ヶ月後",
          },
          days: "{{day}}日",
          endOfMonth: "末日",
          registeredAtFrom: "登録日の",
          of: "の",
          calculatedDay: "(本日登録した場合: {{- calculatedDay}})",
          labels: {
            supplierDescription:
              "請求書を自動入力で登録する際、項目毎に入力情報を指定できます。",
            dueAtPriorityLabel:
              "請求書作成時に{{columnName}}が空の場合のみ、初期値を入力する",
            dueAtPriorityDescription: `{{columnName}}が自動入力された場合、
            その値が優先的に入力されます。`,
            dueAtNotSelected:
              "(未選択の場合は、請求書に記載の支払期日がセットされます)",
          },
        },
        reportItemsForm: {
          labels: {
            description:
              "請求書を自動入力で登録する際、直近に更新された同じ取引先の請求書から明細を自動作成します。",
          },
        },
        etcForm: {
          help: {
            memo: "自動入力の値が存在する場合、テンプレートの値は無視されます。自動入力の値が空の場合、テンプレートの値が適用されます。",
          },
        },
      },
      modal: {
        delete: {
          hasSupplierAlert:
            "取引先に設定されている請求書テンプレートです。削除してもよろしいですか？",
        },
      },
    },
    reportTemplateSelector: {
      titles: "請求書テンプレートを選択",
      newButton: "テンプレートの新規作成はこちら",
    },
    invoiceSettings: {
      labels: {
        title: "請求書ラベル一覧",
        description:
          "[請求書詳細画面 > その他タブ]で設定可能なラベルを登録できます (登録数最大20件)",
        table: {
          headers: {
            label: "ラベル",
          },
        },
        modal: {
          title: {
            createLabel: "請求書ラベルを追加",
            editLabel: "請求書ラベルを編集",
          },
          name: "名前",
          selectLabelColor: "ラベルの色を選択",
        },
        messages: {
          success: {
            create: "請求書ラベルを作成しました",
            update: "請求書ラベルを更新しました",
            delete: "請求書ラベルを削除しました",
          },
        },
      },
    },
    proposals: {
      sequenceNumber: "申請ID",
      title: "稟議名",
      content: "詳細",
      supplierAuthorityUsers: "取引先担当者(複数選択可)",
      supplierAuthorityGroups: "取引先担当部署(複数選択可)",
      debitInvoiceCategories: "借方請求明細科目(複数選択可)",
      costAllocationDepartments: "費用負担部署(複数選択可)",
      projects: "プロジェクト(複数選択可)",
      buttons: {
        uploaderInformation: "送付先を確認",
        usageStatus: "消化状況を確認",
      },
      modals: {
        totalBudget: "稟議金額",
        totalUsed: "消化済み金額",
        reportSequenceNumber: "請求書",
        titles: {
          uploaderInformation: "請求書の送付先を確認",
          usageStatus: "消化状況",
        },
      },
      alerts: {
        supplierWillBeSavedWhenApproved:
          "現在設定されている取引先は未登録ですが、最終承認時に登録されます。",
        checkSendingInformation:
          "「送付先を確認」ボタンより、請求書の送付先をご確認ください。",
      },
      messages: {
        noData: "稟議書が見つかりませんでした。",
      },
    },
    invoiceTable: {
      alert: "アラート",
      memo: "メモ",
      invoiceMemo: "請求書メモ",
      storageNumber: "紙原本管理番号",
      createdByForeignCurrency: "外貨を利用した請求書です",
      notApprovedYet: "承認されていない請求書です",
      inCharge: "担当",
      bankName: "金融機関名",
      sender: "送付元",
      sendFromAddress: "送付元メールアドレス",
      consolidatedReport: "一括支払依頼申請ID",
      supplierCode: "取引先コード",
      supplierName: "取引先名",
      costAllocatedDepartment: "費用負担部署",
      amount: "金額",
      asInvoice: "適格請求書",
      purchaseOrderNumber: "発注書番号",
      reportLabel: "請求書ラベル",
      registeredAt: "登録日時",
    },
    aggregationTable: {
      invoiceId: "請求書ID",
      aggregateSelected: "選択した{{count}}件の請求書を集計する",
      images: "画像等",
    },
    proposalTable: {
      title: "稟議一覧",
      redirectToNew: "発注稟議を作成する",
      showExportModal: "この条件でファイル出力する",
      searchForm: {
        labels: {
          title: "稟議名",
          supplierName: "取引先名",
          supplierCode: "取引先コード",
          costAllocationDepartment: "費用負担部署",
          projectDisplayId: "プロジェクトID",
          projectName: "プロジェクト名",
          memo: "メモ",
          blockedStates: "有効状態",
          usedStates: "消化状況",
          statuses: "ステータス",
        },
        values: {
          blockedStates: {
            yes: "無効",
            no: "有効",
          },
          usedStates: {
            no: "未使用",
          },
        },
      },
      exportModal: {
        createFile: "最新の出力ファイル作成を開始",
        messages: {
          exportSize: "出力可能なデータ件数は最大 {{size}} 件までです。",
        },
      },
      properties: {
        title: "稟議名",
        totalBudget: "金額",
        status: "ステータス",
        usageStatus: "消化状況",
        content: "内容",
        supplierCode: "取引先コード",
        supplierName: "取引先名",
        costAllocationDepartmentNames: "費用負担部署",
        projectNames: "プロジェクト",
        memo: "メモ",
        sequenceNumber: "申請ID",
      },
      buttons: {
        block: "無効",
        unblock: "有効",
      },
      messages: {
        confirm: {
          block: "本当に無効化しますか？",
          unblock: "本当に有効化しますか？",
        },
        success: {
          blocked: "データを無効化しました",
          unblocked: "データを有効化しました",
        },
      },
    },
    nationalTaxDocument: {
      properties: {
        name: "書類タイトル",
        createdAt: "登録日",
        sequenceNumber: "書類ID",
        amountIncludeTax: "取引金額",
        foreignCurrencyAmountIncludeTax: "外貨金額",
        label: "ラベル",
        registratedNumber: "登録番号",
        image: "画像",
        dedicatedinputtingNotice: "自動入力中",
        dataTarget: {
          label: "検索対象",
          taxDocument: "国税関係書類",
          attachedFile: "添付資料",
        },
        relatedAttachedFiles: "添付資料",
        sendFromAddress: "送付元メールアドレス",
      },
      actions: {
        type: {
          searchPlaceholder: "検索する書類カテゴリ名称",
        },
        typeAlias: {
          searchPlaceholder: "検索する書類種別名",
          clearSelection: "選択をクリア",
        },
        register: {
          manual: "手入力",
          bulkUpload: "一括登録",
        },
      },
      titles: {
        selectTaxDocumentType: "書類カテゴリを選択する",
        registerTaxDocument: "国税関係書類を登録する",
        exportColumns: "この条件でファイル出力する",
      },
      messages: {
        documentCreated: "{{documentName}}を登録しました",
        documentUpdated: "更新しました",
        documentOfBeingInCharge: "ご自身が担当の書類です",
        deleteFailed: "書類の削除に失敗しました",
        mustInputTransactedAtWhenUseForeignCurrency:
          "外貨を入力するためには、取引日を入力する必要があります",
        registratedNumberUpdateFailed: "登録番号の更新に失敗しました",
        exportSize: "出力可能なデータ件数は最大 {{size}} 件までです。",
      },
      modals: {
        createConfirmationModal: {
          title: "警告",
          content:
            "国税関係書類は登録後に削除ができません。\n登録内容が正しいことをご確認の上、データを保存してください。",
        },
        nationalTaxDocumentEditModal: {
          actions: {
            displayLargely: "変更履歴を確認",
            cancelDedicatedInputting: "手入力に切り替える",
            workerInputCorrectionRequest: "自動入力修正依頼",
          },
          confirmBody:
            "実行すると自動入力に戻すことはできません。よろしいですか？",
        },
        moveConfirmationModal: {
          title: "請求書一覧に移す",
          contentHeader:
            "実行後、国税関係書類一覧に表示されなくなります。元に戻せません。",
          contentMain: {
            moveByWorkerInput:
              "自動入力を利用し、選択書類を請求書一覧に移します。",
            mustNotManipulateUntilWorkerInputCompleted:
              "※自動入力が完了するまで操作できなくなります",
            moveByManual:
              "現在の登録情報を利用し、移す場合は手入力を選択してください。",
            moveByManualNotImage:
              "現在の登録情報を利用し、選択書類を請求書一覧に移します。",
            workerInputMimetype:
              "※自動入力は、pdf, jpg, pngでのみ利用可能です。",
          },
          actions: {
            moveToReportWorkerInput: "自動入力",
            moveToReport: "手入力",
          },
          message: {
            moveToReportWorkerInputCompleted: "自動入力への処理が完了しました",
            moveToReportCompleted: "請求書への変更が完了しました",
            returnedInvalidResponse:
              "請求書への手動移行処理で不正な結果が返ってきました",
          },
        },
        exportModal: {
          actions: {
            exportColumns: "最新の出力ファイル作成を開始",
          },
        },
        multiImageUpload: {
          title: "一括登録",
          uploadCompleted: "登録完了 成功 {{success}}件, エラー {{error}}件",
          uploadInProgress: "登録中 {{uploaded}}/{{total}}...",
          upload: "登録開始",
          cancel: "キャンセル",
          close: "閉じる",
          selectPlaceholder:
            "クリックして画像・PDFを選択\nまたは\n画像・PDFをドラッグアンドドロップ",
          fileLimit: "{{count}}/300",
          emptyImages: "ファイルが選択されていません",
          errors: {
            unacceptableMIMEType:
              "{{files}}は利用不可能なファイル形式のためアップロードできません",
            tooManyFiles:
              "ファイル数が多すぎます。{{count}}個以下にして選択し直してください。",
            uploadCanceled: "登録がキャンセルされました。",
            tooLargeFile:
              "ファイルサイズが大きすぎます。5GB以下にして選択し直してください。",
            systemError:
              "システムエラーが発生しました。お手数ですが、サポートにお問い合わせください。",
          },
          form: {
            useDedicatedInput: "自動入力する（OCR）",
            useOperatorInput: "自動入力する（オペレーター）",
            sourceTypeHelp:
              "選択に応じて、書類に記載されている以下の情報を「取引先名」として読み取ります。\n・受領：その書類の発行者名\n・発行：その書類の受領者名",
            collapse: "取引日, 取引金額を入力",
            automaticInputTargetPlaceholder: "（自動入力）",
          },
          ocrExtensionWarning:
            "画像（jpg,png,PDF）以外のファイルは、データ化はせず登録のみ行います",
        },
      },
      request: {
        moveToReport: "請求書一覧に移す",
      },
      timestampVerify: {
        title: "タイムスタンプ検証",
        searchedTarget:
          "検索条件を満たす全国税関連書類を対象にタイムスタンプ検証を実行します",
        selectedTarget:
          "選択した国税関連書類({{count}}件)を対象にタイムスタンプ検証を実行します",
        sample: "検証結果のサンプルのダウンロードはこちら",
        warn: "タイムスタンプ検証は完了までお時間を要する場合がございます。ご了承ください。",
        showAll: "全ての検証結果を表示(通常では最新5件の結果を表示します)",
        start: "検証を開始",
        started: "国税関連書類のタイムスタンプ検証を開始しました",
        completed: "国税関連書類のタイムスタンプ検証が完了しました",
        inprogress: "国税関連書類のタイムスタンプ検証中です",
        error: "国税関連書類のタイムスタンプ検証に失敗しました",
        unit: "検証済み国税関連書類画像",
        timeout:
          "国税関連書類のタイムスタンプ検証に失敗しました\n検証対象を減らし、もう一度お試しください",
      },
      searchForm: {
        exclusionSearch: "除外検索",
        help: {
          dataTarget: {
            attachedFile:
              "明細書や工数内訳など、取引先が添付資料としてアップロードした書類を表示します",
          },
        },
      },
      relatedAttachedFileSourceLink: {
        label1: "（ID ",
        label2: " の添付資料）",
      },
    },
    history: {
      common: {
        add: "追加",
        edit: "更新",
        delete: "削除",
        move: "移動",
      },
      nameFormats: {
        byAgent: "({{name}}が代理)",
        viaApi: "API",
      },
      noInput: "未入力",
      report: {
        title: "変更履歴",
        byUser: "変更者",
        historyType: "変更概要",
        createdAt: "変更日時",
        changes: "変更内容",
        edit: "更新",
        delete: "削除",
        create: "追加",
        move: "移動",
        moveEdit: "編集(移動による自動編集)",
        moveWorkerEdit: "編集(自動入力による編集)",
        /* eslint-disable camelcase */
        attached_invoice_files: "請求書画像",
        payment_method_id: "支払方法",
        due_at: "支払期日",
        schedule_at: "支払希望日",
        appropriated_at: "計上日",
        pay_fee: "手数料負担先",
        method_type: "支払方法",
        supplier_name: "取引先名",
        bank_name: "金融機関名",
        bank_branch_name: "支店名",
        bank_account_type: "口座種類",
        bank_account_number: "口座番号",
        bank_account_holder_kana: "名義人",
        total_amount: "金額",
        registrated_number: "登録番号",
        as_invoice: "適格請求書として扱う",
        paymentMethodType: {
          transfer: "振込",
          debit: "口座引落",
          cash: "現金",
          // eslint-disable-next-line camelcase
          payment_slip: "納付書",
          card: "カード",
          bill: "手形",
          check: "小切手",
          // eslint-disable-next-line camelcase
          electronically_recorded_monetary_claims: "でんさい",
          // eslint-disable-next-line camelcase
          overseas_remittance: "海外送金",
          other: "その他",
        },
        ordinal: "普通",
        current: "当座",
        saving: "貯蓄",
        other: "その他",
        their: "先方",
        our: "当方",
        report_number: "請求書番号",
        received_at: "受領日",
        memo: "請求書メモ",
        tax_included: "税込/税抜入力",
        document_type: "",
        otherSupplier: "別取引先",
        null: "",
        "": "",
        /* eslint-enable camelcase */
      },
      reportItemTable: {
        edit: "更新",
        create: "追加",
        delete: "削除",
        // eslint-disable-next-line camelcase
        move_edit: "編集(移動による自動編集)",
      },
      reportItem: {
        edit: "明細更新",
        create: "明細追加",
        delete: "明細削除",
        /* eslint-disable camelcase */
        move_edit: "明細編集(移動による自動編集)",
        debit_invoice_category: "借方請求明細科目",
        tax_category: "税区分",
        project: "プロジェクト",
        credit_invoice_category: "貸方請求明細科目",
        name: "明細名",
        amount_include_tax: "税込金額",
        amount_exclude_tax: "税抜金額",
        unit_price: "単価",
        quantity: "数量",
        amount_consumption_tax: "消費税額",
        amount_withholding: "源泉徴収税額",
        foreign_currency_id: "外貨",
        foreign_currency_amount_include_tax: "外貨税込金額",
        foreign_currency_amount_exclude_tax: "外貨税抜金額",
        exchange_rate: "外貨レート",
        purchase_order_number: "発注書番号",
        memo: "明細メモ",
        cost_allocation: "費用負担部署",
        participant: "参加者",
        colleague: "自社",
        client: "取引先",
        payment_request_proposal_sequence_number: "発注稟議",
        null: "",
        "": "",
        /* eslint-enable camelcase */
      },
      reportLabel: {
        create: "ラベル追加",
        delete: "ラベル削除",
      },
      nationalTaxDocumentLabel: {
        create: "ラベル追加",
        delete: "ラベル削除",
      },
      proposal: {
        title: "変更履歴",
        ownFields: {
          /* eslint-disable camelcase */
          title: "稟議名",
          content: "詳細",
          total_budget: "金額",
          foreign_currency_id: "外貨種別",
          foreign_currency_total_budget: "外貨金額",
          exchange_rate: "為替レート",
          memo: "メモ",
          is_valid: "有効",
          payment_request_supplier: "取引先",
          payment_request_payment_method_supplier_name: "取引先名",
          "": "",
          /* eslint-enable camelcase */
        },
        attachedFiles: {
          /* eslint-disable camelcase */
          attached_file: "画像",
          "": "",
          /* eslint-enable camelcase */
        },
        projects: {
          /* eslint-disable camelcase */
          project_id: "プロジェクト",
          "": "",
          /* eslint-enable camelcase */
        },
        debitInvoiceCategories: {
          /* eslint-disable camelcase */
          payment_request_debit_invoice_category_id: "借方請求明細科目",
          "": "",
          /* eslint-enable camelcase */
        },
        costAllocationDepartments: {
          /* eslint-disable camelcase */
          cost_allocation_department_id: "費用負担部署",
          "": "",
          /* eslint-enable camelcase */
        },
        supplier: {
          new: "(新規取引先)",
        },
      },
      nationalTaxDocument: {
        title: "変更履歴",
        ownFields: {
          /* eslint-disable camelcase */
          type: "書類種別",
          type_alias_id: "書類種別",
          transacted_at: "取引日",
          amount_include_tax: "取引金額",
          supplier_name: "取引先名",
          memo: "メモ",
          name: "書類タイトル",
          document_type: "",
          foreign_currency_id: "外貨",
          foreign_currency_amount_include_tax: "外貨税込金額",
          exchange_rate: "外貨レート",
          registrated_number: "登録番号",
          department: "所属部署",
          project_id: "プロジェクト",
          "": "",
          /* eslint-enable camelcase */
        },
        attachedFiles: {
          /* eslint-disable camelcase */
          attached_file: "証憑データ",
          "": "",
          /* eslint-enable camelcase */
        },
        documentTypes: {
          deleted: "(削除済み)",
        },
        documentTypeAliases: {
          deleted: "(削除済み)",
        },
        projects: {
          deleted: "(削除済み)",
        },
      },
    },
    reportAlerts: {
      ruleList: "請求書アラートルール一覧",
      levels: {
        ignore: "オフ",
        warning: "警告表示",
        cannotRequest: "申請不可",
        cannotApprove: "承認不可",
      },
    },
    request: {
      prepareRequest: "申請情報を入力",
      checkContents: "申請内容を確認",
      title: "申請名",
      department: "所属部署",
      amount: "総額",
      comment: "コメント",
      approvalFlow: "承認フロー",
      submit: "申請を提出する",
      sendRequest: "申請する",
      created: "申請しました",
      approve: "承認する",
      approved: "承認しました",
      cancelApprove: "承認を取り消す",
      cancelApproved: "承認を取り消しました",
      reject: "差し戻す",
      rejectTo: "差し戻し先",
      rejected: "差し戻しました",
      recall: "取り下げる",
      recalled: "申請を取り下げました",
      resubmit: "再申請する",
      resubmitted: "再申請しました",
      changeApprovalFlow: "フローを再設定して申請",
      changeApprovalFlowShort: "フローを再設定する",
      useCurrentApprovalFlow: "前回と同じフローで申請",
      applyNewApprovalFlow: "新しい申請フローで申請",
      reapplyingModal: "前回と同じ承認フローで申請しますか？",
      delete: {
        confirm: {
          title: "申請の削除",
          description: "申請を削除し、請求書を「未申請」の状態に戻します。",
        },
      },
      bulk: {
        requested: "請求書を申請しました",
        sequenceNumbersLabel: "申請ID：",
        sendRequests: "申請する",
        checkContents: "申請内容（{{count}}件）",
        includeReports: "含まれる請求書一覧",
        editRequest: {
          name: "申請名",
          approvalFlow: "申請フロー",
          totalAmount: "総額",
          priceWithCount: "{{price}}（{{count}}件）",
          checkIncludeReports: "含まれる請求書を確認する",
        },
        reapplying: {
          title: "承認フローの設定方法を選択",
          current: {
            label: "「自動」の場合",
            description:
              "過去に申請したことのある支払依頼が、前回使用した承認フローで再提出されます。\n新規の支払依頼の場合、承認フローを手動で確認・編集します。",
            action: "自動",
          },
          new: {
            label: "「手動」の場合",
            description: "すべての支払依頼の承認フローを確認・編集します。",
            action: "手動",
          },
        },
      },
      approverSelect: {
        title: "承認者を追加",
        description:
          "承認ステップの追加が可能な申請です。\n追加しない場合は最終承認となります。",
        addStep: "次の承認ステップを追加",
        noStep: "承認ステップを追加しない",
      },
      consolidated: {
        requested: "請求書を一括支払依頼申請しました",
        sendRequests: "一括支払依頼申請する",
      },
    },
    preferences: {
      featureForRP:
        "こちらの機能はTOKIUM経費精算のみの機能です。TOKIUMインボイスには適用されませんのでご注意ください。",
      properties: {
        proposalContentFormat: "詳細欄テンプレート",
      },
      account: {
        documentDestination: "書類送付先",
        mailAddress: "メールアドレス",
        description:
          "請求書等の書類を添付・送信することで、自動入力が行われます。\n尚、システムへの送信用となるため、お問い合わせ等の返信はできません。",
      },
      approval: {
        approvalSetting: "承認設定 (支払依頼申請)",
        bulkApprove: "一括承認を使用できるようにする",
        bulkApproveCaution:
          "一括承認は支払依頼申請のみの機能です。経費申請、事前申請、汎用申請、稟議申請には適用されませんのでご注意ください。",
        bulkApproveDesc: "承認者が複数の申請をまとめて承認できるようになります",
        bulkApproveChanged: "一括承認設定を更新しました",
      },
      distributionConditions: {
        title: "テナント共通の書類振分設定",
        description:
          "取引先ごとの個別設定を設定している場合、そちらが優先されます。（テナント共通の振分設定登録数最大10件）",
        headers: {
          priority: "優先度",
          documentClass: "振分先",
          conditions: "書類振分条件 (1つの優先度につき登録条件数最大20件)",
          status: "ステータス",
        },
        actions: {
          addCondition: "テナント共通の書類振分設定を追加する",
          enable: "有効",
          disable: "無効",
        },
        modal: {
          title: "テナント共通の書類振分設定",
          labels: {
            conditions: "書類振分条件",
            apply: "設定の適用（有効化）",
            priority: "優先度",
            memo: "メモ",
            enabled: "有効",
            disabled: "無効",
            documentClass: "振分先",
          },
          placeholder: {
            tag: "Enterで確定",
          },
          actions: {
            save: "保存",
            delete: "削除",
          },
        },
        changed: "書類振分を更新しました",
        noConditions: "登録したどの条件にも一致しない場合",
      },
      distributionConditionsBySupplier: {
        title: "取引先ごとの書類振分設定",
        description:
          "取引先ごとに登録した文字列を含む書類が届いた場合に指定された振り分けを行います。（適用対象取引先ごとの優先度最大10件）",
        headers: {
          priority: "優先度",
          documentClass: "振分先",
          suppliers: "適用対象取引先",
          conditions: "書類振分条件",
          status: "ステータス",
        },
        actions: {
          addCondition: "取引先ごとの書類振分設定を追加する",
          expandSuppliers: "全て表示({{count}}件)",
          collapseSuppliers: "閉じる",
          enable: "有効",
          disable: "無効",
          edit: "編集",
          replicate: "複製",
          destroy: "削除",
        },
        modal: {
          title: "取引先ごとの書類振分設定",
          labels: {
            suppliers: "適用対象取引先",
            conditions: "書類振分条件",
            apply: "設定の適用（有効化）",
            priority: "優先度",
            memo: "メモ",
            enabled: "有効",
            disabled: "無効",
          },
          placeholder: {
            tag: "Enterで確定",
          },
          actions: {
            save: "保存",
            editSuppliers: "取引先リストを選択",
          },
        },
        noConditions: "登録したどの条件にも一致しない場合",
      },
      notifications: {
        invoice: "請求書に関する通知",
        onWorkerInputCompleted: "請求書の自動入力完了時",
        onChangeReportSupplier: "請求書の取引先変更時",
        onChangeClosingDate: "申請期限が近い未申請の請求書がある時",
        onChangeClosingDateDescription: "申請期限や通知日は管理者が設定します",
        nationalTaxDocument: "国税関係書類に関する通知",
        onNationalTaxDocumentWorkerInputCompleted:
          "国税関係書類の自動入力完了時",
      },
      reports: {
        titles: {
          proposalContentFormat: "稟議申請詳細欄テンプレート",
        },
        descriptions: {
          proposalContentFormat:
            "稟議申請入力時に、あらかじめ入力される詳細欄を設定できます。",
        },
      },
      consumptionTaxInput: {
        title: "消費税入力設定",
        descriptions: {
          useTaxExcluded:
            "有効化による変更点\n・請求書明細を税込税抜どちらで入力するか選択可能になります\n・請求書明細に入力した消費税額が会計データに出力されます",
          useTaxExcludedAsDefault:
            "有効にすると請求書作成時にデフォルトで税抜入力になります。",
        },
        useTaxExcluded: "税込/税抜入力を選択可能にする",
        useTaxExcludedAsDefault: "税抜入力をデフォルトとする",
      },
      reportEditSetting: {
        title: "取引先編集設定",
        labels: {
          enableSupplierEdit: "請求書編集時に取引先情報を編集可能にする",
        },
      },
      eligibleInvoiceSetting: {
        title: "適格請求書に関する設定",
        labels: {
          useRegistratedNumberAutocompleteToSupplier:
            "取引先マスタへの登録番号自動補完",
        },
        descriptions: {
          useRegistratedNumberAutocompleteToSupplier:
            "申請時または承認時に請求書の登録番号を取引先マスタに自動補完します。\n※ 取引先の登録番号がブランクの場合に限ります。\n※ 支払依頼申請がOFFの場合、請求書を承認したタイミングで自動補完が行われます。",
        },
      },
      purchaseRequisitionNumber: {
        title: "明細項目設定",
        enable: "発注書番号",
        description:
          "請求書の明細に、発注書番号の入力欄を追加します。\n発注書番号は任意入力項目です。",
      },
      resolutionInformation: {
        report: {
          enable: "請求書",
        },
        nationalTaxDocument: {
          enable: "国税関係書類",
        },
      },
      alertRules: {
        reportDifferenceSetting: "請求書記載情報差分アラート設定",
        supplierDifferenceSetting: "取引先マスタ差分アラート設定",
        keys: {
          supplierName: "取引先名",
          dueAt: "支払期日",
          paymentMethodType: "支払方法",
          bankName: "金融機関名",
          bankBranchName: "支店名",
          bankAccountType: "口座種別",
          bankAccountHolderKana: "名義人",
          bankAccountNumber: "口座番号",
          payFee: "手数料負担先",
        },
        actions: {
          enable: "アラートを出す",
          disable: "無視する",
        },
      },
    },
    analysisDetail: {
      aggregationDetail: {
        title: "集計詳細",
        name: "名前",
        creationDate: "作成日",
        amount: "金額",
        totalTransferAmount: "振込金額",
        totalAmountIncludingWithholding: "合計金額",
        totalWithholding: "源泉徴収税額",
        numberOfInvoices: "請求書数",
      },
      buttons: {
        exportZenginData: "全銀データ・会計データ出力",
        handleAsPaid: "支払処理済みにする",
        paid: "支払処理済み",
        updateToExported: "会計データ出力済みにする",
        exported: "会計データ出力済み",
      },
      messages: {
        confirmProceed: "本当に実行しますか？",
        unableToProceedUnapproved:
          "未承認の請求書が含まれているため支払済みにできません",
      },
      aggregationPerPayee: {
        titles: {
          transfer: "支払先毎集計(振込)",
          transferHelp:
            "「振込金額≦0円」の支払先がある場合、全銀データ出力はエラーになります。該当する支払依頼申請をご確認ください",
          other: "支払先毎集計(その他)",
        },
        tableHeaders: {
          bankName: "金融機関名",
          branchName: "支店名",
          bankAccountNumber: "口座番号",
          accountHolder: "名義人",
          accountType: "口座種別",
        },
        memberTable: {
          account: "口座",
        },
      },
    },
    exports: {
      modalTitle: "ファイル出力",
      startCreatingLatestAccountingData: "最新の会計データ作成を開始",
      zengin: {
        tabTitle: "全銀データ出力",
        paymentAccount: "支払口座",
        paymentDate: "支払指定日",
        placeHolder: "支払指定日を入力してください",
        transferFeeHelp:
          "※本画面で選択した手数料負担先(※1)と、請求書に設定されている手数料負担先(※2)を参照し、振込金額が自動計算されます。",
        payFeeHelp:
          "全銀データ取込時のオンラインバンキングの設定を選択してください。請求書ごとの手数料負担先に応じて振込金額が自動計算されます。",
        table: {
          calculatedAnswer: "振込金額の自動計算結果",
          noChange: "変更なし",
          amountPlusFee: "合計金額 + 振込手数料",
          amountMinusFee: "合計金額 - 振込手数料",
        },
      },
      formatted: {
        tabTitle: "会計データ出力",
        exportFormat: "出力形式",
        specifiedDate: "取引日付の一括指定日",
      },
      messages: {
        exportSize: "出力可能なデータ件数は最大 {{size}} 件までです。",
      },
    },
    invoiceDetail: {
      title: "請求書（詳細）",
      comment: "コメント",
      paymentAmount: "支払金額",
      totalAmount: "合計金額",
      close: "閉じる",
      percentAmount: "{{number}}%金額",
      amountOfWithholdings: "源泉徴収額",
      paymentSettings: "支払設定",
      displayLargely: "変更履歴",
      workerInputCorrectionRequest: {
        short: "自動入力修正依頼",
        long: "自動入力データの修正依頼",
      },
      approve: "承認する",
      unapprove: "未承認に戻す",
      noImageAttached: "画像添付なし",
      diffWorkerInputAmount: "支払金額の値が、自動入力から変更されています",
      uploadSizeAnnotationTitle: "ファイルをアップロード",
      uploadSizeAnnotationDetail: "※ 最大10ファイルまで",
      detailAmount: "内訳",
      summaryAmount: "要約",
      overview: {
        paymentInformation: "支払情報",
        supplementaryInformation: "補足情報",
        asInvoiceSetting: "適格請求書（インボイス）の設定",
      },
      info: {
        supplier: "取引先：",
        supplierInCharge: "取引先担当：",
        status: "ステータス：",
        sequenceNumber: "申請ID：",
        registeredAt: "登録日時：",
        sendFrom: "送付情報：",
      },
      sendInfoPopup: {
        sendFromInfo: "送付情報",
        sendMeans: "送付方法",
        sendFrom: "送付元",
      },
      supplierInChargePopup: {
        title: "取引先担当",
        userInCharge: "担当者",
        groupInCharge: "担当部署",
        noInCharge: "なし",
      },
      amountDiffAlert: {
        alert: "支払金額の値が、自動入力の値から変更されています。",
        workerInputAmount: "自動入力の値＝{{ value }}",
      },
      resolution: {
        label: "スキャン解像度",
        notSatisfied:
          "スキャン解像度がNGでも、電子取引（※）であれば問題ありません。\n※メールやインターネットを介してやり取りした取引",
        imageAcquisitionFailure: "画像の情報が取得できません。",
      },
      timeStamps: {
        label: "タイムスタンプ情報",
        unsupportedTimestamp:
          "画像（jpg,png,PDF）以外のファイルには、タイムスタンプが付与されません。",
        failed: {
          headline: "タイムスタンプの付与に失敗しました。",
          exampleTitle: "該当ケースの例",
          example1: "添付ファイルにパスワードが設定されている",
          example2: "添付PDFファイルに署名が付与されている",
          example3: "添付ファイルのサイズが巨大（数百MBなど）",
          link1: "※詳しくは",
          link2: "の記事をご参照ください",
        },
        inprogress: {
          desciption1: "タイムスタンプ付与中です。",
          desciption2: "しばらくお待ちください。",
        },
      },
      timeline: {
        progress: "承認状況",
        latestComment: "直近コメント",
        otherApprovers: "、ほか{{count}}名",
        otherApproversIncludedDepartments: "、ほか{{count}}",
        seeAll: "すべてを見る",
        unapproved: "未承認",
        conditionAll: "全員",
        conditionAny: "いずれか1人",
        enterComment: "コメントを入力",
        separator: "、",
        userName: "（{{userName}}）",
        userNameWithAgentName: "（{{userName}}（{{agentName}}が代理））",
        applicantNameWithAgentName: "{{userName}}（{{agentName}}が代理）",
      },
      alert: {
        hasCheckItems: "チェック項目あり",
        item: "項目",
        duplicateAlert:
          "同じ内容の取引先が、既に取引先マスタに存在しています。更新すると、2重登録されます。\n回避する場合、項目「取引先」から「選択」をクリックした一覧（取引先マスタ）から設定してください。",
        helps: {
          help1:
            "「システム設定」＞「アラート」画面にて、設定されたチェックレベルに該当するアラートを確認できます。",
          help2:
            "チェックアラート横の「確認する」ボタンを選択すると、差分情報などを更新することができ、アラートを解消することができます。",
          help3:
            "※「確認」ボタンが存在しないアラートは案内に従いご対応お願いします",
        },
        error: {
          title: "チェックレベル「申請不可」の請求書アラートが存在します。",
          description1: "このままでは次のステップに進めません。",
          description2: "内容をご確認の上、ご対応お願いします。",
        },
        warning: {
          title: "チェックレベル「警告表示」の請求書アラートが存在します。",
          description1: "このままでも次のステップに進めますが、",
          description2: "内容をご確認の上、ご対応お願いします。",
        },
        blockedSupplier: {
          title: "凍結された取引先に紐付いています。",
          message:
            "凍結されていない取引先を選択するか、管理者へご連絡ください。",
        },
        differentWorkerInvoiceInput: {
          title:
            "「入力された請求書の内容」と「データ化された請求書の内容」に差分があります。",
          message: "確認画面にて差分更新ができます。",
        },
        unattachedToOfficialSupplier: {
          title: "未登録の取引先が入力されています。",
          message: "確認画面にて未登録の取引先を登録できます。",
          successMessage: "取引先マスタを登録しました。",
        },
        attachedToUnofficialPaymentMethod: {
          title: "取引先の登録情報と入力内容に差分があります。",
          message: "確認画面にて差分更新ができます。",
          successMessage: "取引先マスタを更新しました。",
        },
        workerInvoiceInputPreviewModal: {
          title: "確認（請求書の内容）",
          description:
            "「入力された請求書の内容」から、「データ化された請求書の内容」に更新できます。",
          beforeChange: "入力された請求書の内容",
          afterChange: "データ化された請求書の内容",
        },
        unofficialPaymentMethodPreviewModal: {
          title: "確認（取引先の登録内容）",
          description: "取引先の登録情報を現在の入力内容で更新できます。",
          beforeChange: "追加済みの取引先の内容",
          afterChange: "入力された取引先の内容",
        },
        unattachedToOfficialSupplierPreviewModal: {
          title: "確認（取引先）",
          description: "請求書の入力内容で、取引先を登録する。",
          afterChange: "請求書の入力内容",
        },
      },
      actions: {
        update: "更新する",
        register: "登録する",
      },
      calendar: {
        dateSelection: "日付選択",
        today: "本日",
        endOfLastMonth: "先月末",
        endOfMonth: "今月末",
        endOfNextMonth: "来月末",
        clear: "クリア",
        selectedHoliday: "休日が選択されています",
      },
    },
    sendFrom: {
      summary: {
        system: "システム",
        tokium: "TOKIUM",
      },
      email: "メールで受領しました",
      emailFromTokium: "TOKIUMがアップロードしました",
      manual: "手動で作成しました",
      post: "郵送で受領しました",
      agency: "TOKIUMがアップロードしました",
      uploader: "請求書アップローダで受領しました",
      periodic: "定期支払で作成しました",
      dedicated: "OCRでデータ化しました",
      dedicatedOperator: "オペレーターでデータ化しました",
      api: "APIで作成しました",
    },
    supplierSelector: {
      actions: {
        searchPlaceholder:
          "取引先名 もしくは 取引先コード（複数キーワードの指定が可能）",
      },
      titles: {
        selectSupplier: "取引先を選択",
      },
      labels: {
        otherPaymentMethod: "その他の支払方法",
      },
    },
    supplierExport: {
      modalTitle: "取引先エクスポート",
      goToImportModal: "インポート画面へ移動",
      messages: {
        toFixContent:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
      },
    },
    supplierImport: {
      modalTitle: "取引先インポート",
      downloadCurrentSettingsContent: "現在の設定内容をエクスポート",
      messages: {
        importSuppliers: "取引先をインポートします。",
        toDownloadSampleHere: "サンプルのダウンロードはこちら",
        toFixContent:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
        useLatestDownloadFileWhenUpdate:
          "更新時は最新のエクスポートファイルをご利用ください。",
        removeNoteColumnBeforeToImport:
          "サンプルデータの{{ column }}列に補足事項の記載があります。インポート時には{{ column }}列を削除してください。",
      },
    },
    suppliers: {
      edit: "取引先編集",
      phoneNumber: "電話番号",
      phoneNumberHelp:
        "請求書データ化の際、取引先ごとの固有の識別コードが確認できない場合は、電話番号、口座情報(金融機関、口座種類、口座番号)をもとに請求書と取引先の紐付けを行います。",
      registratedNumberTitleHelp:
        "税務署から適格請求書発行事業者に通知された登録番号を指します。登録番号は「T+半角数字13桁」です。",
      registratedNumberInputAlert: "13桁で入力してください",
      accessibleUserSetting: "担当者設定",
      accessibleDepartmentSetting: "担当部署設定",
      invoiceUploaderAddressee: "社内宛名",
      invoiceUploaderAddresseeHelp:
        "お取引先様が請求書送付時に利用する取引先ごとの名前です。\n郵送時・請求書のアップロードサイトでお取引先様が目にする名前ですので、重複・社内情報の流出にご注意ください。\n同一取引先と複数の部署が取引を行っている場合、部署名(ex: 横浜営業部)を入力することで、取引先が選択しやすくなります。",
      invoiceUploaderAddresseeNeeded: "社内宛名が未入力です",
      invoiceUploaderCodeHelp:
        "識別コードとは、弊社が取引先ごとに割り当てた情報で、お取引先様はこのコードを付与して弊社に請求書を送付します。",
      diffOfficial: "取引先の登録情報と下記の入力内容に差分があります",
      noOfficial: "未登録の取引先が入力されています",
      blocked: "凍結された取引先に紐付いています",
      beforeChangeHelp: "取引先マスタに登録された値です",
      afterChangeHelp: "現在の入力値です",
      officialized: "取引先マスタを登録/更新しました",
      duplicateSupplierPossibility:
        "同一内容の取引先が既に取引先マスタに存在している可能性があります",
      applicableTemplate: "適用中:",
      detailDescription:
        "この画面からは編集できません。情報の変更がある場合は、管理者の方が取引先編集画面より変更をお願いいたします。",
      titles: {
        registratedNumber: "登録番号",
        phoneNumber: "電話番号",
        userInCharge: "担当者設定",
        groupInCharge: "担当部署設定",
        other: "その他",
        reportTemplate: "請求書テンプレート",
        invoiceMailerModal: {
          requestForUpdateAndConfirmSendingMethod: "取引先への送付先変更連絡",
          editMail: "送付メール",
        },
      },
      labels: {
        supplierCode: "取引先コード",
        supplierName: "取引先名",
        paymentMethod: "支払方法",
        phoneNumber: "電話番号",
        userInCharge: "担当者",
        groupInCharge: "担当部署",
        receivingReportStatus: "請求書受領状態",
        confirmReceivingReport: "請求書受領確認",
        receiving: "受領",
        notReceiving: "未受領",
        blockedStatus: "凍結状態",
        blocked: "凍結済み",
        notBlocked: "未凍結",
        invoiceUploaderCode: "識別コード",
      },
      tables: {
        columns: {
          supplierCode: "取引先コード",
          supplierName: "取引先名",
          paymentMethod: "支払方法",
          userInCharge: "担当者",
          groupInCharge: "担当部署",
          phoneNumber: "電話番号",
          latestReportReceivingDate: "請求書直近受領日",
        },
      },
      buttons: {
        block: "凍結する",
        cancellBlocked: "凍結解除",
      },
      descriptions: {
        confirmReceivingReport:
          "本項目をONに設定した取引先は、「請求書受領状態」を条件に検索可能になります",
        receivingReportSearch:
          "「請求書受領確認」設定有効の取引先から、以下の条件に合うものを抽出できます",
        paymentMethodsPayFeeSelect:
          "「取引先設定の内容でデータ化」が有効でも「未指定」が選択されている場合は、請求書の記載内容が優先されます。",
      },
      actions: {
        block: "凍結する",
        unblock: "凍結解除",
        openEmailEditorModal: "取引先への送付先変更連絡",
        selectSupplier: "取引先を選択",
        searchPlaceholder: "検索する取引先名",
        reportTemplates: {
          searchPlaceholder: "請求書テンプレート名",
        },
      },
      propertyGroups: {
        sendingInformation: "請求書送付先情報",
      },
      properties: {
        code: "取引先コード",
        name: "取引先名",
        paymentMethod: "支払方法",
        registratedNumber: "登録番号",
        phoneNumbers: "電話番号",
        accessibleUsers: "担当者",
        accessibleDepartments: "担当部署",
        invoiceUploaderCode: "識別コード",
        email: "メールアドレス",
        uploaderUrl: "アップロードサイト登録用URL",
        sendingEmail: "送付先メールアドレス",
        sendingAddress: "送付先住所",
        latestReportReceivingDate: "請求書直近受領日",
        supplierSetting: "取引先設定",
        supplier: "取引先",
        reportTemplateName: "請求書テンプレート名",
        reportTemplate: "請求書テンプレート",
      },
      errors: {
        registratedNumber: {
          updateFailed: "登録番号の更新時にエラーが発生しました",
        },
      },
    },
    supplierInvoiceMailer: {
      properties: {
        email: "送付先メールアドレス",
        subject: "メールタイトル",
        body: "メール本文",
      },
      messages: {
        youShouldCopy:
          "本メールをコピー&ペーストし、\n普段ご利用されているメールシステム上からご送付ください。",
      },
      actions: {
        switchToPreview: "プレビューへ切替え",
        switchToEditor: "入力モードへ切替え",
      },
      labels: {
        preview: "メール内容プレビュー",
      },
    },
    timestamp: {
      properties: {
        inprogress: "タイムスタンプ付与中",
        failed: "タイムスタンプ付与失敗",
      },
    },
    workerMailRejection: {
      titles: {
        suppliers: "取引先を選択",
        statusOfInCharge: "取引先の担当者",
      },
      labels: {
        userInCharge: "自分",
        groupInCharge: "自分の所属部署",
        notInCharge: "自分以外",
        noCharge: "未設定",
      },
      buttons: {
        setForEachSupplier: "取引先ごとに設定する",
      },
      messages: {
        saved: "設定を保存しました",
      },
    },
    bankAccount: {
      types: {
        ordinal: "普通",
        current: "当座",
        saving: "貯蓄",
        other: "その他",
      },
    },
    invoiceCategory: {
      title: "科目名",
      setting: "請求明細科目",
      properties: {
        displayName: "科目名称",
        superCategory: "勘定科目",
        parentCategory: "親科目",
        subCategory: "補助科目",
        requiresCompanion: "参加者入力",
        requiresWithholding: "源泉徴収対応",
        required: "必須",
        noNeed: "不要",
        sort: "並び順",
        taxCategory: "税区分",
        enable: "する",
        disable: "しない",
        memoTemplate: "メモ欄テンプレート",
        isDefault: "既定の科目",
      },
      actions: {
        categoryAsParent: "親科目として設定する",
      },
      descriptions: {
        categoryAsParent:
          "一度登録すると、子科目から親科目、親科目から子科目への変更はできません。",
        enable: "「利用する」となっている子科目がないため、編集できません。",
      },
      exports: {
        goToImportModal: "インポート画面へ移動",
        messages: {
          toFixContent:
            "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
        },
      },
    },
    debitInvoiceCategory: {
      title: "借方",
      placeholder: "検索する借方請求明細科目名",
      setting: "借方請求明細科目設定",
      settingWithExpense: "経費/借方請求明細科目設定",
      properties: {
        superCategory: "勘定科目",
        parentCategory: "親科目",
        subCategory: "補助科目",
        requiresCompanion: "参加者入力",
        requiresQuantityAndUnitPrice: "数量・単価入力",
        required: "必須",
        noNeed: "不要",
        sort: "並び順",
        taxCategory: "税区分",
        enable: "する",
        disable: "しない",
        memoTemplate: "メモ欄テンプレート",
      },
      actions: {
        add: "借方請求明細科目を追加",
        includeDisabled: "利用しない借方請求明細科目も表示する",
        categoryAsParent: "親科目として設定する",
        edit: "借方請求明細科目編集",
        select: "借方請求明細科目を選択",
        searchPlaceholder: "検索する借方請求明細科目名",
      },
      descriptions: {
        categoryAsParent:
          "一度登録すると、子科目から親科目、親科目から子科目への変更はできません。",
        enable: "「利用する」となっている子科目がないため、編集できません。",
      },
      imports: {
        title: "借方請求明細科目インポート",
        desciption: "借方請求明細科目をインポートします。",
        sample: "サンプルのダウンロードはこちら",
        guide: "サンプルデータ「項目説明」シートに補足事項の記載があります。",
        goToExportModal: "現在の設定内容をエクスポート",
        toFixContent:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
      },
      exports: {
        title: "借方請求明細科目エクスポート",
        goToImportModal: "インポート画面へ移動",
        messages: {
          toFixContent:
            "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
        },
      },
    },
    creditInvoiceCategory: {
      title: "貸方",
      placeholder: "検索する貸方請求明細科目名",
      setting: "貸方請求明細科目設定",
      settingWithExpense: "経費/貸方請求明細科目設定",
      properties: {
        superCategory: "勘定科目",
        parentCategory: "親科目",
        subCategory: "補助科目",
        requiresCompanion: "参加者入力",
        required: "必須",
        noNeed: "不要",
        sort: "並び順",
        taxCategory: "税区分",
        enable: "する",
        disable: "しない",
        memoTemplate: "メモ欄テンプレート",
      },
      actions: {
        add: "貸方請求明細科目を追加",
        includeDisabled: "利用しない貸方請求明細科目も表示する",
        excludeDisabled: "利用しない貸方請求明細科目を非表示にする",
        categoryAsParent: "親科目として設定する",
        edit: "貸方請求明細科目編集",
        select: "貸方請求明細科目を選択",
        searchPlaceholder: "検索する貸方請求明細科目名",
      },
      descriptions: {
        categoryAsParent:
          "一度登録すると、子科目から親科目、親科目から子科目への変更はできません。",
        enable: "「利用する」となっている子科目がないため、編集できません。",
      },
      imports: {
        title: "貸方請求明細科目インポート",
        desciption: "貸方請求明細科目をインポートします。",
        sample: "サンプルのダウンロードはこちら",
        guide:
          "サンプルデータのK列に補足事項の記載があります。インポート時にはK列を削除してください。",
        goToExportModal: "現在の設定内容をエクスポート",
        toFixContent:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
      },
      exports: {
        title: "貸方請求明細科目エクスポート",
      },
    },
    taxCategoryOption: {
      title: "税区分選択",
      properties: {
        taxCategoryName: "税区分名",
        taxRate: "税率",
        term: "有効期間",
        sort: "並び順",
      },
    },
    reportItem: {
      continuous: "入力内容を再利用する",
      createdCount: "件目",
      created: "作成しました",
      hasAlert: "項目に不備があります",
      hasAlertFollowingItems: "以下の項目に不備があります",
      notMatchProposal:
        "発注稟議に含まれない借方請求明細科目、もしくは費用負担部署が設定されています",
      notMatchProposalDebitInvoiceCategoryOrCostAllocationDepartment:
        "請求書明細の「借方請求明細科目」もしくは「費用負担部署」が、承認済み発注稟議の同項目と差分があります。",
      overBudgetOfProposal:
        "発注稟議申請に紐づく請求明細の合計が申請額を超過しています",
      createCopied: "コピーを作成",
      createFromPast: "過去明細から作成",
      pastList: "過去明細一覧：{{supplierName}}",
      createFromPastWithSupplierName: "過去明細から作成：{{supplierName}}",
      inputFromPast: "過去明細で上書き",
      selectItemYouWantToInput: "入力する明細を選択してください",
      createFromImport: "インポート",
      otherActions: "その他の操作",
      taxCategoryUnmatchForAsInvoice:
        "適格請求書として扱う判定ですが、非適格用の税区分が選択されています",
      taxCategoryUnmatchForNotAsInvoice:
        "適格請求書として扱わない判定ですが、適格用の税区分が選択されています",
      descriptions: {
        createFromPastData:
          "同じ取引先に対する過去の明細の内容で、明細を作成できます。",
        overwriteToFromPastData:
          "同じ取引先に対する過去の明細の内容で、明細を上書きできます。",
        latestInSimilarPastData:
          "類似の過去の明細は最新の1件のみ表示されます。",
        doNotInputSupplier: "取引先が入力されていません",
        alertForConsumptionTax:
          "端数切り捨て処理のため、金額によっては税抜切り替えで1円誤差が発生します。\nその際は金額を修正してください。",
        notQuotingParticipants:
          "借方請求明細科目が利用しないに変更されている、または参加者が不要に変更されているため、参加者は引用されません。",
        notQuotingCostAllocations:
          "金額指定で按分しているため、費用負担部署は引用されません。",
        notQuotingCostAllocationsBecauseDifferAmountForCreate:
          "金額が一致しないため、費用負担部署は引用されません。",
        notQuotingCostAllocationsBecauseDifferAmountForInput:
          "金額が一致しない明細は、費用負担部署を引用しません。",
        notQuotingCostAllocationsBecauseExistsDeletedPayer:
          "削除済みの部署が含まれているため、費用負担部署は引用されません。",
        notQuotingProject:
          "プロジェクト機能を利用しない設定になっているため、プロジェクトは引用されません。",
      },
      messages: {
        mustInputAppropriatedAtWhenUseForeignCurrency:
          "外貨を入力するためには、計上日を入力する必要があります",
        importFinished: "インポートでの明細作成が完了しました",
        noData: "請求書が見つかりませんでした。",
      },
      labels: {
        valueTypes: {
          inputAsPercentage: "割合で入力",
          inputAsAmount: "金額で入力",
        },
        amounts: {
          automaticFormula: "[自動計算結果] 単価×数量=",
        },
        unitPrice: {
          automaticFormula: "[自動計算結果] 金額÷数量=",
        },
      },
      genericFields: {
        values: {
          deletedDataSet: "削除済みのマスタ",
        },
        itemsSelector: {
          title: "汎用マスタ {{name}} から選択",
          searchPlaceholder: "複数キーワードの指定が可能",
        },
      },
      actions: {
        add: "明細の追加",
        addManually: "手動で作成",
        addFromImport: "インポートから作成",
        edit: "明細の編集",
      },
    },
    reportItemImport: {
      modalTitle: "請求明細インポート",
      messages: {
        importReportItems: "請求書の明細をインポートします。",
        toDownloadSampleHere: "サンプルのダウンロードはこちら",
        toTaxIncludedDownloadSampleHere:
          "税込入力サンプルのダウンロードはこちら",
        toTaxExcludedDownloadSampleHere:
          "税抜入力サンプルのダウンロードはこちら",
        bulkCreateFromImport:
          "インポートによって、複数の明細を一括作成できます。",
        cantOverWriteAndDeleteAtAlreadyRegistered:
          "既に登録されている明細の上書きおよび削除はできません。",
        removeNoteColumnBeforeToImport:
          "サンプルデータ「請求明細情報」シートのAA列に補足事項の記載があります。インポート時にはAA列を削除してください。",
        removeNoteColumnBeforeToImportForGenericFields:
          "サンプルデータ「汎用マスタマッピング」シートのC列に補足事項の記載があります。インポート時にはC列を削除してください。",
        notChangeSheetNameInFormat:
          "「請求明細情報」シート、「汎用マスタマッピング」シートの名称は変更しないでください。",
        removeExceptReportItemInfoSheet:
          "インポートに時間がかかる場合は「請求明細情報」シートと「汎用マスタマッピング」シート以外を削除することで改善する場合があります。",
        genericFieldLicenseMessage:
          "汎用マスタのご契約をされていない場合、「請求明細情報」シートの汎用マスタ1〜5の列への値の記載、並びに「汎用マスタマッピング」シートは不要です",
        changeTaxCategoryDependingOnAsInvoice:
          "「適格請求書として扱う」のチェックに応じて入力する税区分を変更してください。",
      },
    },
    superCategory: {
      properties: {
        defaultCreditCategory: "既定の貸方勘定科目(請求書明細用)",
      },
    },
    currencyPreference: {
      errors: {
        noAppropriatedAt: "計上日が設定されていません",
        earlyOrExpired: "外貨の適用期間外です",
      },
    },
    metadata: {
      imports: {
        guideWithHierarchyWithKeihi:
          "部署名・経費科目名・借方請求明細科目名に「/」が含まれる場合はエクスポート時に「¥/」と変換されますが、そのままインポートしてください。",
        guideWithHierarchy:
          "部署名・借方請求明細科目名に「/」が含まれる場合はエクスポート時に「¥/」と変換されますが、そのままインポートしてください。",
      },
    },
    agentSettings: {
      agentTable: {
        reportRequest: "支払依頼申請",
        proposalRequest: "稟議",
        canMakeAndSubmit: "作成・申請",
        canApprove: "承認",
      },
      agentAuthorities: {
        canApprove: "経費承認",
        canMakeReportRequestAndSubmit: "支払依頼申請作成・申請",
        canApproveReportRequest: "支払依頼申請承認",
        canMakeProposalRequestAndSubmit: "稟議作成・申請",
        canApproveProposalRequest: "稟議承認",
      },
    },
    approveJobs: {
      bulkApproveSelectedPaymentReportRequests:
        "選択した支払依頼申請を一括承認する",
      bulkApproveSelectedPreReports: "選択した事前申請を一括承認する",
      bulkApproveSelectedReports: "選択した経費精算を一括承認する",
      bulkApproveSelectedApplications: "選択した{{tabName}}を一括承認する",
      processing: "実行中",
      error: "一括承認エラー",
      message: {
        bulkCreate: "一括承認の実行を開始しました",
      },
    },
    reportRequestJobs: {
      processing: "実行中",
      error: "申請エラー",
    },
    waitingWorkerDocumentInputs: {
      detail: "自動入力中書類詳細",
      createdAt: "受領日時",
      supplierCode: "取引先コード",
      supplierName: "取引先名",
      toReport: "請求書として手入力",
      toNationalTaxDocument: "国税関係書類として手入力",
      confirmTitleToReport: "請求書として手入力",
      confirmTitleToNtd: "国税関係書類として手入力",
      confirmBody:
        "実行すると自動入力中書類に戻すことはできません。よろしいですか？",
      indexCount: "総件数",
      toManualNow: "自動入力を待たずに手入力",
      movedFromNtd: "国税関係書類から移動",
      copyUrl: "URLをコピー",
      messages: {
        noData: "自動入力中書類が見つかりませんでした。",
      },
    },
    accountingDataScheduledExports: {
      title: "インボイス",
      common: {
        exclusion: "以外",
      },
      tasks: {
        forms: {
          export: {
            payerAccount: {
              label: "支払口座",
            },
          },
        },
        analysisAggregationConditions: {
          noData: "集計条件なし",
          details: "集計条件詳細",
          costAllocationDepartment: "費用負担部署",
          departmentInCharge: "担当部署",
          userDepartment: "所属部署",
          bank: "支払口座",
          projectId: "プロジェクトID",
          projectName: "プロジェクト名",
          supplierName: "取引先名",
          supplierCode: "取引先コード",
          includeChildDepartment: "下位部署を含む",
          includeNotAggregated: "未集計",
          includeAggregated: "集計済み",
          includeNotExported: "会計データ未出力",
          includeExported: "会計データ出力済み",
          includeNotPaid: "未払い",
          includePaid: "支払処理済み",
          includeOurPayFee: "当方",
          includeTheirPayFee: "先方",
          duePeriod: "支払期日",
          schedulePeriod: "支払希望日",
          appropriatedPeriod: "計上日",
          lastApprovedPeriod: "最終承認日",
          registeredPeriod: "登録日",
          reportLabel: "請求書ラベル",
          purchaseOrderNumber: "発注書番号",
          consolidatedReportSequenceNumber: "一括支払依頼申請ID",
          paymentMethods: "支払方法",
          registratedNumber: "登録番号",
          includeAsInvoice: "あり",
          includeNotAsInvoice: "なし",
          memo: "請求書メモ",
          period: {
            current: "当月",
            previous: "前月",
            rangeAndDate: "{{range}} {{from}}日~{{to}}日",
          },
          includeLabel: {
            departmentSearchRange: "部署検索範囲",
            aggregationStatus: "集計状況",
            exportationStatus: "会計データ出力状況",
            paymentStatus: "支払い状況",
            payFee: "手数料負担先",
            asInvoice: "適格請求書の取り扱い",
          },
        },
        readOnlyForm: {
          bankDataCreation: {
            feePaymentDestination: "手数料負担先",
            our: "当方",
            their: "先方",
          },
        },
      },
      noLicense: {
        messageForIP:
          "会計データ定期出力機能（TOKIUMインボイス）は、有料オプションとなります。ご利用の際には、サポートまでご連絡ください。",
        summaryForIP: `[TOKIUMインボイス > 集計] 画面で実施可能な以下の操作を、指定のスケジュールに自動実行できます。

          ・集計の作成
          ・作成した集計から会計データを出力
          ・作成した集計から全銀データを出力

          また、出力したファイルを指定のFTPサーバーに自動転送できます。
        `,
      },
    },
    consolidatedReports: {
      properties: {
        sequenceNumber: "申請ID",
        totalAmountIncludingWithholding: "総額",
        totalAmountExcludeWithholding: "総額\n(各源泉徴収税額を除く)",
        totalWithholding: "(源泉徴収税総額)",
        requester: "申請者名",
        requestStatus: "申請状況",
      },
      views: {
        includingReportsCard: {
          cardTitle: "請求書一覧",
        },
      },
      messages: {
        requestedAt: "{{requestedAt}}に申請",
      },
    },
    nationalTaxDocumentSettings: {
      labels: {
        title: "国税関係書類ラベル一覧",
        description: "登録数最大20件",
        table: {
          headers: {
            label: "ラベル",
          },
        },
        modal: {
          title: {
            createLabel: "国税関係書類ラベルを追加",
            editLabel: "国税関係書類ラベルを編集",
          },
        },
      },
    },
  },
  td: {
    fileForm: {
      timestamp: {
        alert: "タイムスタンプの付与がされないファイル形式です",
      },
    },
  },
  managements: {
    invoicingOrganizations: {
      properties: {
        name: "登録事業者名",
        registrationDate: "有効開始日",
        verifyStatus: "自動照合結果",
        address: "所在地",
        updateDate: "更新年月日",
      },
      verifyStatuses: {
        OK: "有効",
        NG: "無効",
      },
      processDetail: {
        expire: "登録の失効",
        disposal: "登録の取消",
        deleted: "登録の削除",
        unregistered: "未登録",
      },
      description: {
        automatedProcessVerifyStatus:
          "入力した登録番号と、国税庁の適格請求書発行事業所公表サイトを自動照合した結果を表示します。\n最新の情報が反映されるまで1日程度の遅れが発生する場合があります。",
      },
      title: {
        organizationInformation: "事業者の登録情報",
      },
    },
    errors: {
      invoicingOrganization: {
        getFailed: "適格請求書発行事業者情報の取得時にエラーが発生しました",
        unregistered:
          "登録番号に該当する適格請求書発行事業者が登録されていません",
      },
    },
  },
  navigations: {
    titles: {
      helpCenter: "ヘルプセンター",
    },
  },
  activityLogs: {
    title: "ログ",
    serchSetting: "検索条件を設定",
    downloadModal: {
      title: "ログエクスポート",
      description1: "出力可能なデータ件数は最大10万件までです。",
      create: "最新のCSVファイルを作成する",
    },
    downloadButton: {
      button: "エクスポート",
    },
  },
  logins: {
    errors: {
      password: {
        blank: "パスワードを入力してください。",
        tooShort: "{{minPasswordLength}}文字以上で設定してください。",
        notMatch: "パスワードの入力が一致しません。",
      },
    },
    pageTitles: {
      resetPassword: "パスワード再設定",
    },
    pageDescriptions: {
      resetPassword: "新しいパスワードを設定してください。",
      passwordCondition:
        "（{{minPasswordLength}}文字以上 {{maxPasswordLength}}文字以下）",
      passwordConditionOfCharTypes:
        "（英小文字、英大文字、数字、記号のうち3種類以上含む必要があります）",
    },
    placeholders: {
      password: "パスワード",
      passwordConfirmation: "パスワード(確認)",
    },
    buttons: {
      resetPassword: "パスワードを再設定",
    },
    pageLinks: {
      backToSignIn: "ログイン画面に戻る",
      reSendInvitation: "登録メールが届かない場合",
    },
    forcePasswordChangePages: {
      message:
        "パスワードの条件が変更されました。新しいパスワードに変更してください。",
      condition: "パスワードの条件",
      length: "8文字以上",
      chars:
        "英字（小文字）、英字（大文字）、数字、記号のうち3種類以上を含む。",
      usableSymbols: "利用できる記号",
      symbols:
        "@ # $ % ^ & * - _ ! + = [ ] { } | \\ : ' , . ? / ` ~ \" ( ) ; < >",
      newPassword: "新しいパスワード",
      newPasswordConfirm: "新しいパスワード(確認)",
      changePassword: "パスワードを変更する",
      errors: {
        format: "パスワードの条件を満たしていません。",
        notMatch: "パスワードが一致しません。",
      },
    },
  },
  eDocOption2016: {
    title: "電子帳簿保存法オプション ※2016年改正対応版",
  },
  eDocOption2022: {
    title: "電子帳簿保存法オプション ※2022年改正対応版",
    timeStampExpirationDate: "タイムスタンプの有効期限: {{day}}日",
    startedAt: "運用開始日",
    periodOfFlowCycleWithRange: "業務サイクルの期間(0〜60の範囲)",
    placeholder: {
      dateInput: "年/月/日",
    },
  },
  systemSettings: {
    systemSettings: "システム設定",
    menus: {
      paymentRequests: {
        inputFormats: "入力フォーマット設定",
        distributionConditions: "書類振分",
        invoiceSettings: "請求書設定",
        nationalTaxDocumentSettings: "国税関係書類設定",
      },
      members: "従業員",
      posts: "役職",
      groups: "部署",
      companions: "参加者",
      expenseAccounts: "支払口座",
      requestTypes: "申請フォーム",
      approvalFlows: "申請フロー",
      projects: "プロジェクト",
      currencies: "外貨",
      export: "会計データ出力形式",
      rootCategories: "科目",
      taxCategories: "税区分",
      businessCategories: "自動入力科目",
      reports: "経費入力・レポート",
      allowances: "日当・手当",
      alertRules: "アラート",
      icCardOptions: "IC乗車券オプション",
      metadata: "付加情報",
      closingDates: "締め日",
      analysesConfig: "会計データ出力",
      eDocOptions: "電子帳簿保存法",
      listOptions: "一覧表示",
      corporateCards: "法人カード",
      journalEntries: "仕訳フォーマット",
      accountingDataScheduledExports: "会計データ定期出力",
      ipRestriction: "セキュリティ",
      btob: "BtoB連携",
      activityLogs: "ログ",
      payment: "契約",
      externalTables: "外部マスタ",
      genericFields: "汎用マスタ",
      referenceFilters: "データの公開範囲",
      bulkImageDownloads: "一括ダウンロード",
    },
  },
  importStatus: {
    started: "インポート待機中です…",
    importing: "データをインポートしています...",
    uploading: "インポート結果をアップロードしています...",
    failure: "インポートに失敗しました",
    success: "インポートが正常に完了しました",
    error: "エラーが発生しました",
  },
  ReferenceFilters: {
    resourceType: {
      all: "全て",
      nationalTaxDocument: "国税関係書類",
    },
    headers: {
      enabled: "有効/無効",
      name: "ルール名",
      resourceType: "データ種別",
      description: "説明",
    },
    Ntd: {
      resourceRuleExplanation: {
        all: "全ての国税関係書類",
        // eslint-disable-next-line camelcase
        under_my_department: "　自分の所属部署　の国税関係書類",
        // eslint-disable-next-line camelcase
        under_my_department_and_created_by_myself: "作成者が自分の国税関係書類",
        helpBalloon:
          "自分の所属部署の書類のうち、自分が作成した書類を閲覧可能になります。",
        isAppliedToSubsidiaries: {
          true: "(下位部署を含める)",
          false: "(下位部署を含めない)",
        },
      },
    },
    member: {
      title: "対象者",
      description: "ログインしている従業員が次のいずれかである",
      addMember: "従業員を追加",
      assigneTrigger: "未割り当てのみ",
      assigneTriggerDescription:
        "国税関係書類に「データの公開範囲」設定済みの従業員を除外します。",
      modalTitle: "従業員を選択",
      placeholder: "従業員名、部署名、またはメールアドレス",
      notValuesFieldMember: "未入力",
      checkAll: "表示中の{{count}}件を選択",
    },
    condition: {
      title: "条件",
    },
  },
  viewer: {
    passwordForm: {
      title: "パスワードを入力してください",
      subTitle: "このPDFはパスワードで保護されています。",
      placeholder: "パスワードを入力",
    },
    changeViewer: "ビューアーを切り替える",
    browserPDFError: {
      title: "ビューアーが対応していません。\nこちらから切り替えてください。",
      button: "ビューアーを切り替える",
    },
  },
};

export default locales;
